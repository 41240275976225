import React, { useState, useMemo, useRef } from "react";
// import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Eventcalendar, formatDate } from "@mobiscroll/react";
// import { Drawer } from '@material-ui/core';
import { DateRangePicker as RsuitePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import SVG from "react-inlinesvg";
import "./styles.css";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { MdPublish, MdDelete } from "react-icons/md";
import { DatePicker as RsuiteDatePicker} from 'rsuite';
import "./loader.css";
import {
  Switch as AntdSwitch,
  DatePicker,
  Drawer,
  Input,
  Spin,
  Select as AntSelect,
  Space,
  Button,
  Card,
  Divider,
  Modal,
  Alert,
  Watermark,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import EditDrawer from "../EditDrawer/EditDrawer";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { FiClock } from "react-icons/fi";
import { TbClockX } from "react-icons/tb";
import { AiOutlineCopy } from "react-icons/ai";
import { PiArrowsCounterClockwiseBold } from "react-icons/pi";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { Avatar } from "@material-ui/core";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { api_key } from "../../../services/utils";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import Select from "react-select";
import { SendNotifaction } from "../../../services/SendNotifaction";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import DeleteModal from "../DeleteModal/DeleteModal";
import CustomModal from "../CustomModal/CustomModal";
import DeleteShift from "./DeleteShift";
import DeleteShift2 from "../DeleteRecureModal/DeleteShift";
import UpdateRecureModal from "../UpdateRecureModal/UpdateRecureModal";
import UserProfileDrawer from "./UserProfileDrawer";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IoMove } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import DeleteRecureModal from "../DeleteRecureModal/DeleteRecureModal";
import { RiWifiOffLine } from "react-icons/ri";
import { Spinner } from "react-bootstrap";
import SettingModal from "../settingmodal/SettingModal";
import DeleteDraftModal from "../DeleteModal/DeleteDraftModal";
import { RiDraftFill } from "react-icons/ri";
import { MdOutlineDrafts } from "react-icons/md";
import OverlapModal from "../OverlapModal/OverlapModal";
import { IoIosUndo } from "react-icons/io";
import ConfirmForRecurring from "../ConfirmModal/ConfirmForRecurring";
import EditDrawer2 from "../EditDrawer/EditDrawer2";
import AssignRotaUsers from "../AssignUsers/AssignRotaUsers";
import {UserSwitchOutlined} from '@ant-design/icons'
const { Option } = AntSelect;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { beforeToday } = RsuitePicker;

function RotaCalendarMobi({ rotaId, rota, refectchRota }) {
  // useRef to create a reference for the autoComplete component
  const autoCompleteRef = useRef();
  const scrollRef = useRef();

  // useRef to create a reference for the input component
  const inputRef = useRef();

  // location options
  const options = {
    componentRestrictions: { country: "us" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  var currentDate1 = new Date();

  currentDate1.setHours(9);
  currentDate1.setMinutes(0);
  currentDate1.setSeconds(0);

  var currentDate2 = new Date();

  currentDate2.setHours(18);
  currentDate2.setMinutes(0);
  currentDate2.setSeconds(0);

  const [currentPublishDate, setCurrentPublishDate] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const authtoken = localStorage.getItem("userToken");
  const [selectedOption, setSelectedOption] = useState("");
  const [onLeaveToday, setOnLeaveToday] = useState(false);
  const [valueOfTitle, setValueOftitme] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmPublishModal, setConfirmPublishModal] = useState(false);
  const [confirmdeleteModal, setConfirmDeleteModal] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState("");
  const [color, setColor] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [view, setView] = React.useState("month");
  const [selectedObject, setSelectedObject] = useState({});
  const [editDrawerModal, setEditDrawerModal] = useState(false);
  const [editDrawerModal2, setEditDrawerModal2] = useState(false);
  const [checkingModal, setCheckingModal] = useState(false);
  const [search, setSearch] = useState("");
  const [copyUpdate, setCopyUpdate] = useState(false);
  const [catchStartEvent, setCatchStartEvent] = useState("");
  const [catchEndEvent, setCatchEndEvent] = useState("");
  const [defaultColor, setDefaultColor] = useState({});
  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showStartDate, setShowStartDate] = useState(null);
  const [showEndDate, setShowEndDate] = useState(null);
  const [pikerModal, setPikerModal] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [defaultUser, setDefaultUser] = useState({});
  const [defaultDateRange, setDefaultDateRange] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [repeat, setRepeat] = useState("never");
  const [custom, setCustom] = useState(false);
  const [invalid, setInvalid] = useState([]);
  const [endRepeat, setEndRepeat] = useState(false);
  const [holidaysColor, setholidaysColor] = useState([]);
  const [endRepeatDate, setEndRepeatDate] = useState("");
  const [hideShow, setHideShow] = useState(true);
  const [next7ear, setNext7Year] = useState("");
  const [ableStartDate, setAbleStartDate] = useState("");
  const [ableEndDate, setAbleEndDate] = useState("");
  const [publishShiftModal, setPublishShiftModal] = useState(false);
  const [publishShiftArray, setPublishShiftArray] = useState(false);
  const [reCureEditModal, setReCureEditModal] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [reCureDeleteModal, setReCureDeleteModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [myselectedShift, setMyselectedShift] = useState("");
  const [liveRotaSelectedShift,setLiveRotaSelectedShift]=useState({})
  const [tagLoading, setTagLoading] = useState(true);
  const [Oflinemode, setOflineMode] = useState(false);
  const [showTimeVisible, setshowTimeVisible] = useState(false);
  const [rotaPlannerDraft, setRotaPlannerDraft] = useState(false);
  const [moveDraftModal, setMoveDraftModal] = useState(false);
  const [moveDraftModalLoading, setMoveDraftModalLoading] = useState(false);
  const [moveDraftToLiveModal, setMoveDraftToLiveModal] = useState(false);
      const [assignModal, setAssignModal] = useState(false)
  
  const [
    moveDraftToLiveModalLoading,
    setMoveDraftToLiveModalLoading,
  ] = useState(false);
  const [revertModal, setRevertModal] = useState(false);
  const [revertModalLoading, setRevertModalLoading] = useState(false);
  const [loadingRevertLive, setLoadingRevertLive] = useState(false);
  const [movingDraftLoading, setMovingDraftLoading] = useState(false);
  const [isPublishDraftShifts,setIsPublishDraftShifts]=useState(false)
  const [isChange,setIsChange]=useState(false)
  const [overLapShifts, setOverLapShifts] = useState([]);
  const [endRepeatValue, setEndRepeatValue] = useState({
    label: "Never",
    value: "never",
  });
  const [copyDataForapi, setCopyDataForapi] = useState([]);
  // new states for delete shifts

  const [settingmodal, setSettingmodal] = useState(false);

  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");

  const [calView, setCalView] = React.useState({
    calendar: { labels: true },
  });
  const [locError, setlocError] = useState("");
  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "year":
        calView = {
          calendar: { type: "year" },
        };
        break;
      case "month":
        calView = {
          calendar: { labels: true },
        };
        break;
      case "week":
        calView = {
          schedule: { type: "week" },
        };
        break;
      case "day":
        calView = {
          schedule: { type: "day" },
        };
        break;
      case "agenda":
        calView = {
          calendar: { type: "week" },
          agenda: { type: "week" },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };
  //loading states
const repeatArray=[
  { label: "Never", value: "never" },
  { label: "Every Day", value: "everyday" },
  { label: "Every Week", value: "weekly" },
  { label: "Every 2 Weeks", value: "every_two_week" },
  { label: "Every 3 Weeks", value: "every_three_week" },
  { label: "Every Month", value: "monthly" },
  { label: "Every Other Month", value: "every_other_month" },
  { label: "Every Year", value: "yearly" },
  { label: "Every Week Gap", value: "weekly_gape" },
  { label: "Every 2 Weeks Gap", value: "every_two_week_gape" },
  { label: "Every 3 Weeks Gap", value: "every_three_week_gape" },
  { label: "Every Month Gap", value: "monthly_gape" },
  // { label: "Every Other Month Gap", value: "every_other_month_gape" },
  { label: "Every Year Gap", value: "yearly_gape" },
  // { label: "Custom", value: "custom" },
]
  const [isLoading, setIsLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [isGeoFence, setIsGeoFence] = useState(false);
  const user = useSelector((state) => state.user.user);
  // shift states
  const [title, setTitle] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shiftDate, setShiftdate] = useState();
  // const [startTime, setstartTime] = useState(new Date(currentDate1.getTime()));
  const [startTime, setstartTime] = useState({
    label: "9:00 am",
    value: "09:00:00",
  });
  // const [endTime, setendTime] = useState(new Date(currentDate2.getTime()));
  const [endTime, setendTime] = useState({
    label: "6:00 pm",
    value: "18:00:00",
  });
  const [isfullDay, setIsFullday] = useState(false);
  const [selectedResource, setSelectedResource] = useState("");
  const [isEdit, setIsEdit] = useState();
  const [type, setType] = useState("week");
  const [theme, setTheme] = useState("light");
  const [draft, setDraft] = useState(true);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [templates, setTemplates] = useState([]);
  const [dataPayload, setDataPayload] = useState([]);
  const [deleteShiftModal, setDeleteShiftModal] = useState(false);
  const [deleteShifts, setDeleteShifts] = useState([]);
  const [titlesArry, setTitlesArray] = useState([]);
  // api data states
  const [isCopyPressed, setIsCopyPressed] = useState(false);
  const [isUpdatePressed, setIsUpdatePressed] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [profileDrawer, setProfileDrawer] = useState(false);
  const [profileUser, setProfileUser] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersList2, setUsersList2] = useState([]);
  const [testUser, setTestUser] = useState([]);
  const [rotaUsers, setrotaUsers] = useState([]);
  const [singlePublishLoading, setSinglePublishLoading] = useState(false);
  const [singleDeleteLoading, setSingleDeleteLoading] = useState(false);
  const [testDelete, settestDelete] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [repeatValue, setRepeatValue] = useState({
    label: "Never",
    value: "never",
  });
  const [notificationModal, setNotificationModal] = useState(false);
  const [filterdCurrentDaysShit, setFilterdCurrentDaysShit] = useState([]);
  const [copyOflineLoading, setCopyOflineLoading] = useState(false);
  const [updateOflineShift, setUpdateOflineShift] = useState([]);
  const [rotaSettingId, setRotaSettingId] = useState(null);
  const [deleteDraftModal, setdeleteDraftModal] = useState(false);
  const [draftButtonDisable, setDraftButtonDisable] = useState(false);
  const [overLapModal, setOverLapModal] = useState(false);
  const [overLapModalLoading, setOverLapModalLoading] = useState(false);
  const [validateStartDate, setValidateStartDate] = useState("");
  const [validateEndDate, setValidateEndDate] = useState("");
  const [validatingLoading, setValidatingLoading] = useState(false);
  const [orignalData, setOrignalData] = useState([]);
  const [overlapDraft, setOverlapDraft] = useState(false);
  const [allowCopyMove, setAllowCopyMove] = useState(true);
  const [hasUnpublish, setHasUnpublish] = useState(false);
  const [undoLoading, setUndoLoading] = useState(false);
  const [repeatoptions,setrepeatoptions]=useState(repeatArray)
  const [reCureEditModal2,setReCureEditModal2]=useState(false)
  const [updateForFuture,setUpdateForFuture]=useState(0)
  const [resultId,setResultId]=useState(undefined)
  useEffect(() => {
    getAllUsers();
    getTemplates();
    getAllTitle();
    // const [startDate, endDate] = getStartEndDateCurrentMonth(new Date());
      const startDate = new Date();
      const dayOfWeek = startDate.getDay();
      startDate.setDate(startDate.getDate() - dayOfWeek);

      const endDate = new Date();
      endDate.setDate(endDate.getDate() + (6 - dayOfWeek));

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);

      const formattedDay =
        currentDate.getDate() < 10
          ? `0${currentDate.getDate()}`
          : currentDate.getDate();

      const formattedMonth =
        currentDate.getMonth() + 1 < 10
          ? `0${currentDate.getMonth() + 1}`
          : currentDate.getMonth() + 1;

      const formattedYear = currentDate.getFullYear();

      const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
      const finalEndDate = `${new Date(
        endDate
      ).getFullYear()}-${formattedMonth}-${
        new Date(endDate).getDate() < 10
          ? `0${new Date(endDate).getDate()}`
          : new Date(endDate).getDate()
      }`;
     let adjusmetDate=
        adjustedEndDate(formattedDate, finalEndDate)
          .toISOString()
          .slice(0, 10)
      ;
    // let adjusmetDate='2024-05-31'
      const today = new Date();
      const providedDate = new Date(adjusmetDate);
      let startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      if (providedDate.getMonth() > today.getMonth() || providedDate.getFullYear() > today.getFullYear()) {
        endOfMonth = new Date(providedDate.getFullYear(), providedDate.getMonth() + 1, 0);
      }
    
      startOfMonth.setDate(startOfMonth.getDate() + 1);
      endOfMonth.setDate(endOfMonth.getDate() + 1);
    setValidateStartDate(startOfMonth.toISOString().split("T")[0]);
    setValidateEndDate(endOfMonth.toISOString().split("T")[0]);
    gettingCurrentViewDates();
  }, []);

































  const gettingCurrentViewDates = () => {
    if (type == "week") {
      const startDate = new Date();
      const dayOfWeek = startDate.getDay();
      startDate.setDate(startDate.getDate() - dayOfWeek);

      const endDate = new Date();
      endDate.setDate(endDate.getDate() + (6 - dayOfWeek));

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);

      const formattedDay =
        currentDate.getDate() < 10
          ? `0${currentDate.getDate()}`
          : currentDate.getDate();

      const formattedMonth =
        currentDate.getMonth() + 1 < 10
          ? `0${currentDate.getMonth() + 1}`
          : currentDate.getMonth() + 1;

      const formattedYear = currentDate.getFullYear();

      const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
      const finalEndDate = `${new Date(
        endDate
      ).getFullYear()}-${formattedMonth}-${
        new Date(endDate).getDate() < 10
          ? `0${new Date(endDate).getDate()}`
          : new Date(endDate).getDate()
      }`;

      setNewStartDate(formattedDate);
      setNewEndDate(
        adjustedEndDate(formattedDate, finalEndDate)
          .toISOString()
          .slice(0, 10)
      );
    }
  };

  function getStartEndDateCurrentMonth(today) {
      let startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
      startOfMonth.setDate(startOfMonth.getDate() + 1);
  
      endOfMonth.setDate(endOfMonth.getDate() + 1);
  
      return [startOfMonth, endOfMonth];
  }

  useEffect(() => {
    if (rota.id != undefined) {
      getRotaCalendar();
    }
  }, [rota.id]);

  function randstr(prefix) {
    return Math.random()
      .toString(36)
      .replace("0.", prefix || "");
  }
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "MyDropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        setSelectedElement(selectedEl);
      }
    }, 15);
  };

  useEffect(() => {
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [selectedElement]);

  const getAllTitle = () => {
    // setloading(true);
    fetch(`${baseUrl}/mgmt/rotas/${rotaId}/rota-settings`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        let arr = [{ label: "Other", value: "Other" }];
        // setloading(false);
        data.payload.map((item) => {
          arr.push({
            ...item,
            label: item.title,
            value: item.title,
          });
        });
        setTitlesArray(arr);
      })

      .catch((err) => {
        // setloading(false);
        console.log(err);
      });
  };
  const getAllUsers = () => {
    fetch(`${baseUrl}/mgmt/rotas/${rotaId}/assigned-users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        let tempArray = [];
        setUsersList2(data.payload);
        data.payload.forEach((item, index) => {
          tempArray.push({
            label: `${item.first_name} ${item.last_name}`,
            value: item.id,
          });
        });
        setrotaUsers(tempArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTemplates = () => {
    fetch(`${baseUrl}/mgmt/rota-shift-templates`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        data.payload.forEach((item) => {
          item.users.forEach((user) => {
            user["color"] = Math.floor(Math.random() * 16777215).toString(16);
          });
        });

        setTemplates(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTiming = (selected) => {
    setValueOftitme(selected);
    setSelectedOption(selected.value);
    if (selected.value == "Other") {
      setShowTextBox(true);
      setRotaSettingId(null);

      setEndRepeat(false);
      setSelectedTemplate("");
      setRepeat("never");
      setRepeatValue({ label: "Never", value: "never" });
      setEndRepeatValue({ label: "Never", value: "never" });
      setEndRepeatDate("");
    } else {
      setIsFullday(selected.full_day);
      setShowTextBox(false);
      setRotaSettingId(selected.id);
      setstartTime({
        value: selected.start_time,
        label: removeSeconds(selected.start_time),
      });
      if (selected.end_time) {
        setendTime({
          label: removeSeconds(selected.end_time),
          value: selected.end_time,
        });
      } else {
        setendTime({
          label: "6:00 pm",
          value: "18:00:00",
        });
      }
      setEndRepeatValue({ label: "Never", value: "never" });
      setEndRepeatDate("");
      const filterRepeats = repeatoptions.find(
        (item) => item.value == selected.repeat
      );
      if(!filterRepeats.isDisabled){
        setRepeatValue(filterRepeats);
        setRepeat(selected.repeat);
      }
      if (selected.repeat == "never") {
        setEndRepeat(false);
      }
    }
  };

  // const getColor = () => {
  //   let color = "";

  //   color = Math.floor(Math.random() * 16777215).toString(16);
  //   return color;
  // };

  const nameOfdurationChange = (name) => {
    let convertedString = name
      .toLowerCase()
      .replace("_", " ")
      .replace(/\b\w/g, function(char) {
        return char.toUpperCase();
      });
    return convertedString;
  };
  const getRotaCalendar = (start_date, end_date) => {
    console.log(start_date,"testing start date")
    if (start_date != undefined) {
      setValidatingLoading(true);
    }
    return new Promise((resolve, reject) => {
      setTagLoading(true);
      setLoadingRevertLive(true);
      // setIsLoading(true);
      const isDraft = rota?.is_draft;
      setRotaPlannerDraft(isDraft);
      const url = `mgmt/rotas/${rotaId}/start-date/${
        start_date == undefined ? validateStartDate : start_date
      }/end-date/${
        end_date == undefined ? validateEndDate : end_date
      }/web-calendar`;
      const urlDraft = `mgmt/rotas/${rotaId}/draft-shifts/start-date/${
        start_date == undefined ? validateStartDate : start_date
      }/end-date/${
        end_date == undefined ? validateEndDate : end_date
      }/web-calendar`;
      fetch(`${baseUrl}/${isDraft ? urlDraft : url}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setEventList([]);
          setOrignalData([]);
          setDataPayload(data.payload);
          setIsChange(data.payload.is_changes_occur);
          let newUsers = data.payload.users;

          data.payload.users.map((userone, userIndex) => {
            userone["work"] = {
              friday: 1,
              monday: 1,
              total_weekly_working_hours: "6",
              tuesday: 1,
              wednesday: 0,
              tuesday: 1,
              saturday: 0,
              sunday: 0,
            };
            userone["color"] = Math.floor(Math.random() * 16777215).toString(
              16
            );
            userone["name"] = `${userone.first_name} ${userone.last_name}`;
            newUsers[userIndex] = userone;
          });

          setUsersList(newUsers);
          // const eventsArray = data.payload.flatMap(user => {
          //     const shifts = (user.rota_shifts.length > 0 && user.rota_shifts[0].shifts) || [];
          //     return shifts.map(shift => ({
          //         start: `${shift.date}T${shift.start_time}`,
          //         end: shift.full_day ? null : `${shift.date}T${shift.end_time}`,
          //         title: shift.title,
          //         resource: user.id
          //     }));
          // });
          // const usersArray = data.payload.map(user => ({
          //     id: user.id,
          //     name: `${user.first_name} ${user.last_name}`,
          //     color: user.color
          // }));

          let newShifts = data.payload.shifts;
          let holidays2 = [];
          data.payload.users.map((item) => {
            item.leave_applications.map((subs) => {
              if (subs.status == "APPROVED") {
                subs.leaves.map((lev) => {
                  if (lev.working_day == "1") {
                    holidays2.push({
                      // start: `${lev.day}T00:00`,
                      // end: `${lev.day}T00:00`,
                      title: (
                        <div className="text-center mainColerdDiv">
                          <p style={{ fontSize: "20px" }}>{subs?.type?.name}</p>
                          <h6>{subs?.type?.type}</h6>
                          <h4>{`${
                            subs?.type?.paid == "1" ? "Paid" : "Unpaid"
                          }`}</h4>
                          <i>{nameOfdurationChange(lev?.duration)}</i>
                        </div>
                      ),
                      resource: item.id,
                      date: `${lev.day}T00:00`,
                      background: "white",
                      cssClass:
                        lev?.duration == "FIRST_HALF"
                          ? "akiiaa firstHalfStyling"
                          : lev?.duration == "SECOND_HALF"
                          ? "akiiaa secondHalfStyling"
                          : "akiiaa",
                      background:
                        lev?.duration == "FULL_DAY" ? "#fde4c880" : "",
                    });
                  }
                });
              }
            });
          });
          // setholidaysColor(holidays2)

          let newArr = [];
          data.payload.users.map((item) => {
            if (
              // item.work_pattern.monday == 1 ||
              // item.work_pattern.tuesday == 1
              item.work_pattern
            ) {
              newArr.push({
                recurring: {
                  repeat: "weekly",
                  weekDays: renderrecoue(item),
                },
                // title:"chutti",
                cssClass: "hadhybhai",
                resource: item.id,
              });
            }
          });
          // let holidays = []
          data.payload.shifts.map((userone, userIndex) => {
            // holidays.push({
            //   date: `${userone.date}T00:00`,
            //   background: 'white',
            //   resource: userone.user_id,
            //   cssClass: 'hadhybhai',
            //   recurring: {
            //     repeat: 'weekly',
            //     weekDays: 'MO,TU,WE,TH,FR'
            // },
            // })
            userone["resource"] = parseInt(userone.user_id);
            userone["holiday"] = userone.user_on_leave;
            userone["hide"] = userone.hide_time;
            userone["start"] = `${userone.date}T${
              userone.start_time ? userone.start_time : "00:00:00"
            }`;
            userone["end"] = `${userone.date}T${
              userone.end_time ? userone.end_time : "00:00:00"
            }`;
            userone["isFullDay"] =
              userone.full_day == "1" ? Boolean(true) : Boolean(false);
            userone["isRe"] =
              userone.recurring == "1" ? Boolean(true) : Boolean(false);
            userone["hideTime"] =
              userone.hide_time == "1" ? Boolean(true) : Boolean(false);
            userone["isGeoFense"] =
              userone.geo_fence == "1" ? Boolean(true) : Boolean(false);
            // userone['color'] = `#${getColor()}`

            // userone['timeShow'] = `${userone.title}`
            userone["allDay"] =
              userone.full_day == "1" ? Boolean(true) : Boolean(false);
            userone["draft"] = userone.draft;
            userone["sposedId"] = randstr(Math.floor(Math.random() * 100));
            userone["offline"] =
              userone.offline == "1" ? Boolean(true) : Boolean(false);
            userone["is_overlapped"] =
              userone.is_overlapped == "1" ? Boolean(true) : Boolean(false);
            userone["parentId"] = "";

            newShifts[userIndex] = userone;
            delete userone.recurring;
          });
          // setInvalid(newArr)
          setholidaysColor(newArr.concat(holidays2));

          setTestUser(newUsers);
          setEventList(newShifts);
          setOrignalData(newShifts);

          const checkingDisable = newShifts.filter((item) => item.offline);
          const overLap = newShifts.filter((item) => item.is_overlapped);

          setOverLapShifts(overLap);
          if (checkingDisable.length == 0) {
            setDraftButtonDisable(true);
          } else {
            setDraftButtonDisable(false);
          }
          setIsLoading(false);
          setCopyOflineLoading(false);
          setMoveDraftModalLoading(false);
          setMoveDraftModal(false);
          setMoveDraftToLiveModalLoading(false);
          setMoveDraftToLiveModal(false);
          setRevertModalLoading(false);
          setRevertModal(false);
          setOflineMode(false);
          setValidatingLoading(false);
          setUpdateOflineShift([]);
          setCopyDataForapi([]);
          whyNotWorking();
          setTagLoading(false);
          setLoadingRevertLive(false);
          resolve({ success: true, shifts: newShifts });
        })
        .catch((err) => {
          setIsLoading(false);
          reject(err);
        });
    });
  };

  const renderrecoue = (item) => {
    let str = "MO";
    // const daysOfWeek = [
    //   "MONDAY",
    //   "TUESDAY",
    //   "WEDNESDAY",
    //   "THURSDAY",
    //   "FRIDAY",
    //   "SATURDAY",
    //   "SUNDAY",
    // ];
    const daysOfWeek = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    const weekdaysWithOneValue = daysOfWeek.filter(
      (weekday) => item.work_pattern[weekday] == 1
    );

    const firstTwoLettersOfWeek = weekdaysWithOneValue.map((day) =>
      day.substring(0, 2)
    );

    const formattedString = firstTwoLettersOfWeek.slice(0, 7).join(",");
    str = formattedString;

    return str;
  };
  const whyNotWorking = () => {
    // const worldText = document.getElementsByClassName('mbsc-schedule-color-text');
    // const styledText = worldText.replace('Unpaid', '<span style="color: blue; font-weight: bold;">Unpaid</span>');
    // document.getElementsByClassName('mbsc-schedule-color-text').innerHTML = styledText;
    // console.log(worldText,'had hy bhai')
  };
  const onEventCreateFailed = React.useCallback(() => {});

  const colorOptions = [
    // { value: "#FF0000", label: "Red" },
    // { value: "#00FF00", label: "Green" },
    // { value: "#0000FF", label: "Blue" },
    // { value: "#FF69B4", label: "HotPink" },
    // { value: "#FFA07A", label: "LightSalmon" },
    // { value: "#BDB76B", label: "DarkKhaki" },
    // { value: "#663399", label: "RebeccaPurple" },
    // { value: "#32CD32", label: "LimeGreen" },
    // { value: "#6B8E23", label: "OliveDrab" },
    // { value: "#48D1CC", label: "MediumTurquoise" },
    // { value: "#5F9EA0", label: "CadetBlue" },
    { value: "#ADD8E6", label: "LightBlue" },
    { value: "#E0FFFF", label: "LightCyan" },
    { value: "#E6E6FA", label: "Lavender" },
    { value: "#D8BFD8", label: "Thistle" },
    { value: "#F0E68C", label: "Khaki" },
    { value: "#F5DEB3", label: "Wheat" },
    { value: "#FFFACD", label: "LemonChiffon" },
    { value: "#FFEFD5", label: "PapayaWhip" },
    { value: "#FFE4E1", label: "MistyRose" },
    { value: "#F0FFF0", label: "Honeydew" },
  ];
  
  function disableOptionsByGap(startDate, endDate) {
    const dayDifference = Math.floor((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
  
    const updatedOptions = repeatArray.map(option => {
      switch (option.value) {
        case "everyday":
          return { ...option, isDisabled: dayDifference >= 1 };
        case "weekly":
          return { ...option, isDisabled: dayDifference >= 7 };
        case "every_two_week":
          return { ...option, isDisabled: dayDifference >= 14 };
        case "every_three_week":
          return { ...option, isDisabled: dayDifference >= 21 };
        case "monthly":
          return { ...option, isDisabled: dayDifference >= 30 };
        case "every_other_month":
          return { ...option, isDisabled: dayDifference >= 60 };
        case "yearly":
          return { ...option, isDisabled: dayDifference >= 365 };
        default:
          return option;
      }
    });
  
    return updatedOptions;
  }
  
  const timing = [
    { label: "Morning", value: "Morning" },
    { label: "Evening", value: "Evening" },
    { label: "Other", value: "Other" },
    // { label: "Custom", value: "custom" },
  ];

  const onEventClick = async (event) => {
    // const event = args.event;

    if (event?.completed == "1") {
      toast("Shif is completed you cannot edit it", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: event.color == null ? "black" : event.color,
          color: "#fff",
        },
      });
    } else {
      setSelectedId(event.id);
      setSelectedObject(event);
      const resultant = colorOptions.find((item) => item.value == event.color);
      //  const resultant2= rotaUsers.find((item)=>item.value==event.user_id)
      setDefaultColor(resultant);
      //  setDefaultUser(resultant2)
      // setShiftdate(event.date)
      // setstartTime(event.start)
      // setendTime(event.end)
      // setTitle(event.title)
      console.log(event)
      setLiveRotaSelectedShift(event)
      if(event.isRe){
        // setEditDrawerModal(true);
        setReCureEditModal2(true)
      }else{
        setTimeout(() => {
      setrepeatoptions(repeatArray)
          setEditDrawerModal2(true)
          
        }, 0);
        // setCheckingModal(true)
      }
    }
  };

  function isDateBeforeToday(targetDate) {
    const currentDate = new Date(); // Create a Date object for the current date

    // Set the time portion of currentDate to midnight (00:00:00)
    currentDate.setHours(0, 0, 0, 0);

    // Set the time portion of the targetDate to midnight (00:00:00)
    targetDate.setHours(0, 0, 0, 0);

    // Compare the targetDate with the currentDate
    return targetDate < currentDate;
  }

  const newFunc = (users, reg, date) => {
    let resultUser = users.find((item) => item.id == reg);
    const dayOfWeek = date.getDay();
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const currentDay = daysOfWeek[dayOfWeek];
    return resultUser.work_pattern[currentDay] == 1;
  };

  const onClickCell = (args, res) => {
    if (!newFunc(res, args.resource, new Date(args.date))) {
      toast.error("User does not have work pattren on this date");
      return;
    } else if (isDateBeforeToday(new Date(args.date))) {
      toast.error("You Cannot Create Past Days Shift");
      return;
    } else {
      setStartDate(new Date(args.date));
      setEndDate(new Date(args.date));
      getNext7Years(new Date(args.date))
      checkIfUseronLeave(args);
      setIsEdit(false);
      setSelectedResource(args.resource);
      let dd = new Date(args.date);
      let fd = new Intl.DateTimeFormat("en-us", {
        dateStyle: "full",
      });
      setCurrentDate(fd.format(dd));
      setShiftdate(args.date);
      let newD = `${new Date(args.date).getFullYear()}-${new Date(
        args.date
      ).getMonth() + 1}-${new Date(args.date).getDate()}`;

      fetch(`${baseUrl}/mgmt/rotas/${rotaId}/assigned-users`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => res.json())

        .then(async (data) => {
          let tempArray = [];
          data.payload.forEach((item, index) => {
            tempArray.push({
              label: item.first_name,
              value: item.id,
            });
          });

          if (tempArray) {
            let filterd = tempArray.filter(
              (user) => user.value == args.resource
            );
            let filterd2 = filterd.map((user) => user.value);
            if (PremissionChecker(user, "rota_shift.create")) {
              setselectedUsers(filterd2);
              setDefaultColor(null);
              setColor("");
              setDraft(true);
              setHideShow(true);
              setIsDrawerOpen(true);
      setrepeatoptions(repeatArray)
            } else {
              toast.error("Do not have permission to create rota shift.");
            }
          }
          // setrotaUsers(tempArray)

          // setUsersList2(data.payload)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const checkIfUseronLeave = (event) => {
    let newDate = moment(event.date).format("YYYY-MM-DD");
    fetch(
      `${baseUrl}/mgmt/asset/users/${event.resource}/on-leave/date/${newDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setOnLeaveToday(data?.payload?.has_leave);
      })
      .catch((err) => {});
  };

  const onChangeDate = (date, dateString) => {
    setShiftdate(dateString);
  };

  const onChangeStartTime = (time) => {
    setstartTime(time);
  };

  const onChangeEndTime = (time) => {
    setendTime(time);
  };

  const CheckerEvent = (isDraft) => {
    setlocError("");
    if (onLeaveToday) {
      setConfirmModal(true);
    } else {
      setConfirmModal(false);
      if (selectedUsers.length < 0) {
        toast.error("Please select Users");
      } else {
        saveEvent(isDraft);
      }
    }
  };

  function formatDateEndRepeat(dateString) {
    if(!dateString){
      return
    }
    var parts = dateString.split(dateString.includes("-") ? "-" : "/");
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];
  
    day = day.padStart(2, '0');
    month = month.padStart(2, '0');
  
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }
  
  function formatDateEndRepeat2(dateString) {
    if(!dateString){
      return
    }
    var parts = dateString.split(dateString.includes("-") ? "-" : "/");
    var day = parts[2];
    var month = parts[1];
    var year = parts[0];
  
    day = day.padStart(2, '0');
    month = month.padStart(2, '0');
  
    var formattedDate = day + "/" + month + "/" +  year ;
    return formattedDate;
  }
  
  const saveEvent = (isDraft) => {
    if(color==""){
      return toast.error("Please select the color")
    }
    if(!repeat){
      return toast.error("Please select the Repeat")
    }
    setConfirmModal(false);
    let users_idss = selectedUsers.map((item) => item.value);
    let formatedDate = moment(shiftDate).format("YYYY-MM-DD");

    let formatStartDate = moment(startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(endDate).format("YYYY-MM-DD");
    let formatedStartTime = moment(startTime).format("HH:mm:ss");
    let formatedEndTime = moment(endTime).format("HH:mm:ss");

    if (formatStartDate == "Invalid date" || formatEndDate == "Invalid date") {
      return toast.error("Please Select Data Range");
    }
    if (isGeoFence == 1) {
      if (latitude == null || latitude == null) {
        return toast.error("Please Select Location");
      }
    }
    setAddLoading(true);

    const data = {
      title: selectedOption == "Other" ? title : selectedOption,
      start_date_range:formatStartDate,
      end_date_range:formatEndDate,
      start_date: formatStartDate || "",
      end_date: formatEndDate || "",
      full_day: 0,
      start_time: startTime.value,
      end_time: !isfullDay && endTime?.value,
      color: color,
      geo_fence: isGeoFence ? 1 : 0,
      latitude: isGeoFence ? latitude : null,
      longitude: isGeoFence ? longitude : null,
      address: isGeoFence ? address : null,
      draft: draft ? 1 : 0,
      user_ids: selectedUsers,
      repeat: repeat,
      is_never: repeat == "never"
      ? false
      : endRepeat
      ? false
      : true,
      repeat_end_date:
        repeat == "never"
          ? ""
          : endRepeat
          ? formatDateEndRepeat(endRepeatDate)
          : next7ear,
      hide_time: 0,
      offline: false,
      rota_setting_id: rotaSettingId,
    };

    const formData = new FormData();

formData.append('title', selectedOption == "Other" ? title : selectedOption);
formData.append('start_date_range', formatStartDate);
formData.append('end_date_range', formatEndDate);
formData.append('start_date', formatStartDate || "");
formData.append('end_date', formatEndDate || "");
formData.append('full_day', 0);
formData.append('start_time', startTime.value);
formData.append('end_time', !isfullDay && endTime?.value ? endTime.value : "");
formData.append('color', color);
formData.append('geo_fence', isGeoFence ? 1 : 0);
formData.append('latitude', isGeoFence ? latitude : '');
formData.append('longitude', isGeoFence ? longitude : '');
formData.append('address', isGeoFence ? address : '');
formData.append('draft', draft ? 1 : 0);
selectedUsers.map((item,i)=>{
  formData.append(`user_ids[${[i]}]`, item);
})
formData.append('repeat', repeat);
formData.append(
  'is_never',
  repeat == "never" ? false : endRepeat ? false : true
);
formData.append(
  'repeat_end_date',
  repeat == "never"
    ? ""
    : endRepeat
    ? formatDateEndRepeat(endRepeatDate)
    : next7ear
);
formData.append('hide_time', 0);
formData.append('offline', 0);
if(rotaSettingId){
  formData.append('rota_setting_id', rotaSettingId);
}


    let s = `${new Date(startTime).getHours()}:${new Date(
      startTime
    ).getMinutes()}:${new Date(startTime).getSeconds()}`;
    let e = `${new Date(endTime).getHours()}:${new Date(
      endTime
    ).getMinutes()}:${new Date(endTime).getSeconds()}`;

    // const data2 = {
    //   title: selectedOption == "Other" ? title : selectedOption,
    //   start_date_range:formatStartDate,
    //   end_date_range:formatEndDate,
    //   start_date: formatStartDate || "",
    //   end_date: formatEndDate || "",
    //   full_day: 1,
    //   color: color,
    //   geo_fence: isGeoFence ? 1 : 0,
    //   latitude: isGeoFence ? latitude : null,
    //   longitude: isGeoFence ? longitude : null,
    //   address: isGeoFence ? address : null,
    //   draft: draft ? 1 : 0,
    //   start_time: startTime.value,
    //   user_ids: selectedUsers,
    //   repeat: repeat,
    //   is_never: repeat == "never"
    //   ? false
    //   : endRepeat
    //   ? false
    //   : true,
    //   repeat_end_date:
    //     repeat == "never"
    //       ? ""
    //       : endRepeat
    //       ? formatDateEndRepeat(endRepeatDate)
    //       : next7ear,
    //   hide_time: 0,
    //   offline: false,
    //   rota_setting_id: rotaSettingId,
    // };


    const formData2 = new FormData();

formData2.append('title', selectedOption == "Other" ? title : selectedOption);
formData2.append('start_date_range', formatStartDate);
formData2.append('end_date_range', formatEndDate);
formData2.append('start_date', formatStartDate || "");
formData2.append('end_date', formatEndDate || "");
formData2.append('full_day', 1);
formData2.append('color', color);
formData2.append('geo_fence', isGeoFence ? 1 : 0);
formData2.append('latitude', isGeoFence ? latitude : '');
formData2.append('longitude', isGeoFence ? longitude : '');
formData2.append('address', isGeoFence ? address : '');
formData2.append('draft', draft ? 1 : 0);
formData2.append('start_time', startTime.value);
selectedUsers.map((item,i)=>{
  formData2.append(`user_ids[${[i]}]`, item);
})
formData2.append('repeat', repeat);
formData2.append(
  'is_never',
  repeat == "never" ? false : endRepeat ? false : true
);
formData2.append(
  'repeat_end_date',
  repeat == "never"
    ? ""
    : endRepeat
    ? formatDateEndRepeat(endRepeatDate)
    : next7ear
);
formData2.append('hide_time', 0);
formData2.append('offline', 0);
if(rotaSettingId){
  formData2.append('rota_setting_id', rotaSettingId);
}



    let url = `${baseUrl}/mgmt/rotas/${rotaId}/rota-shifts`;
    let draftUrl = `${baseUrl}/mgmt/rotas/${rotaId}/draft-rota-shifts`;

    fetch(isDraft ? draftUrl : url, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      // body: isfullDay ? JSON.stringify(data2) : JSON.stringify(data),
      body: isfullDay ? formData2 : formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setAddLoading(false);
          getRotaCalendar();
          setIsDrawerOpen(false);
          setShiftdate("");
          setIsFullday(false);
          setstartTime({ label: "9:00 am", value: "09:00:00" });
          setendTime({ label: "6:00 pm", value: "18:00:00" });
          setTitle("");
          setShowEndDate("");
          setSelectedOption("");
          setShowStartDate("");
          setEndRepeat(false);
          setEndDate(null);
          setSelectedTemplate("");
          setRepeat("never");
          setStartDate(null);
          setDefaultColor(null);
          setDraft(false);
          setConfirmModal(false);
          setValueOftitme({});
          setRepeatValue({ label: "Never", value: "never" });
          setShowTextBox(false);
          setRotaSettingId(null);
          setselectedUsers([]);
          if (!draft) {
            selectedUsers.map((id) => {
              const extraData = {
                type: "create_shift",
                userId: id,
              };
              if (rota?.is_draft == false) {
                SendNotifaction(
                  title,
                  isfullDay
                    ? `Your shift is created at ${formatedDate} and Shift start time is ${startTime.value}`
                    : `Your shift is created at ${formatedDate} and Shift start time is ${startTime.value}  and ended at ${endTime.value} `,
                  id,
                  "",
                  extraData
                );
              }
            });
          }
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            if(data.metadata.message !=""){
              toast.error(data.metadata.message);
            }
          }
          setEndRepeatDate("");
          setAddLoading(false);
        } else {
          setEndRepeatDate("");
          toast.error(`Something went wrong`);
          setAddLoading(false);
        }
      })
      .catch((err) => {
        setAddLoading(false);
      });
  };

  function calculateHours(startTime, endTime) {
    // Parse the time strings into Date objects
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);

    // Calculate the difference in milliseconds
    let timeDifference = end - start;

    // If start time is greater than end time, add 24 hours to the difference
    if (start > end) {
      timeDifference += 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    }

    // Convert the time difference to hours and minutes
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    // Format the result as 'HH:mm'
    const formattedResult = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;

    return formattedResult;
  }

  const saveTemplate = () => {
    if(color==""){
      return toast.error("Please select the color")
    }
    setConfirmModal(false);
    setAddLoading(true);

    let formatStartDate = moment(startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(endDate).format("YYYY-MM-DD");
    let formatedStartTime = moment(startTime).format("HH:mm:ss");
    let formatedEndTime = moment(endTime).format("HH:mm:ss");

    const data = {
      title: selectedOption == "Other" ? title : selectedOption,
      start_date: formatStartDate || "",
      end_date: formatEndDate || "",
      full_day: 0,
      start_time: startTime.value,
      end_time: !isfullDay && endTime.value,
      color: color,
      geo_fence: isGeoFence ? 1 : 0,
      latitude: isGeoFence ? latitude : null,
      longitude: isGeoFence ? longitude : null,
      address: isGeoFence ? address : null,
      draft: draft ? 1 : 0,
      user_ids: selectedUsers,
      hide_time: hideShow == true ? 1 : 0,
      repeat: repeat,
      is_never: repeat == "never"
      ? false
      : endRepeat
      ? false
      : true,
      repeat_end_date:
        repeat == "never"
          ? ""
          : endRepeat
          ? formatDateEndRepeat(endRepeatDate)
          : next7ear,
    };

    let s = `${new Date(startTime).getHours()}:${new Date(
      startTime
    ).getMinutes()}:${new Date(startTime).getSeconds()}`;
    let e = `${new Date(endTime).getHours()}:${new Date(
      endTime
    ).getMinutes()}:${new Date(endTime).getSeconds()}`;

    const data2 = {
      title: selectedOption == "Other" ? title : selectedOption,
      start_date: formatStartDate || "",
      end_date: formatEndDate || "",
      full_day: 1,
      color: color,
      geo_fence: isGeoFence ? 1 : 0,
      latitude: isGeoFence ? latitude : null,
      longitude: isGeoFence ? longitude : null,
      address: isGeoFence ? address : null,
      draft: draft ? 1 : 0,
      start_time: startTime.value,
      user_ids: selectedUsers,
      hide_time: hideShow == true ? 1 : 0,
      repeat: repeat,
      is_never: repeat == "never"
      ? false
      : endRepeat
      ? false
      : true,
      repeat_end_date:
        repeat == "never"
          ? ""
          : endRepeat
          ? formatDateEndRepeat(endRepeatDate)
          : next7ear,
    };

    let url = `${baseUrl}/mgmt/rota-shift-templates`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: isfullDay ? JSON.stringify(data2) : JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setAddLoading(false);
          getTemplates();
          // setIsDrawerOpen(false);
          setShiftdate("");
          setIsFullday(false);
          setstartTime({ label: "9:00 am", value: "09:00:00" });
          setendTime({ label: "6:00 pm", value: "18:00:00" });
          setTitle("");
          setSelectedTemplate("");
          setConfirmModal(false);
          setEndRepeat(false);
          setShowEndDate("");
          setShowStartDate("");
          setEndDate(null);
          setStartDate(null);
          setValueOftitme({});
          setDefaultColor(null);
          setDraft(false);
          setRepeatValue({ label: "Never", value: "never" });
          setSelectedOption("");
          setRepeat("never");
          setShowTextBox(false);
          setRotaSettingId(null);
          setselectedUsers([]);
          // getTemplates();
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
            setEndRepeatDate("");
            setAddLoading(false);
          });
        } else {
          toast.error(`Something went wrong`);
          setEndRepeatDate("");
          setAddLoading(false);
        }
      })
      .catch((err) => {
        setEndRepeatDate("");
        setAddLoading(false);
      });
  };

  const handleSelect = (result) => {
    geocodeByPlaceId(result.value.place_id)
      .then((results) => {
        const { lat, lng } = results[0].geometry.location;

        setAddress(results[0].formatted_address);
        setLatitude(lat());
        setLongitude(lng());
      })
      .catch((error) => {});
  };

  const getNext7Years = (startDate) => {
  
    var currentDate =startDate ?  new Date(startDate) : new Date();
    var year = currentDate.getFullYear() + 5; 
    var month = currentDate.getMonth() + 1;
    var day = currentDate.getDate();
    var todayDateString =
      year +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      (day < 10 ? "0" : "") +
      day;
      setNext7Year(todayDateString);
  };
  
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };
  const getHourseToString = () => {
    const sTime = moment(startTime);
    const eTime = moment(endTime);

    const duration = moment.duration(eTime.diff(sTime));
    const formattedDuration = moment
      .utc(duration.asMilliseconds())
      .format("HH:mm [hours]");
    return formattedDuration;
  };
  const formetTime = (date) => {
    let d = new Date(date);

    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  const checkerHoliday = (item, date) => {
    console.log(item, date, "ya hy user");
    let hasLeave = false;
    item.leave_applications.forEach((subs) => {
      if (subs.status === "APPROVED") {
        subs.leaves.forEach((lev) => {
          if (lev.working_day == "1" && lev.day == date) {
            hasLeave = true;
          }
        });
      }
    });
    return hasLeave;
  };

  const dataConverterForClander = (array) => {
    let newShifts = toString.call(array) == "[object Object]" ? [array] : array;
    newShifts.forEach((userone, userIndex) => {
      const findUser = usersList.find((user) => user.id == userone.user_id);
      console.log(checkerHoliday(findUser, userone.date), "ya hy user");
      userone["resource"] = parseInt(userone.user_id);
      // userone["holiday"] = userone.user_on_leave;
      userone["holiday"] = checkerHoliday(findUser, userone.date);
      userone["hide"] = userone.hide_time;
      userone["start"] = `${userone.date}T${
        userone.start_time ? userone.start_time : "00:00:00"
      }`;
      userone["end"] = `${userone.date}T${
        userone.end_time ? userone.end_time : "00:00:00"
      }`;
      userone["isFullDay"] =
        userone.full_day == "1" ? Boolean(true) : Boolean(false);
      userone["isRe"] =
        userone.recurring == "1" ? Boolean(true) : Boolean(false);
      userone["hideTime"] =
        userone.hide_time == "1" ? Boolean(true) : Boolean(false);
      userone["isGeoFense"] =
        userone.geo_fence == "1" ? Boolean(true) : Boolean(false);
      // userone['color'] = `#${getColor()}`

      // userone['timeShow'] = `${userone.title}`
      userone["allDay"] =
        userone.full_day == "1" ? Boolean(true) : Boolean(false);
      userone["draft"] = userone.draft;
      userone["sposedId"] = randstr(Math.floor(Math.random() * 100));
      userone["offline"] =
        userone.offline == "1" ? Boolean(true) : Boolean(false);
      userone["is_overlapped"] =
        userone.is_overlapped == "1" ? Boolean(true) : Boolean(false);
      userone["parentId"] = "";

      newShifts[userIndex] = userone;
      delete userone.recurring;
    });
    return newShifts;
  };

  const handleDrage = async (some) => {
    let userId = [];
    userId.push(some.resource);
    const data = {
      // user_id: some.resource,
      title: some.title,
      // date: formetTime(some.end),
      start_date_range:formetTime(some.end),
      start_date: formetTime(some.end),
      end_date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      end_time: some.end_time != null ? some.end_time : "00:00:00",
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: some.draft,
      hide_time: some.hide,
      ///onlyforAddd
      user_ids: userId,
      repeat: repeat,
      offline: false,
    };
    const data2 = {
      // user_id: some.resource,
      title: some.title,
      start_date_range:formetTime(some.end),
      // date: formetTime(some.end),
      start_date: formetTime(some.end),
      end_date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      // end_time: some.end_time !=null?some.end_time:"00:00:00" ,
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: some.draft,
      ///onlyforAddd
      user_ids: userId,
      hide_time: some.hide,
      repeat: repeat,
      offline: false,
    };

    ///addd
    let draftUrl = `${baseUrl}/mgmt/rotas/${rotaId}/draft-rota-shifts`;
    let url = `${baseUrl}/mgmt/rotas/${rotaId}/rota-shifts`;
    fetch(draftUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: some.full_day == 1 ? JSON.stringify(data2) : JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.metadata.success) {
          const convertshifts = await dataConverterForClander(data.payload);
          setEventList((prevEventList) => prevEventList.concat(convertshifts));
          setEventList((prev) => {
            const index = prev.findIndex(
              (item) => item.sposedId === some.sposedId
            );
            const updatedArray = [...prev];
            some["start"] = `${formetTime(some.date)}T${
              some.start_time ? some.start_time : "00:00:00"
            }`;
            some["end"] = `${formetTime(some.date)}T${
              some.end_time ? some.end_time : "00:00:00"
            }`;
            some["resource"] = some.user_id;
            updatedArray[index] = {
              ...some,
            };
            return updatedArray;
          });
          setIsChange(true)
        } else if (!data.metadata.success) {
          setEventList((prev) => {
            const index = prev.findIndex(
              (item) => item.sposedId === some.sposedId
            );
            const updatedArray = [...prev];
            some["start"] = `${formetTime(some.date)}T${
              some.start_time ? some.start_time : "00:00:00"
            }`;
            some["end"] = `${formetTime(some.date)}T${
              some.end_time ? some.end_time : "00:00:00"
            }`;
            some["resource"] = some.user_id;
            updatedArray[index] = {
              ...some,
            };
            return updatedArray;
          });
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            if(data.metadata.message !=""){
              toast.error(data.metadata.message);
            }
          }
          setAddLoading(false);
        } else {
          toast.error(`Something went wrong`);
          setAddLoading(false);
        }
      })
      .catch((err) => {
        setAddLoading(false);
      });
  };
  function addDaysInRange(date, startDate, endDate) {
    // Ensure all inputs are valid Date objects
    const initialDate = new Date(date);
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    if (isNaN(initialDate) || isNaN(start) || isNaN(end)) {
      throw new Error("Invalid date provided");
    }
  
    if (start > end) {
      throw new Error("startDate cannot be after endDate");
    }
  
    // Calculate the total number of days in the range (inclusive)
    const daysInRange = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  
    // Add days to the initial date
    initialDate.setDate(initialDate.getDate() + daysInRange);
  
    return initialDate;
  }

  console.log(eventList,"here we go with convert shifts=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

  const handleMove = async (future) => {
    console.log(updateData,"what happining with repeat")
    let userId = [];
    userId.push(updateData.resource);
    const data = {
      user_ids: [updateData.resource],
      title: updateData.title,
      date: formetTime(updateData.end),
      move_shift:1,
      start_date:formetTime(updateData.end),
      end_date:formetTime(updateData.end),
      // start_date:future==0 ? formetTime(updateData.end) : formetTime(updateData.end),
      // end_date:future==0 ? formetTime(updateData.end) :  formetTime(addDaysInRange(updateData.end,updateData.start_date_range,updateData.end_date_range)),
      // date:formetTime(updateData.end),
      full_day: updateData.full_day,
      start_time:
        updateData.start_time != null ? updateData.start_time : "00:00:00",
      end_time: updateData.end_time != null ? updateData.end_time : "00:00:00",
      color: updateData.color != null ? updateData.color : "",
      geo_fence: updateData.geo_fence,
      latitude: updateData.latitude != null ? updateData.latitude : "",
      longitude: updateData.longitude != null ? updateData.longitude : "",
      address: updateData.address != null ? updateData.address : "",
      draft: updateData.draft,
      hide_time: updateData.hide,
      future_shifts_edit: future,
      offline: true,
      repeat:updateData.repeat,
      // repeat_end_date:
      // updateData.repeat_end_date,
      rota_setting_id: updateData.rota_setting_id,

      ///onlyforAddd
      // user_ids: userId
    };
    const formData = new FormData();
    formData.append(`user_ids[0]`, updateData.resource);
    
    // formData.append("user_ids[]", updateData.resource); // Assuming `resource` is a single value
    formData.append("title", updateData.title);
    formData.append("date", formetTime(updateData.end));
    formData.append("move_shift", "1");
    formData.append("start_date", formetTime(updateData.end));
    formData.append("end_date", formetTime(updateData.end));
    formData.append("full_day", updateData.full_day);
    formData.append("start_time", updateData.start_time != null ? updateData.start_time : "00:00:00");
    formData.append("end_time", updateData.end_time != null ? updateData.end_time : "00:00:00");
    formData.append("color", updateData.color != null ? updateData.color : "");
    formData.append("geo_fence", updateData.geo_fence);
    formData.append("latitude", updateData.latitude != null ? updateData.latitude : "");
    formData.append("longitude", updateData.longitude != null ? updateData.longitude : "");
    formData.append("address", updateData.address != null ? updateData.address : "");
    formData.append("draft", updateData.draft);
    formData.append("hide_time", updateData.hide);
    formData.append("future_shifts_edit", future > 0 ? 1 : 0);
    formData.append("offline", 1);
    formData.append("repeat", future==0 ? 'never' : updateData.repeat_type);
    
    if(updateData.rota_setting_id){
      formData.append("rota_setting_id", updateData.rota_setting_id);
   }
    const data2 = {
      user_ids: [updateData.resource],
      title: updateData.title,
      date: formetTime(updateData.end),
      move_shift:1,

      // start_date:future==0 ? formetTime(updateData.end) : formetTime(updateData.end),
      // end_date:future==0 ? formetTime(updateData.end) :  formetTime(addDaysInRange(updateData.end,updateData.start_date_range,updateData.end_date_range)),
      // date:formetTime(updateData.end),
      full_day: updateData.full_day,
      start_time:
        updateData.start_time != null ? updateData.start_time : "00:00:00",
      // end_time: updateData.end_time !=null?updateData.end_time:"00:00:00" ,
      color: updateData.color != null ? updateData.color : "",
      geo_fence: updateData.geo_fence,
      latitude: updateData.latitude != null ? updateData.latitude : "",
      longitude: updateData.longitude != null ? updateData.longitude : "",
      address: updateData.address != null ? updateData.address : "",
      draft: updateData.draft,
      hide_time: updateData.hide,
      future_shifts_edit: future,
      offline: true,
      repeat:updateData.repeat,
      // repeat_end_date:
      // updateData.repeat_end_date,
      rota_setting_id: updateData.rota_setting_id,

      ///onlyforAddd
      // user_ids: userId
    };
    const formData2 = new FormData();
    formData2.append(`user_ids[0]`, updateData.resource);
    // formData2.append("user_ids[]", updateData.resource); 
    formData2.append("title", updateData.title);
    formData2.append("start_date", formetTime(updateData.end));
     formData2.append("end_date", formetTime(updateData.end));
    formData2.append("date", formetTime(updateData.end));
    formData2.append("move_shift", "1");
    formData2.append("full_day", updateData.full_day);
    formData2.append("start_time", updateData.start_time != null ? updateData.start_time : "00:00:00");
    formData2.append("color", updateData.color != null ? updateData.color : "");
    formData2.append("geo_fence", updateData.geo_fence);
    formData2.append("latitude", updateData.latitude != null ? updateData.latitude : "");
    formData2.append("longitude", updateData.longitude != null ? updateData.longitude : "");
    formData2.append("address", updateData.address != null ? updateData.address : "");
    formData2.append("draft", updateData.draft);
    formData2.append("hide_time", updateData.hide);
    formData2.append("future_shifts_edit", future > 0 ? 1 : 0);
    formData2.append("offline", 1);
    formData2.append("repeat",future==0 ? 'never' : updateData.repeat_type);
    if(updateData.rota_setting_id){
      formData2.append("rota_setting_id", updateData.rota_setting_id);

    }
    //update
    let draftUrl = `${baseUrl}/mgmt/draft-rota-shifts/${updateData.id}?_method=PUT`;
    fetch(draftUrl, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body:updateData.full_day == "1" ? formData2 : formData,
    })
      .then((resp) => {
        resp.json().then(async (data) => {
          if (data.metadata) {
            setReCureEditModal(false);
            if (data.metadata.success) {
              let newShifts =
                toString.call(data.payload) == "[object Object]"
                  ? [data.payload]
                  : data.payload;
              const indexAyyyaOfIdz = [];
              newShifts.map((shift) => indexAyyyaOfIdz.push(shift.id));
              const filterAlreadyExitItems = eventList.filter(
                (shift) => !indexAyyyaOfIdz.includes(shift.id)
              );
              let convertshifts = await dataConverterForClander(data.payload);
              const updatedData=filterAlreadyExitItems.concat(convertshifts)
              const overLap = updatedData.filter((item) => item.is_overlapped);
              setOverLapShifts(overLap);
              console.log(convertshifts,"here we go with convert shifts")
              console.log(filterAlreadyExitItems.concat(convertshifts),"here we go with convert shifts=>>>>>>>>")
              convertshifts = convertshifts.map((item) => ({
                ...item,
                start: new Date(item.date),
                end: new Date(item.date),
              }));
              setEventList(filterAlreadyExitItems.concat(convertshifts));
          setIsChange(true)

            } else if (!data.metadata.success) {
              setEventList((prev) => {
                const index = prev.findIndex(
                  (item) => item.sposedId === updateData.sposedId
                );
                const updatedArray = [...prev];
                updateData["start"] = `${formetTime(updateData.date)}T${
                  updateData.start_time ? updateData.start_time : "00:00:00"
                }`;
                updateData["end"] = `${formetTime(updateData.date)}T${
                  updateData.end_time ? updateData.end_time : "00:00:00"
                }`;
                updateData["resource"] = updateData.user_id;
                updatedArray[index] = {
                  ...updateData,
                };
                return updatedArray;
              });
              if (toString.call(data.metadata.message) == "[object Object]") {
                const errorMessageArray = Object.values(
                  data.metadata.message
                ).flatMap((messages) => messages);
                errorMessageArray.forEach((message) => toast.error(message));
              } else if (
                toString.call(data.metadata.message) == "[object Array]"
              ) {
                Object.values(data.metadata.message).map((msg) => {
                  toast.error(msg);
                });
              } else {
                if(data.metadata.message !=""){
                  toast.error(data.metadata.message);
                }
              }
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Opps something wents wronge");
      });
  };

  const handleMoveForSingle = async (some) => {
    let userId = [];
    userId.push(some.resource);
    const data = {
      user_id: some.resource,
      title: some.title,
      date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      end_time: some.end_time != null ? some.end_time : "00:00:00",
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: some.draft,
      hide_time: some.hide,
      move_shift:1,
      future_shifts_edit: 0,
      offline: true,

      ///onlyforAddd
      // user_ids: userId
    };
    const data2 = {
      user_id: some.resource,
      title: some.title,
      date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      // end_time: some.end_time !=null?some.end_time:"00:00:00" ,
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: some.draft,
      hide_time: some.hide,
      move_shift:1,
      future_shifts_edit: 0,
      offline: true,
      ///onlyforAddd
      // user_ids: userId
    };

    //update
    let draftUrl = `${baseUrl}/mgmt/draft-rota-shifts/${some.id}?_method=PUT`;
    fetch(draftUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(some.full_day == "1" ? data2 : data),
    })
      .then((resp) => {
        resp.json().then(async (data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              // const convertshifts= await dataConverterForClander(data.payload)
              // setEventList((prevEventList) => prevEventList.concat(convertshifts));

              let newShifts =
                toString.call(data.payload) == "[object Object]"
                  ? [data.payload]
                  : data.payload;
              const indexAyyyaOfIdz = [];
              newShifts.map((shift) => indexAyyyaOfIdz.push(shift.id));
              const filterAlreadyExitItems = eventList.filter(
                (shift) => !indexAyyyaOfIdz.includes(shift.id)
              );
              const convertshifts = await dataConverterForClander(data.payload);
              const updatedData=filterAlreadyExitItems.concat(convertshifts)
              const overLap = updatedData.filter((item) => item.is_overlapped);
              setOverLapShifts(overLap);
              setEventList(filterAlreadyExitItems.concat(convertshifts));
          setIsChange(true)

            } else if (!data.metadata.success) {
              setEventList((prev) => {
                const index = prev.findIndex(
                  (item) => item.sposedId === some.sposedId
                );
                const updatedArray = [...prev];
                some["start"] = `${formetTime(some.date)}T${
                  some.start_time ? some.start_time : "00:00:00"
                }`;
                some["end"] = `${formetTime(some.date)}T${
                  some.end_time ? some.end_time : "00:00:00"
                }`;
                some["resource"] = some.user_id;
                updatedArray[index] = {
                  ...some,
                };
                return updatedArray;
              });

              if (toString.call(data.metadata.message) == "[object Object]") {
                const errorMessageArray = Object.values(
                  data.metadata.message
                ).flatMap((messages) => messages);
                errorMessageArray.forEach((message) => toast.error(message));
              } else if (
                toString.call(data.metadata.message) == "[object Array]"
              ) {
                Object.values(data.metadata.message).map((msg) => {
                  toast.error(msg);
                });
              } else {
                if(data.metadata.message !=""){
                  toast.error(data.metadata.message);
                }
              }
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Opps something wents wronge");
      });
  };
  const dataSearch = usersList.filter((user) => {
    let text2 = search.toLowerCase();
    const usered = text2 ? user?.name?.toLowerCase().includes(text2) : true;
    return usered;
  });

  const onPublishClick = async () => {
    const response = await fetch(
      `${baseUrl}/mgmt/publish-rota-shifts/${rotaId}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    const data = await response.json();
    if (data.metadata.success) {
      toast.success(data.metadata.message);
      getRotaCalendar();
    } else if (!data.metadata.success) {
      Object.values(data.metadata.message).map((msg) => {
        toast.error(msg);
      });
    }
  };

  // Custom option component to display color and label
  const CustomOption = ({ innerProps, label, data }) => (
    <div
      {...innerProps}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      <div
        style={{
          padding: 10,
          backgroundColor: data.value,
          width: "20px",
          margin: 10,
          height: "20px",
          marginRight: "10px",
          borderRadius: 10,
        }}
      ></div>
      <div>{label}</div>
    </div>
  );

  // Custom value component to display color and label
  const CustomValue = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: data.value,
          width: "20px",
          height: "20px",
          padding: 10,
          margin: 10,
          marginRight: "10px",
          borderRadius: 10,
        }}
      ></div>
      {label}
    </div>
  );

  // Function to format the option label
  const formatOptionLabel = ({ label, value }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          padding: 10,
          backgroundColor: value,
          margin: 10,
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      ></div>
      {label}
    </div>
  );

  const deleteOverlapShifts = (nu) => {
    setOverLapModalLoading(true);
    const url = `mgmt/rotas/${rotaId}/delete-overlapped-shifts`;
    fetch(`${baseUrl}/${url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          if (nu == 0) {
            moveToLive();
          } else {
            publishShiftof();
          }
          setOverLapModalLoading(false);
          setOverLapModal(false);
          setOverlapDraft(false);
        } else if (!data.metadata.success) {
          setOverLapModalLoading(false);
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error(`Something went wrong`);
        }
      })
      .catch(() => {
        setOverLapModalLoading(false);
      });
  };

  const publishShiftof = (e) => {
    const fdata = new FormData();
    for (let i = 0; i < publishShiftArray.length; i++) {
      fdata.append(`shift_ids[${i}]`, publishShiftArray[i].id);
    }
    fetch(`${baseUrl}/mgmt/custom-publish-draft-rota/${rotaId}/shifts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({
        start_date: newStartDate,
        end_date: newEndDate,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          getRotaCalendar();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => {
              toast.error(message);
            });
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error("Unknown error :(");
        }
      })
      .catch((err) => {});
  };

  const removeEvent = (isDraft,id) => {
    setAddLoading(true);
    const url = `mgmt/rota-shifts/${id !=undefined ? id : liveRotaSelectedShift.id}`;
    const draftUrl = `mgmt/draft-rota-shifts/${id !=undefined ? id : liveRotaSelectedShift.id}`;
    fetch(`${baseUrl}/${isDraft ? draftUrl : url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAddLoading(false);
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setReCureDeleteModal(false);
          setDeleteConfirmation(false);
          getRotaCalendar();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error(`Something went wrong`);
        }
      });
  };
  const removeFutureShift = (isDraft,id,userId) => {
    const url = `mgmt/delete-future-shifts/${id !=undefined ? id : liveRotaSelectedShift.id}`;
    const draftUrl = `mgmt/delete-future-draft-shifts/${id !=undefined ? id : liveRotaSelectedShift.id}`;
    setAddLoading(true);
    const formData=new FormData()
    formData.append('user_ids[0]',id !=undefined ? [userId] :liveRotaSelectedShift.user_id)
    fetch(`${baseUrl}/${isDraft ? draftUrl : url}`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      // body:JSON.stringify({user_ids:[id !=undefined ? [userId] :liveRotaSelectedShift.user_id]}),
      body:formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setAddLoading(false);
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setReCureDeleteModal(false);
          getRotaCalendar();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          setAddLoading(false);
          toast.error(`Something went wrong`);
        }
      });
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedUsers,
    options: rotaUsers,
    onChange: (newValue) => {
      setselectedUsers(newValue);
    },
    placeholder: "Select Users...",
    maxTagCount: "responsive",
  };

  let TEMPLATES = [
    {
      shiftTitle: "Morning shift",
      startTime: new Date(),
      endTime: new Date(),
      allDay: false,
      shiftColor: "#ffff",
    },
    {
      shiftTitle: "Shift shift",
      startTime: "09:00AM",
      endTime: "17:30PM",
      allDay: true,
      shiftColor: "#ffff",
    },
  ];
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const formattedStartDate = startDate ? startDate.format("YYYY-MM-DD") : "";
    const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
    setShowStartDate(formattedStartDate);
    setShowEndDate(formattedEndDate);
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };
  const handleSetDefaultDates = (dd, dd2) => {
    setStartDate(moment(dd));
    setEndDate(moment(dd2));
  };
  const handleCancel = () => {
    setPikerModal(false);
  };
  const handleOk = () => {
    if (showEndDate == "") {
      setShiftdate(showStartDate);
      setEndDate(startDate);
    }
    setPikerModal(false);
  };

  const handleRepeat = (e) => {
    setRepeatValue(e);
    setRepeat(e.value);
    if (e.value == "never") {
      setEndRepeat(false);
    }
  };

  const handlePickerChange = (e) => {
    if (e == null) {
      setStartDate(null);
      setEndDate(null);
      setrepeatoptions(repeatArray)
    } else {
      setrepeatoptions(disableOptionsByGap(e[0],e[1]))
      setEndDate(e[1]);
      setStartDate(e[0]);
      getNext7Years(e[0])
      if(repeatValue?.value !="never"){
        setRepeatValue(null);
      setRepeat("");
      }
    }
  };

  const timeArray = [
    { label: "12:00 am", value: "00:00:00" },
    { label: "0:30 am", value: "00:30:00" },
    { label: "1:00 am", value: "01:00:00" },
    { label: "1:30 am", value: "01:30:00" },
    { label: "2:00 am", value: "02:00:00" },
    { label: "2:30 am", value: "02:30:00" },
    { label: "3:00 am", value: "03:00:00" },
    { label: "3:30 am", value: "03:30:00" },
    { label: "4:00 am", value: "04:00:00" },
    { label: "4:30 am", value: "04:30:00" },
    { label: "5:00 am", value: "05:00:00" },
    { label: "5:30 am", value: "05:30:00" },
    { label: "6:00 am", value: "06:00:00" },
    { label: "6:30 am", value: "06:30:00" },
    { label: "7:00 am", value: "07:00:00" },
    { label: "7:30 am", value: "07:30:00" },
    { label: "8:00 am", value: "08:00:00" },
    { label: "8:30 am", value: "08:30:00" },
    { label: "9:00 am", value: "09:00:00" },
    { label: "9:30 am", value: "09:30:00" },
    { label: "10:00 am", value: "10:00:00" },
    { label: "10:30 am", value: "10:30:00" },
    { label: "11:00 am", value: "11:00:00" },
    { label: "11:30 am", value: "11:30:00" },
    { label: "12:00 pm", value: "12:00:00" },
    { label: "12:30 pm", value: "12:30:00" },
    { label: "1:00 pm", value: "13:00:00" },
    { label: "1:30 pm", value: "13:30:00" },
    { label: "2:00 pm", value: "14:00:00" },
    { label: "2:30 pm", value: "14:30:00" },
    { label: "3:00 pm", value: "15:00:00" },
    { label: "3:30 pm", value: "15:30:00" },
    { label: "4:00 pm", value: "16:00:00" },
    { label: "4:30 pm", value: "16:30:00" },
    { label: "5:00 pm", value: "17:00:00" },
    { label: "5:30 pm", value: "17:30:00" },
    { label: "6:00 pm", value: "18:00:00" },
    { label: "6:30 pm", value: "18:30:00" },
    { label: "7:00 pm", value: "19:00:00" },
    { label: "7:30 pm", value: "19:30:00" },
    { label: "8:00 pm", value: "20:00:00" },
    { label: "8:30 pm", value: "20:30:00" },
    { label: "9:00 pm", value: "21:00:00" },
    { label: "9:30 pm", value: "21:30:00" },
    { label: "10:00 pm", value: "22:00:00" },
    { label: "10:30 pm", value: "22:30:00" },
    { label: "11:00 pm", value: "23:00:00" },
    { label: "11:30 pm", value: "23:30:00" },
  ];

  function removeSeconds(timeStr) {
    const response = timeArray.find((item) => item.value == timeStr);
    if (response == undefined) {
      return timeStr;
    } else {
      return response.label.toUpperCase();
    }
    //  return timeStr.substring(0, 5);
  }

  function isBetweenStartAndEnd(startDate, endDate) {
    var currentDate = new Date();
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    return startDate <= currentDate && currentDate <= endDate;
  }

  function isDateGreaterThanToday(inputDate) {
    var currentDate = new Date();

    var parsedDate = new Date(inputDate);

    return parsedDate > currentDate;
  }
  function adjustedEndDate(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    if (startDate > endDate) {
      endDate.setMonth(endDate.getMonth() + 1);
    }

    return endDate;
  }

  const deleteCurrentShifts = () => {
    if (!rotaPlannerDraft) {
      setMoveDraftModal(true);
      return;
    }
    if (ableStartDate == "" && ableEndDate == "") {
      if (type == "week") {
        const startDate = new Date();
        const newd = new Date();
        const dayOfWeek = startDate.getDay();
        startDate.setDate(startDate.getDate() - dayOfWeek);

        const endDate = new Date();
        endDate.setDate(endDate.getDate() + (6 - dayOfWeek));

        const filteredArr = eventList.filter((item) => {
          const itemDate = new Date(item.date);
          return itemDate >= startDate && itemDate <= endDate;
        });

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        const formattedDay =
          currentDate.getDate() < 10
            ? `0${currentDate.getDate()}`
            : currentDate.getDate();

        const formattedMonth =
          currentDate.getMonth() + 1 < 10
            ? `0${currentDate.getMonth() + 1}`
            : currentDate.getMonth() + 1;

        const formattedYear = currentDate.getFullYear();

        const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
        const finalEndDate = `${new Date(
          endDate
        ).getFullYear()}-${formattedMonth}-${
          new Date(endDate).getDate() < 10
            ? `0${new Date(endDate).getDate()}`
            : new Date(endDate).getDate()
        }`;

        setNewStartDate(formattedDate);
        setNewEndDate(
          adjustedEndDate(formattedDate, finalEndDate)
            .toISOString()
            .slice(0, 10)
        );

        // setNewStartDate()

        setDeleteShifts(filteredArr);
        setDeleteShiftModal(true);
      }
      // if(type=='month'){
      //   const monthStartDate=new Date(date.getFullYear(), date.getMonth(), 1);
      //   const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      //   const monthEndDate= new Date(nextMonth - 1);

      //   const
      // }
    } else {
      const filteredArr = eventList.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= ableStartDate && itemDate < ableEndDate;
      });
      if (filteredArr.length == 0) {
        toast.error("You dont have any Shift to delete");
        return;
      }
      setDeleteShifts(filteredArr);
      setDeleteShiftModal(true);
    }
  };
  const changer = (dateString) => {
    let currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() - 1);
    let year = currentDate.getFullYear();
    let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    let day = ("0" + currentDate.getDate()).slice(-2);
    
    let oneDayBefore = `${year}-${month}-${day}`;
    
    return oneDayBefore
  };
  const publishCurrentShifts = () => {
    if (ableStartDate == "" && ableEndDate == "") {
      if (type == "week") {
        const startDate = new Date();
        const dayOfWeek = startDate.getDay();
        startDate.setDate(startDate.getDate() - dayOfWeek);

        const endDate = new Date();
        endDate.setDate(endDate.getDate() + (6 - dayOfWeek));

        const filteredArr = eventList.filter((item) => {
          const itemDate = new Date(item.date);
          return (
            itemDate >= startDate && itemDate <= endDate && item.draft == "1"
          );
        });
        // if (filteredArr.length == 0) {
        //   toast.error("You dont have any Shift to Publish");
        //   return;
        // }
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        const formattedDay =
          currentDate.getDate() < 10
            ? `0${currentDate.getDate()}`
            : currentDate.getDate();

        const formattedMonth =
          currentDate.getMonth() + 1 < 10
            ? `0${currentDate.getMonth() + 1}`
            : currentDate.getMonth() + 1;

        const formattedYear = currentDate.getFullYear();

        const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
        const finalEndDate = `${new Date(
          endDate
        ).getFullYear()}-${formattedMonth}-${
          new Date(endDate).getDate() < 10
            ? `0${new Date(endDate).getDate()}`
            : new Date(endDate).getDate()
        }`;

        setNewStartDate(formattedDate);
        setNewEndDate(
          adjustedEndDate(formattedDate, finalEndDate)
            .toISOString()
            .slice(0, 10)
        );

        setPublishShiftArray(filteredArr);
        // setPublishShiftModal(true);
      }
      // if(type=='month'){
      //   const monthStartDate=new Date(date.getFullYear(), date.getMonth(), 1);
      //   const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      //   const monthEndDate= new Date(nextMonth - 1);

      //   const
      // }
    } else {
      const filteredArr = eventList.filter((item) => {
        const itemDate = new Date(item.date);
        return (
          itemDate >= ableStartDate && itemDate < ableEndDate && item.draft == 1
        );
      });
      // if (filteredArr.length == 0) {
      //   toast.error("You dont have any Shift to Publish");
      //   return;
      // }
      setPublishShiftArray(filteredArr);
      // setPublishShiftModal(true);
    }
  };

  const openPublishModel = (date) => {
    const currentDate = new Date(date);
    const currentMonth = currentDate.getMonth() + 1; // Add 1 because months are zero-based

    // Add a leading zero for single-digit months
    const formattedMonth =
      currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    const formattedDate =
      currentDate.getDate() < 10
        ? `0${currentDate.getDate()}`
        : currentDate.getDate();

    const final = `${currentDate.getFullYear()}-${formattedMonth}-${formattedDate}`;
    const filteredArr = eventList.filter((item) => {
      // return item.date == final && item.draft == 1;
      if (item.date == final) {
        if (item.draft == 1) {
          return item;
        } else if (!item.hideTime) {
          return item;
        } else {
        }
      }
    });
    const notificationSending = eventList.filter((item) => {
      return item.date == final;
    });
    // notificationSending.map((shift) => {
    //   SendNotifaction(
    //     "Publish",
    //     `Your Shifts is published of ${final}`,
    //     shift.user_id,
    //     "",
    //   );
    // })
    setCurrentPublishDate(final);
    // if (filteredArr.length == 0) {
    //   if (rota?.is_draft) {
    //     // return toast.error("You dont have any Shift to Publish");
    //   } else {
    //     setFilterdCurrentDaysShit(notificationSending);
    //     setNotificationModal(true);
    //     // toast.error('You dont have any Shift to Publish')
    //     return;
    //   }
    // }
    setConfirmPublishModal(true);
  };

  const openDeleteModel = (date) => {
    const currentDate = new Date(date);
    const currentMonth = currentDate.getMonth() + 1; // Add 1 because months are zero-based

    // Add a leading zero for single-digit months
    const formattedMonth =
      currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    const formattedDate =
      currentDate.getDate() < 10
        ? `0${currentDate.getDate()}`
        : currentDate.getDate();

    const final = `${currentDate.getFullYear()}-${formattedMonth}-${formattedDate}`;
    const filteredArr = eventList.filter((item) => {
      return item.date == final;
    });
    // if (filteredArr.length == 0) {
    //   toast.error("You dont have any shift to delete");
    //   return;
    // }
    setCurrentPublishDate(final);

    setConfirmDeleteModal(true);
  };

  const dateFormatChanger = (date) => {
    const currentDate = new Date(date);
    const currentMonth = currentDate.getMonth() + 1;

    const formattedMonth =
      currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    const formattedDate =
      currentDate.getDate() < 10
        ? `0${currentDate.getDate()}`
        : currentDate.getDate();

    const final = `${currentDate.getFullYear()}-${formattedMonth}-${formattedDate}`;
    return final;
  };

  const checingHideTimeFalse = (mydate) => {
    //  if(rotaPlannerDraft){
    const filteredArr = eventList.filter((item) => {
      if (item.date == mydate) {
        // if (item.draft == 1) {
        //   return item;
        // } else
         if (!item.hideTime) {
          return item;
        } else {
        }
      }
    });
    let resultant = filteredArr.length > 0 ? true : false;
    return resultant;
    //  }else{
    //   return true
    //  }
  };
  const checingHideTimeFalse2 = (mydate) => {
    const filteredArr = eventList.filter((item) => {
      if (item.date == mydate) {
        return item;
      }
    });
    let resultant = filteredArr.length > 0 ? true : false;
    return resultant;
  };
  const renderDay = (args) => {
    const date = args.date;
    const dayNr = date.getDay();
    // const task = milestones.find((obj) => { return +new Date(obj.date) === +date }) || {};

    const date1 = `${new Date(date).getDate()}-${new Date(date).getMonth() +
      1}-${new Date(date).getFullYear()}`;
    const currentdate = `${new Date().getDate()}-${new Date().getMonth() +
      1}-${new Date().getFullYear()}`;

    const isToday = date1 == currentdate ? true : false;

    return (
      <div className="header-template-container">
        <div style={{}} className="p-2 header-template-date">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: isToday ? "black" : "",
              color: isToday ? "white" : "",
              borderRadius: isToday ? 6 : 0,
            }}
            className="header-template-day-name"
          >
            {formatDate("DD DDD MMM YYYY", date)}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="row"
          >
            {checingHideTimeFalse2(dateFormatChanger(date)) ? (
              <div className="header-template-day m-1">
                {checingHideTimeFalse(dateFormatChanger(date)) ? (
                     <AntdSwitch
                     checked={false}  
                     onChange={() => {
                      setHasUnpublish(false);
                   openPublishModel(date);
                    }}
                    checkedChildren="Times Shown" unCheckedChildren="Show Times"/>
                  // <button
                  //   onClick={() => {
                  //     setHasUnpublish(false);
                  //     openPublishModel(date);
                  //   }}
                  //   style={{ backgroundColor: "black" }}
                  //   className="myRotaButtons"
                  //   disabled={Oflinemode}
                  //   success
                  // >
                  //   Publish Times
                  // </button>
                ) : (
                  <AntdSwitch
                  size="lg"
                  checked={true} 
                   onChange={() => {
                    setHasUnpublish(true);
                   openPublishModel(date);
                  
                  }}
                  checkedChildren="Times Shown" unCheckedChildren="Show Times"/>
         
                 
                    // <button
                    //   onClick={() => {
                    //     setHasUnpublish(true);
                    //     openPublishModel(date);
                    //   }}
                    //   style={{ backgroundColor: "black" }}
                    //   className="myRotaButtons"
                    //   disabled={Oflinemode}
                    //   success
                    // >
                    //   Unpublish Times
                    // </button>
        
                )}
              </div>
            ) : (
              ""
            )}
            <div className="header-template-day m-1">
              <a
                style={{ cursor: Oflinemode ? "inherit" : "pointer" }}
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => {
                  if (Oflinemode) {
                    console.log("nope");
                  } else {
                    openDeleteModel(date);
                  }
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    title="Delete"
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
              {/* <button
                onClick={() => openDeleteModel(date)}
                style={{ backgroundColor: "red", color: "white" }}
                className="myRotaButtons"
                disabled={Oflinemode}
              >
                Delete
              </button> */}
            </div>
          </div>
        </div>
        {/* <div className="header-template-task" style={{ background: task.color }}>{task.name}</div> */}
      </div>
    );
  };

  const publishDay = () => {
    setSinglePublishLoading(true);
    const url = `mgmt/daily-publish-rota/${rotaId}/shifts/${currentPublishDate}`;
    const unUrl = `mgmt/daily-unpublish-rota/${rotaId}/shifts/${currentPublishDate}`;
    const draftUrl = `mgmt/daily-publish-draft-rota/${rotaId}/shifts/${currentPublishDate}`;
    const drafUntUrl = `mgmt/daily-unpublish-draft-rota/${rotaId}/shifts/${currentPublishDate}`;
    fetch(
      `${baseUrl}/${
        rotaPlannerDraft ? (hasUnpublish ? drafUntUrl : draftUrl) : (hasUnpublish ? unUrl : url)
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setSinglePublishLoading(false);
        if (data.metadata.success) {
          const filteredArr = eventList.filter((item) => {
            return item.date == currentPublishDate;
          });

          const idArray = [];
          filteredArr.map((shi) => {
            if (!idArray.includes(shi.user_id)) {
              idArray.push(shi.user_id);
            } else {
            }
          });
          if (rota?.is_draft == false && !hasUnpublish) {
            idArray.map((id) => {
              SendNotifaction(
                "Publish",
                `Your Shifts is published of ${currentPublishDate}`,
                id,
                ""
              );
            });
          }
          if (!notificationModal) {
            toast.success(data.metadata.message);
            setConfirmPublishModal(false);
          } else {
            toast.success("Notification send successfully");
            setNotificationModal(false);
          }

          getRotaCalendar();
        } else if (!data.metadata.success) {
          setConfirmPublishModal(false);
          setNotificationModal(false);
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          setConfirmPublishModal(false);
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
        setConfirmPublishModal(false);
        setSinglePublishLoading(false);
      });
  };
  const deleteDay = (drafter) => {
    setSingleDeleteLoading(true);
    const url = `mgmt/daily-delete-rota-shifts/${currentPublishDate}`;
    const draftUrl = `mgmt/daily-delete-draft-rota/${rotaId}/shifts/${currentPublishDate}`;
    fetch(`${baseUrl}/${drafter ? draftUrl : url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSingleDeleteLoading(false);
        if (data.metadata.success) {
          const filteredArr = eventList.filter((item) => {
            return item.date == currentPublishDate && item.draft == "1";
          });

          const idArray = [];
          filteredArr.map((shi) => {
            if (!idArray.includes(shi.user_id)) {
              idArray.push(shi.user_id);
            } else {
            }
          });

          // idArray.map((id) => {
          //   SendNotifaction(
          //     "Publish",
          //     `Your Shifts is published of ${currentPublishDate}`,
          //     id,
          //     "",
          //   );
          // })
          toast.success(data.metadata.message);
          setConfirmDeleteModal(false);
          getRotaCalendar();
        } else if (!data.metadata.success) {
          setConfirmDeleteModal(false);
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }

          setSingleDeleteLoading(false);
        } else {
          setConfirmDeleteModal(false);
          toast.error(`Something went wrong`);
          setSingleDeleteLoading(false);
        }
      })
      .catch((err) => {
        setSingleDeleteLoading(false);
        setConfirmDeleteModal(false);
      });
  };

  const publishShifts = () => {
    const fdata = new FormData();
    for (let i = 0; i < publishShiftArray.length; i++) {
        fdata.append(`shift_ids[${i}]`, publishShiftArray[i].id);
    }
    fetch(`${baseUrl}/mgmt/custom-publish-rota/${rotaId}/shifts`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify({
            start_date: changer(newStartDate),
            end_date: newEndDate
        }),
    }).then(res => res.json())
        .then((data) => {
            if (data.metadata.success) {
              getRotaCalendar();
                if(title=='Publish'){
                    const idArray = [];
                    publishShiftArray.map((shi)=>{
                        if (!idArray.includes(shi.user_id)) {
                            idArray.push(shi.user_id);
                          } else {
                          }
                    })
                    
                    idArray.map((id)=>{
                        SendNotifaction(
                            "Publish",
                           `Your Shifts is published from  ${changer(newStartDate)} to ${newEndDate}`,
                              id,
                            "",
                          );
                    })
                
                }
      
            } else if (!data.metadata.success) {
              if (toString.call(data.metadata.message) == "[object Object]") {
                const errorMessageArray = Object.values(
                  data.metadata.message
                ).flatMap((messages) => messages);
                errorMessageArray.forEach((message) => toast.error(message));
              } else if (toString.call(data.metadata.message) == "[object Array]") {
                Object.values(data.metadata.message).map((msg) => {
                  toast.error(msg);
                });
              } else {
                toast.error(data.metadata.message);
              }
            
            } else {
              toast.error('Internal server error');
               
            }
        }).catch(err => {
          
        })
}

  const closeDrawer = () => {
    setIsFullday(false);
    setHideShow(false);
    setstartTime({
      label: "9:00 am",
      value: "09:00:00",
    });
    setendTime({
      label: "6:00 pm",
      value: "18:00:00",
    });
    setDraft(false);
    setIsGeoFence(false);
    setHideShow(false);
    setColor("");
    setShowEndDate("");
    setShowStartDate("");
    setEndDate(null);
    setStartDate(null);
    setDefaultColor(null);
    setselectedUsers([]);
    setEndRepeat(false);
    setSelectedOption("");
    setSelectedTemplate("");
    setIsDrawerOpen(false);
    setValueOftitme({});
    setRepeatValue({ label: "Never", value: "never" });
    setRepeat("never");
    setEndRepeatValue({ label: "Never", value: "never" });
    setEndRepeatDate("");
    setShowTextBox(false);
    setTitle("");
  };

  const onTemplateShiftClick = (shift) => {
    setEndRepeat(false);
    setEndRepeatDate("");
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
    });

    setShowTextBox(true);
    const getTitle = titlesArry.find((tt) => tt.value == shift.title);
    if (getTitle == undefined) {
      handleTiming({ label: "Other", value: "Other" });
      setTitle(shift.title);
    } else {
      handleTiming(getTitle);
    }
    setSelectedTemplate(shift.id);
    setIsFullday(shift.full_day);
    setIsGeoFence(shift.geo_fence);
    const gettingRepeat = repeatoptions.find((rr) => rr.value == shift.repeat);
    handleRepeat(gettingRepeat);
    // setRepeat(shift.repeat)

    const resultant = colorOptions.find((item) => item.value == shift.color);

    setDefaultColor(resultant);
    setEndDate(new Date(shift.end_date));
    setStartDate(new Date(shift.start_date));
    // handleSetDefaultDates(
    //   shift.start_date,
    //   shift.end_date
    // );

    setColor(shift.color);
    const findStartTime = timeArray.find(
      (item) => item.value == shift.start_time
    );
    const findEndTime = timeArray.find((sigletime) =>
      (sigletime.value == shift.end_time) == null ? "18:00:00" : shift.end_time
    );

    if (shift.end_time) {
      setendTime({
        label: removeSeconds(shift.end_time),
        value: shift.end_time,
      });
    } else {
      setendTime({ label: "12:00 am", value: "00:00:00" });
    }

  

    setstartTime(findStartTime);


    const users = shift.users.map((user) => user.user_id);
    setselectedUsers(users);

    if (shift.repeat_end_date != null) {
      setEndRepeatValue({ label: "Select Date", value: "date" });
      setEndRepeatDate(formatDateEndRepeat2(shift.repeat_end_date));
     setTimeout(() => {
      setEndRepeat(true)
     }, 500);;
    } else {
      setEndRepeat(false);
      setEndRepeatDate("");
      setEndRepeatValue({ label: "Never", value: "never" });
    }
  };

  const sendItKnow = () => {
    filterdCurrentDaysShit.map((shift) => {
      if (rota?.is_draft == false) {
        SendNotifaction(
          "Publish",
          `Your Shifts is published of ${currentPublishDate}`,
          shift.user_id,
          ""
        );
      }
    });
    toast.success("Notification send successfully");
    setNotificationModal(false);
  };

  const HandleOfilineShifts = () => {
    setCopyOflineLoading(true);
    const data = {
      copy_shifts: copyDataForapi,
      move_shifts: updateOflineShift,
    };
    const url = `mgmt/rotas/${rotaId}/move-and-copy-local-shifts`;
    const draftUrl = `mgmt/rotas/${rotaId}/move-and-copy-local-draft-shifts`;
    fetch(`${baseUrl}/${rotaPlannerDraft ? draftUrl : url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        getRotaCalendar();

        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setIsDrawerOpen(false);
          setShiftdate("");
          setEndRepeat(false);
          setIsFullday(false);
          setstartTime({ label: "9:00 am", value: "09:00:00" });
          setendTime({ label: "6:00 pm", value: "18:00:00" });
          setTitle("");
          setConfirmModal(false);
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
          setOflineMode(false);
          setCopyOflineLoading(false);
        } else {
          toast.error(`Something went wrong`);
          setCopyOflineLoading(false);
        }
      })
      .catch((err) => {
        setCopyOflineLoading(false);
      });
  };

  const checkValidation = async (shift, forWhat) => {
    const data = {
      shift_id: shift.parentId == "" ? shift.id : shift.parentId,
      date: formetTime(shift.end),
      user_id: shift.resource,
      update: forWhat,
    };

    // const url=`mgmt/move-and-copy-shifts-validation-check`
    const draftUrl = `mgmt/move-and-copy-draft-shifts-validation-check`;
    try {
      const response = await fetch(`${baseUrl}/${draftUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (responseData.metadata) {
        if (responseData.metadata.success) {
          return true;
        } else {
          if (
            toString.call(responseData.metadata.message) === "[object Object]"
          ) {
            const errorMessageArray = Object.values(
              responseData.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (
            toString.call(responseData.metadata.message) === "[object Array]"
          ) {
            Object.values(responseData.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(responseData.metadata.message);
          }
          return false;
        }
      } else {
        toast.error(`Something went wrong`);
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const myReloader = () => {
    setSettingmodal(false);
    getAllTitle();
    getRotaCalendar();
  };
  const findingLiveShiftInDraft=(shifts)=>{
    console.log(shifts,"sajhdafs banjsaf=>>>>>>>>>>>>>>")
    console.log(liveRotaSelectedShift,"sajhdafs banjsaf=>>>>>>>>>>>>>>")
    const result=shifts.find((item)=>{
      if(item.date==liveRotaSelectedShift.date && item.end_time==liveRotaSelectedShift.end_time &&  item.start_time==liveRotaSelectedShift.start_time &&  item.user_id==liveRotaSelectedShift.user_id ){
        return item
      }
     })
     return result
  }

  const moveAllShiftsToDraft = () => {
    setMoveDraftModalLoading(true);
    fetch(`${baseUrl}/mgmt/rota-shifts/${rotaId}/move-to-draft`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            rota["is_draft"] = true;
            getRotaCalendar();
            toast.success(data.metadata.message);
          } else {
            setMoveDraftModalLoading(false);
            setMoveDraftModal(false);
            if (toString.call(data.metadata.message) === "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => toast.error(message));
            } else if (
              toString.call(data.metadata.message) === "[object Array]"
            ) {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }
          }
        } else {
          toast.error("Error occurred while processing your request");
        }
      })

      .catch((err) => {
        setMoveDraftModalLoading(false);
        setMoveDraftModal(false);
      });
  };

  const moveAllShiftsToDraftOnly = () => {
    return new Promise((resolve, reject) => {
      setMovingDraftLoading(true);
      fetch(`${baseUrl}/mgmt/rota-shifts/${rotaId}/move-to-draft-only`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              rota["is_draft"] = true;
           const response=   await getRotaCalendar();
           console.log(response.shifts,"ya hy shifts")
              toast.success(data.metadata.message);
              setMovingDraftLoading(false);
              resolve({ success: true, shifts: response.shifts });
            } else {
              setMovingDraftLoading(false);
              if (toString.call(data.metadata.message) === "[object Object]") {
                const errorMessageArray = Object.values(
                  data.metadata.message
                ).flatMap((messages) => messages);
                errorMessageArray.forEach((message) => toast.error(message));
              } else if (
                toString.call(data.metadata.message) === "[object Array]"
              ) {
                Object.values(data.metadata.message).map((msg) => {
                  toast.error(msg);
                });
              } else {
                toast.error(data.metadata.message);
              }
              resolve(false);
            }
          } else {
            toast.error("Error occurred while processing your request");
            resolve(false);
          }
        })
        .catch((err) => {
          setMovingDraftLoading(false);
          reject(err);
        });
    });
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'z') {
      try {
        const response = await undoShifts();
        if (response.metadata.success) {
          getRotaCalendar();
        }
      } catch (error) {
        console.error('Error during undoShifts:', error);
        toast.error("An error occurred while processing your request");
      }
    }
  };

  const undoShifts = async () => {
    console.log('Ctrl + Z pressed!');
    setUndoLoading(true)
    try {
      const res = await fetch(`${baseUrl}/mgmt/rotas/${rotaId}/undo`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
      });
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await res.json();
      if (data.metadata) {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
        } else {
          const errorMessage =
            toString.call(data.metadata.message) === "[object Object]"
              ? Object.values(data.metadata.message).flatMap((messages) => messages)
              : Array.isArray(data.metadata.message)
              ? data.metadata.message
              : [data.metadata.message];
          errorMessage.forEach((message) => toast.error(message));
        }
      } else {
        toast.error("Error occurred while processing your request");
      }
    setUndoLoading(false)
      return data; 
    } catch (err) {
    setUndoLoading(false)
      console.error('Error during fetch:', err);
      throw err; 
    }
  };
  const moveAllShiftsToDraftOnlyForCopy = () => {
    return new Promise((resolve, reject) => {
      setMovingDraftLoading(true);
      fetch(`${baseUrl}/mgmt/rota-shifts/${rotaId}/move-to-draft-only`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              rota["is_draft"] = true;
              setRotaPlannerDraft(true);
              await getRotaCalendar().then((success) => {
                if (success) {
                  toast.success(data.metadata.message);
                  setMovingDraftLoading(false);
                  resolve(true);
                }
              });
            } else {
              setMovingDraftLoading(false);
              if (toString.call(data.metadata.message) === "[object Object]") {
                const errorMessageArray = Object.values(
                  data.metadata.message
                ).flatMap((messages) => messages);
                errorMessageArray.forEach((message) => toast.error(message));
              } else if (
                toString.call(data.metadata.message) === "[object Array]"
              ) {
                Object.values(data.metadata.message).map((msg) => {
                  toast.error(msg);
                });
              } else {
                toast.error(data.metadata.message);
              }
              resolve(false);
            }
          } else {
            toast.error("Error occurred while processing your request");
            resolve(false);
          }
        })
        .catch((err) => {
          setMovingDraftLoading(false);
          reject(err);
        });
    });
  };

  const checkerIsDraf = () => {
    const result = eventList.filter((item) => {
      const itemDate = item.date;
      // if (itemDate >= changer(newStartDate) && itemDate <= newEndDate) {
      if (itemDate >= formetTime(new Date())) {
        if (item.draft == 1) {
          return item;
        }
      }
    });

    return result.length > 0 ? true : false;
  };
  const checkerOfline = () => {
    const result = eventList.filter((item) => {
      const itemDate = item.date;
      // if (itemDate >= newStartDate && itemDate <= newEndDate) {
      if (itemDate >= formetTime(new Date())) {
        if (item.draft == "1") {
          return item;
        }
      }
    });

    return result.length > 0 ? true : false;
  };

  function convertArray(arr) {
    const groupedByDateAndUserId = {};
    arr.forEach((item) => {
      const key = item.action + "-" + item.user_id;
      if (!groupedByDateAndUserId[key]) {
        groupedByDateAndUserId[key] = {
          action: item.action,
          title: item.title,
          isfullDay: item.full_day == 1 ? true : false,
          date: item.date,
          start_time: item.start_time,
          end_time: item.end_time,
          user_id: item.user_id,
          isMulti: false,
        };
      } else {
        groupedByDateAndUserId[key].isMulti = true;
      }
    });

    const arr2 = Object.values(groupedByDateAndUserId);
    return arr2;
  }

  const sendNotificationsToUsers = (converter) => {
    converter.map((item) => {
      if (item.action == "create") {
        const extraData = {
          type: "create_shift",
          userId: item.user_id,
        };
        if (item.isMulti) {
          SendNotifaction(
            "Shift Created",
            "Your multiple shifts have been created.",
            item.user_id,
            "",
            extraData
          );
        } else {
          SendNotifaction(
            item.title,
            item.isfullDay
              ? `Your shift is created at ${item.date} and Shift start time is ${item.start_time}`
              : `Your shift is created at ${item.date} and Shift start time is ${item.start_time}  and ended at ${item.end_time} `,
            item.user_id,
            "",
            extraData
          );
        }
      } else if (item.action == "update") {
        if (item.isMulti) {
          SendNotifaction(
            "Shift Updated",
            "Your multiple shifts have been updated.",
            item.user_id,
            ""
          );
        } else {
          SendNotifaction(
            item.title,
            item.isfullDay
              ? `Your shift is updated at ${item.date} and Shift start time is ${item.start_time}`
              : `Your shift is updated at ${item.date} and Shift start time is ${item.start_time}  and ended at ${item.end_time} `,
            item.user_id,
            ""
          );
        }
      } else {
        if (item.isMulti) {
          SendNotifaction(
            "Publish",
            `Your multiple shifts have been published.`,
            item.user_id,
            ""
          );
        } else {
          SendNotifaction(
            "Publish",
            `Your Shifts is published of ${item.date}`,
            item.user_id,
            ""
          );
        }
      }
    });
  };

  const moveToLive = () => {
    publishCurrentShifts();
    setAllowCopyMove(false);
    setMoveDraftToLiveModalLoading(true);
    fetch(`${baseUrl}/mgmt/draft-rota-shifts/${rotaId}/move-to-live`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then(async (data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            rota["is_draft"] = false;
            getRotaCalendar();
            toast.success(data.metadata.message);
            if(isPublishDraftShifts){
              publishShifts()
            }
            setIsPublishDraftShifts(false)
            setAllowCopyMove(true);
            const converter = await convertArray(data.payload);
            console.log(converter, "notification data");
            sendNotificationsToUsers(converter);
          } else {
            setMoveDraftToLiveModalLoading(false);
            setMoveDraftToLiveModal(false);
            if (toString.call(data.metadata.message) === "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => {
                if (
                  message ==
                  "Sorry you can not publish shifts, because there are some overlapping shifts."
                ) {
                  getRotaCalendar();
                  setOverLapModal(true);
                }
                toast.error(message);
              });
            } else if (
              toString.call(data.metadata.message) === "[object Array]"
            ) {
              Object.values(data.metadata.message).map((msg) => {
                if (
                  msg ==
                  "Sorry you can not publish shifts, because there are some overlapping shifts."
                ) {
                  getRotaCalendar();
                  setOverLapModal(true);
                }
                toast.error(msg);
              });
            } else {
              if (
                data.metadata.message ==
                "Sorry you can not publish shifts, because there are some overlapping shifts."
              ) {
                getRotaCalendar();
                setOverLapModal(true);
              }
              toast.error(data.metadata.message);
            }
          }
        } else {
          toast.error("Error occurred while processing your request");
        }
      })

      .catch((err) => {
        setMoveDraftToLiveModalLoading(false);
        setMoveDraftToLiveModal(false);
      });
  };

  const revertRota = () => {
    setRevertModalLoading(true);
    fetch(`${baseUrl}/mgmt/rotas/${rotaId}/revert-rota-shifts`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            rota["is_draft"] = false;
            getRotaCalendar();
            toast.success(data.metadata.message);
          } else {
            setRevertModalLoading(false);
            setRevertModal(false);
            if (toString.call(data.metadata.message) === "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => toast.error(message));
            } else if (
              toString.call(data.metadata.message) === "[object Array]"
            ) {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }
          }
        } else {
          toast.error("Error occurred while processing your request");
        }
      })

      .catch((err) => {
        setRevertModalLoading(false);
        setRevertModal(false);
      });
  };
  function isDateRangeWithin(startDate1, endDate1, startDate2, endDate2) {
    const start1 = new Date(startDate1);
    const end1 = new Date(endDate1);
    const start2 = new Date(startDate2);
    const end2 = new Date(endDate2);
    console.log(start1)
    console.log(start2)
    console.log(end1)
    console.log(end2)
    return (start1 >= start2 && end1 <= end2);
}
  function areDatesInSameMonth(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    // Check if the year and month are the same
    return start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth();
  }
  const formatThen = (dateStr) => {
    const parts = dateStr?.split("/");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };
  const handleFetchData = (startDate, endDate) => {
    if (type === "week") {
      const [startDate1, endDate1] = getStartEndDateCurrentMonth(
        new Date(startDate)
      );
      const [startDate2, endDate2] = getStartEndDateCurrentMonth(
        new Date(endDate)
      );
     

      const configerStartDate = startDate1.toISOString().split("T")[0];
      const configerEndDate = endDate2.toISOString().split("T")[0];
      // console.log(configerStartDate,"next start date")
      // console.log(validateStartDate,"next start date=>>>")
       
      console.log(configerEndDate,"new end date==>")
      console.log(validateEndDate,"already set end date=>>")
      // if(areDatesInSameMonth(startDate,endDate)){
        // if (configerEndDate !== validateEndDate || configerStartDate !== validateStartDate) {
          if(!isDateRangeWithin(startDate,endDate,validateStartDate,validateEndDate)){
          setValidateStartDate(configerStartDate);
          setValidateEndDate(configerEndDate);
          getRotaCalendar(configerStartDate, configerEndDate);
        }
      // }else{
      //   setValidateStartDate(configerStartDate);
      //   setValidateEndDate(configerEndDate);
      //   getRotaCalendar(configerStartDate, configerEndDate);
      // }
      
    } else {
      const start = new Date(startDate);
      const formattedStartDate = start.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const end = new Date(endDate);
      end.setDate(end.getDate() - 1);
      const formattedEndDate = end.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      // if ( formatThen(formattedEndDate) !== validateEndDate) {
      setValidateStartDate(formatThen(formattedStartDate));
      setValidateEndDate(formatThen(formattedEndDate));
      getRotaCalendar(
        formatThen(formattedStartDate),
        formatThen(formattedEndDate)
      );
      //  }
    }
  };
  return (
    <div className="">
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        {rota?.is_draft ? (
          <div className="mt-7">
            <div>
              <h3>Draft</h3>
            </div>
          </div>
        ) : (
          <div className="mt-7">
            <div>
              <h3>Live</h3>
            </div>
          </div>
        )}
      </div> */}
      {movingDraftLoading && (
        <div class="loader-overlay-calander">
          <div class="loader-calander"></div>
          <div class="content-behind-calander">
            <h3>Creating Save Point</h3>
          </div>
        </div>
      )}
      {validatingLoading && (
        <div class="loader-overlay-calander">
          <div class="loader-calander"></div>
          <div class="content-behind-calander">
            <h3>Loading...</h3>
          </div>
        </div>
      )}
      <Link to={"/rota-groups"}>
        <BsFillArrowLeftCircleFill
          style={{
            fontSize: "2.5rem",
            color: "black",
            marginRight: "20px",
            textDecoration: "none",
          }}
        />
      </Link>
      {usersList.length == 0 && !tagLoading ? (
        <Alert
          message="Please Assign a User to This Rota Group"
          banner
          closable
        />
      ) : (
        ""
      )}
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div>
            <label>
              <strong>Search</strong>
            </label>
            <input
              style={{ width: "200px" }}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Users"
              className="form-control"
            />
          </div>

          <div>
            <label>
              <strong>View</strong>
            </label>
            <select
              style={{ width: "200px" }}
              className="form-control mx-1"
              defaultValue={"week"}
              onChange={(e) => setType(e.target.value)}
            >
              {/* <option value={"day"}>Day</option> */}
              <option value={"week"}>Week</option>
              <option value={"month"}>Month</option>
              <option value={"year"}>Year</option>
            </select>
          </div>
          <div className="d-flex" style={{maxHeight:"70px",flexWrap:"wrap"}}>
            {rotaPlannerDraft && (
              <button
                // disabled={Oflinemode}
                style={{ width: "150px" }}
                onClick={() => setRevertModal(true)}
                className="btn btn-primary mx-2 mt-7"
              >
                Cancel Changes
              </button>
            )}

            {rotaPlannerDraft && (
              <button
                style={{ width: "150px" }}
                onClick={() => {
                  // if (overLapShifts.length > 0) {
                  //   setOverLapModal(true);
                  // } else {
                    setMoveDraftToLiveModal(true);
                  // }
                }}
                className="btn btn-primary mx-2 mt-7"
              >
                Save
              </button>
            )}
                  {rotaPlannerDraft  && isChange &&  (
                    undoLoading ? 
                    <>
                   <span
              className="mt-7"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "150px",
              }}
            >
              <Spinner style={{marginTop:"5px"}} animation="border" />
            </span>
                    </>:
                      <button
                      style={{ width: "150px" }}
                      onClick={async () => {
                        try {
                          const response = await undoShifts();
                          if(response.metadata.success){
                            getRotaCalendar()
                          }
                        } catch (error) {
                          console.error('Error during undoShifts:', error);
                          toast.error("An error occurred while processing your request");
                        }
                      }}
                      className="btn btn-primary mx-2 mt-7"
                    >
                     Undo
                    </button>
            )}
          </div>
        </div>

        {/* {PremissionChecker(user, "rota_shift.update") &&
        PremissionChecker(user, "rota_shift.create") ? (
          <div className="col-md-2 mt-9">
            <label></label>
            <Switcher
              onChange={(e) => setCopyUpdate(e)}
              checkedChildren="Copy"
              unCheckedChildren="Move"
            />
          </div>
        ) : (
          ""
        )} */}
        <div
          className="text-right"
          style={{ display: "flex", flexWrap: "wrap", justifyContent: "end" }}
        >
          {copyOflineLoading ? (
            <div
              className="mt-7"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "150px",
              }}
            >
              <Spinner animation="border" />
            </div>
          ) : updateOflineShift.length > 0 || copyDataForapi.length > 0 ? (
            <button
              // disabled={updateOflineShift.length>0 || copyDataForapi.length>0 ?false :true}
              style={{ width: "150px" }}
              onClick={HandleOfilineShifts}
              className="btn btn-primary  mt-7"
            >
              Save
            </button>
          ) : (
            ""
          )}

          {checkerOfline() && PremissionChecker(user, "rota_shift.delete") ? (
            <button
              // disabled={draftButtonDisable}
              style={{ width: "150px" }}
              onClick={() => {
                setdeleteDraftModal(true);
              }}
              className="btn btn-danger ml-2 mt-7"
            >
              Delete All Draft
            </button>
          ) : ''}

          {PremissionChecker(user, "rota_shift.delete") ? (
            <button
              style={{ width: "150px" }}
              onClick={deleteCurrentShifts}
              className="btn btn-danger mx-2  mt-7"
              disabled={Oflinemode}
            >
              Delete Shifts
            </button>
          ) : null}

          {/* {PremissionChecker(user, "rota_shift.create") ? (
            <button
              style={{ width: "150px" }}
              onClick={() => {
                setDraft(true);
                setHideShow(true);
                setIsDrawerOpen(true);
              }}
              disabled={Oflinemode}

              className="btn btn-primary mx-2  mt-7"
            >
              Add shift
            </button>
          ) : (
            ""
          )} */}
          {!rotaPlannerDraft && checkerIsDraf() && (
            <button
              style={{ width: "170px" }}
              onClick={() => {
                if (overLapShifts.length > 0) {
                  setOverLapModal(true);
                } else {
                  publishCurrentShifts();
                  setPublishShiftModal(true);
                }
              }}
              className="btn btn-primary  mt-7 mx-2"
              disabled={Oflinemode}
            >
              Publish Drafts
            </button>
          )}
          {/* <button
            style={{ width: "150px", marginLeft: 5 }}
            onClick={() => setSettingmodal(true)}
            className="btn btn-primary  mt-7"
          >
            Setting
          </button> */}

        { PremissionChecker(user, "rota_setting.list") &&  <a
            style={{ marginTop: "25px" }}
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => setSettingmodal(true)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title="Settings"
                src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
              />
            </span>
          </a>}
        { PremissionChecker(user, "rota.assign_user") &&  <a
            style={{ marginTop: "25px" }}
            className="btn btn-icon btn-light btn-hover-warning text-warning btn-sm mx-3"
            onClick={() => setAssignModal(true)} 
          >
            <span className="svg-icon svg-icon-md svg-icon-warning">
            <UserSwitchOutlined/>
            </span>
          </a>}


        </div>
      </div>

      {isLoading ? (
        <div>
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        <div style={{ width: "100%", height: "83vh" }}>
          <Eventcalendar
            // height={'100%'}
            width={"100%"}
            theme={"material"}
            renderDay={renderDay}
            dragToMove={true}
            onPageChange={(e, w) => {
              setAbleStartDate(e.firstDay);
              setAbleEndDate(e.lastDay);

              handleFetchData(e.firstDay, e.lastDay);

              const givenDate = new Date(e.lastDay);
              givenDate.setDate(givenDate.getDate() - 1);

              const formattedDay2 =
                givenDate.getDate() < 10
                  ? `0${givenDate.getDate()}`
                  : givenDate.getDate();

              const formattedMonth3 =
                givenDate.getMonth() + 1 < 10
                  ? `0${givenDate.getMonth() + 1}`
                  : givenDate.getMonth() + 1;

              const formattedYear2 = givenDate.getFullYear();

              const formattedEnd = `${formattedYear2}-${formattedMonth3}-${formattedDay2}`;

              const givenDate2 = new Date(e.firstDay);
              givenDate2.setDate(givenDate2.getDate());

              const formattedDayStart =
                givenDate2.getDate() < 10
                  ? `0${givenDate2.getDate()}`
                  : givenDate2.getDate();

              const formattedMonthStart =
                givenDate2.getMonth() + 1 < 10
                  ? `0${givenDate2.getMonth() + 1}`
                  : givenDate2.getMonth() + 1;

              const formattedYearStart = givenDate2.getFullYear();

              const formattedStart = `${formattedYearStart}-${formattedMonthStart}-${formattedDayStart}`;

              const chekerTodayDateincl = isBetweenStartAndEnd(
                formattedStart,
                formattedEnd
              );

              const givenDate3 = new Date();
              givenDate3.setDate(givenDate3.getDate() + 1);

              const curenters =
                givenDate3.getDate() < 10
                  ? `0${givenDate3.getDate()}`
                  : givenDate3.getDate();

              const currentmonther =
                givenDate3.getMonth() + 1 < 10
                  ? `0${givenDate3.getMonth() + 1}`
                  : givenDate3.getMonth() + 1;

              const cunteyear = givenDate3.getFullYear();

              const formattedTodayDate = `${cunteyear}-${currentmonther}-${curenters}`;

              setNewStartDate(
                chekerTodayDateincl ? formattedTodayDate : formattedStart
              );
              setNewEndDate(formattedEnd);
            }}
            calendarHeight={200}
            themeVariant={theme}
            view={{
              timeline: {
                type: type,
                eventList: true,
              },
            }}
            onCellClick={(e) => onClickCell(e, dataSearch)}
            data={eventList}
            onEventCreateFailed={onEventCreateFailed}
            onEventDragStart={(e) => {
              console.log(e, "sth discription likh dena koi");
              setCatchStartEvent(e);
            }}
            onEventDragEnd={(e) => {
              setCatchEndEvent(e.resource);
              if (rota?.is_draft == false) {
                moveAllShiftsToDraftOnlyForCopy();
                //   moveAllShiftsToDraftOnlyForCopy()
                //     .then((success) => {
                //       if (success) {
                // if (isCopyPressed) {
                //   handleDrage(e.event);
                //   setIsCopyPressed(false);
                //   setIsUpdatePressed(false);
                // } else {
                //   if (PremissionChecker(user, "rota_shift.update")) {
                //     if (e.event.isRe) {
                //       setUpdateData(e.event);
                //       setReCureEditModal(true);
                //       // handleMove(e.event);
                //       setIsCopyPressed(false);
                //       setIsUpdatePressed(false);
                //     } else {
                //       handleMoveForSingle(e.event,true);
                //       setIsCopyPressed(false);
                //       setIsUpdatePressed(false);
                //     }
                //   } else {
                //     toast.error("You do not have permission for this action");
                //     getRotaCalendar();
                //   }
                // }
                //       } else {
                //       }
                //     })
                //     .catch((error) => {
                //     });
              } else {
                if (isCopyPressed) {
                  handleDrage(e.event);
                  setIsCopyPressed(false);
                  setIsUpdatePressed(false);
                } else {
                  if (PremissionChecker(user, "rota_shift.update")) {
                    if (e.event.isRe) {
                      setUpdateData(e.event);
                      setReCureEditModal(true);
                      // handleMove(e.event);
                      setIsCopyPressed(false);
                      setIsUpdatePressed(false);
                    } else {
                      handleMoveForSingle(e.event, false);
                      setIsCopyPressed(false);
                      setIsUpdatePressed(false);
                    }
                  } else {
                    toast.error("You do not have permission for this action");
                    getRotaCalendar();
                  }
                }
              }
            }}
            eventDelete={true}
            tep={2}
            renderScheduleEvent={(eventdata) => (
              <div
              style={{color:"black"}}
                className={
                  // eventdata?.original?.user_on_leave
                  eventdata?.original?.holiday
                    ? `holiy givingopecity ${eventdata?.original
                        ?.is_overlapped && "makingHighlight"}`
                    : eventdata?.original?.is_overlapped
                    ? "makingHighlight holiy"
                    : "holiy"
                }
              >
                {/* <button
                  onClick={() => {
                    // setEditDrawerModal(false)
                    alert('rthis')
                  }}
                  type="button" className="btn btn-info">Delete</button> */}

               {PremissionChecker(user, "rota_shift.delete") && <MdDeleteOutline
                  // disabled={Oflinemode}
                  color={
                    eventdata.color == "#FF0000" &&
                    eventdata.original.draft != "1"
                      ? "white"
                      : "red"
                  }
                  onClick={() => {
                    if (!Oflinemode) {
                      console.log(eventdata, "ya hy user");
                      setLiveRotaSelectedShift(eventdata?.original)
                      if (eventdata?.original?.isRe) {
                        // setMyselectedShift(eventdata?.original?.id);
                        setReCureDeleteModal(true);
                      } else {
                        // setMyselectedShift(eventdata?.original?.id);
                        setDeleteConfirmation(true);
                      }
                    }
                  }}
                  style={{
                    margin: 0,
                    position: "absolute",
                    right: 10,
                    fontSize: "2rem",
                    top: "10px",
                  }}
                />}
                <div
                  onClick={() => {
                    if (!Oflinemode) {
                      // if (isDateBeforeToday(new Date(eventdata.original.date))) {
                      //   toast.error("You Cannot Edit Past Days Shift");
                      //   return;
                      // }
                      onEventClick(eventdata.original);
                    }
                  }}
                  style={
                    eventdata?.original.draft == "1"
                      ? { backgroundColor: "#fff", border: "1px solid red" }
                      : {
                          backgroundColor:
                            eventdata.color[0] != "#"
                              ? "black"
                              : eventdata.color
                        }
                  }
                  className="event-card"
                >
                  <p
                    style={
                      eventdata?.original.draft == "1"
                        ? { color: "black", margin: 0 }
                        : { color: "black", margin: 0 }
                    }
                  >
                    {" "}
                    {eventdata.original.rota_setting != null
                      ? eventdata.original.rota_setting?.full_day == 1
                        ? `${removeSeconds(
                            eventdata.original?.rota_setting?.start_time
                          )}-No End`
                        : `${removeSeconds(
                            eventdata.original?.rota_setting?.start_time
                          )}-${removeSeconds(
                            eventdata.original?.rota_setting?.end_time
                          )}`
                      : eventdata.original?.allDay
                      ? `${removeSeconds(
                          eventdata?.original?.start_time
                        )}-No End`
                      : `${removeSeconds(eventdata?.original.start_time)}-${removeSeconds(
                          eventdata?.original?.end_time
                        )}`}
                    {/* {eventdata?.original?.user_on_leave ? "(On Holiday)" : ""} */}
                  </p>
<div className="d-flex justify-content-between">

<strong
                    style={
                      eventdata?.original.draft == "1"
                        ? { color: "black" }
                        : { color: "black" }
                    }
                  >
                    {/* {eventdata.title.slice(0, 15)} */}
                    {eventdata.original.rota_setting
                      ? eventdata.original.rota_setting.title
                      : eventdata.title.slice(0, 15)}
                  </strong>
                  <strong style={{color:"black",fontSize:"15px"}}>
                    {eventdata.original.draft == "1" ? 'Draft' : ''}
                  </strong>
</div>
             
                  <div
                    className={
                      eventdata?.original.draft == "1"
                        ? "borderClasBlack"
                        : "borderClassWhite"
                    }
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    {PremissionChecker(user, "rota_shift.create") ? (
                      <AiOutlineCopy
                        className={
                          eventdata?.original.draft == "1"
                            ? "colorITBlack"
                            : "colorITBlack"
                        }
                        onMouseDown={() => {
                          setIsUpdatePressed(false);
                          setIsCopyPressed(true);
                        }}
                        style={{ fontSize: "20px", marginLeft: "3px" }}
                      />
                    ) : (
                      ""
                    )}
                    {!eventdata.original.hideTime ? 

                    <FaRegEyeSlash
                    className={
                      eventdata?.original.draft == "1"
                        ? "colorITBlack"
                        : "colorITBlack"
                    }
                    style={{ fontSize: "20px" }}
                    />
                     : (
                      <>
                        <FiClock
                          className={
                            eventdata?.original.draft == "1"
                              ? "colorITBlack"
                              : "colorITBlack"
                          }
                          style={{ fontSize: "20px" }}
                        />
                      </>
                    )}

                    {PremissionChecker(user, "rota_shift.update") ? (
                      <IoMove
                        className={
                          eventdata?.original.draft == "1"
                            ? "colorITBlack"
                            : "colorITBlack"
                        }
                        onMouseDown={() => {
                          setIsCopyPressed(false);
                          setIsUpdatePressed(true);
                        }}
                        style={{ fontSize: "20px", marginLeft: "3px" }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <strong>{eventdata.draft}</strong> */}
                </div>
              </div>
            )}
            renderResource={(resource, index) => (
              <div
                className="mt-9"
                onClick={() => {
                  setProfileUser(resource);
                  setProfileDrawer(true);
                }}
              >
                <div className="d-flex">
                  <Avatar
                    style={{ backgroundColor: `#${resource?.color}` }}
                    alt={resource?.name?.toUpperCase()}
                    src={resource?.image !=null ? resource?.image?.url :   "#"}
                  />
                  <span className="mx-1 mt-3">{resource.name}</span>
                </div>
              </div>
              // <Popover
              //   placement={"right"}
              //   content={
              //     <>
              //       <b>Name</b>
              //       <div>
              //         <p style={{ margin: 0 }}>{resource.name}</p>
              //       </div>
              //       <div>
              //         <b>Work Pattern</b>
              //       </div>
              //       <div>
              //         <p style={{ margin: 0 }}>
              //           {resource.work.monday == 1 ? 'Monday' : ''}{resource.work.tuesday == 1 ? ',Tuesday' : ''}{resource.work.wednesday == 1 ? ',Wednesday' : ''}{resource.work.thursday == 1 ? ',Thursday' : ''}{resource.work.friday == 1 ? ',Friday' : ''}{resource.work.saturday == 1 ? ',Saturday' : ''}{resource.work.sunday == 1 ? ',Sunday' : ''}
              //         </p>
              //       </div>
              //       <b>Working Hours:{resource.work.total_weekly_working_hours}</b>
              //     </>
              //   }
              //   title="Info"
              //   trigger="click"
              // >
              //   <div className="mt-9">
              //     <div className="d-flex">
              //       <Avatar
              //         style={{ backgroundColor: `#${resource?.color}` }}
              //         alt={resource?.name?.toUpperCase()}
              //         src="#"
              //       />
              //       <span className="mx-1 mt-3">{resource.name}</span>
              //     </div>
              //   </div>
              // </Popover>
            )}
            // onEventClick={onEventClick}
            // onCellClick={(args)=>console.log(args.)}
            resources={dataSearch}
            invalidateEvent="strict"
            invalid={invalid}
            colors={holidaysColor}
          />
        </div>
      )}

      <Drawer
        className="addDrawer"
        // title={currentDate}
        width={450}
        onClose={closeDrawer}
        open={isDrawerOpen}
        bodyStyle={{
          paddingBottom: 50,
        }}
        extra={
          <Space>
            <h3 className="text-center">New Shift:{currentDate}</h3>
          </Space>
        }
      >
        {addLoading ? (
          <div>
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <>
            <div className="" ref={scrollRef}></div>
            <div className="d-flex align-items-center col-md-12">
              {/* <FormControlLabel
                value="start"
                control={
                  <Switch
                    checked={hideShow}
                    onChange={(check) => {
                      setHideShow(check.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Show Time"
                labelPlacement="start"
              /> */}
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="row d-flex justify-content-around m-3">
                <div className="d-flex align-items-center col-md-8">
                  <RsuitePicker
                    disabledDate={beforeToday()}
                    value={[startDate, endDate]}
                    onChange={handlePickerChange}
                    defaultValue={[startDate, endDate]}
                    format="dd/MM/yyyy"
                  />
                </div>
                <div className="d-flex align-items-center col-md-4">
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        checked={isfullDay}
                        onChange={(check) => {
                          setIsFullday(check.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="No End"
                    labelPlacement="start"
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-5">
                  {/* <KeyboardTimePicker
                    // disabled={isfullDay}
                    margin="normal"
                    id="start-time-picker"
                    label="Start Time"
                    value={startTime}
                    onChange={(date) => onChangeStartTime(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  /> */}
                  <div>
                    <lable>Start Time</lable>
                  </div>
                  <div>
                    <Select
                      onMenuOpen={onMenuOpen}
                      className="MyDropdown"
                      classNamePrefix="MyDropdown"
                      // defaultValue="lucy"
                      style={{ width: 120 }}
                      onChange={(e) => onChangeStartTime(e)}
                      options={timeArray}
                      value={startTime}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  {/* <KeyboardTimePicker
                    margin="normal"
                    disabled={isfullDay}
                    id="end-time-picker"
                    label="End Time"
                    value={endTime}
                    onChange={(date) => onChangeEndTime(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  /> */}
                  <div>
                    <lable>End Time</lable>
                  </div>
                  <div>
                    <Select
                      onMenuOpen={onMenuOpen}
                      className="MyDropdown"
                      classNamePrefix="MyDropdown"
                      isDisabled={isfullDay}
                      style={{ width: 120 }}
                      onChange={(e) => onChangeEndTime(e)}
                      options={timeArray}
                      value={endTime}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center col-md-2">
                  {/* <b>{getHourseToString().toLocaleUpperCase()}</b> */}
                  <b>
                    {calculateHours(
                      startTime?.value,
                      endTime == undefined ? "18:00:00" : endTime?.value
                    )}{" "}
                    HOURS
                  </b>
                </div>
              </div>

              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-12">
                  <label>
                    Title
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={handleTiming}
                    options={titlesArry}
                    value={valueOfTitle}
                  />
                </div>
                {showTextBox && (
                  <div className="col-md-12">
                    <label className="mt-2">
                      Enter Title <span className="text-danger">*</span>
                    </label>
                    <textarea
                      //  value={selectedOption}
                      onChange={(e) => setTitle(e.target.value)}
                      rows={4}
                      value={title}
                      className="form-control"
                      placeholder="Shift title"
                    />
                  </div>
                )}
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-12">
                  <label>
                    Users <span className="text-danger">*</span>
                  </label>

                  <AntSelect {...selectProps} />
                </div>
              </div>

              <div>
                <div className="row d-flex justify-content-around m-3 mt-5">
                  <div className="col-md-12">
                    <label>Shift Color</label>
                    <Select
                      defaultValue={defaultColor || ""}
                      value={defaultColor || ""}
                      onChange={(e) => {
                        setDefaultColor(e);
                        setColor(e.value);
                      }}
                      options={colorOptions}
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomValue,
                      }}
                      formatOptionLabel={formatOptionLabel}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-around m-3 mt-5">
                  <div className="col-md-12">
                    <label>Repeat</label>
                    <Select
                      // defaultValue={{ label: "Never", value: "never" }}
                      onChange={handleRepeat}
                      options={repeatoptions}
                      value={repeatValue}
                      defaultValue={repeatoptions[0]}
                    />
                  </div>
                </div>
                {repeat == "never" ? (
                  ""
                ) : (
                  <div className="row d-flex justify-content-around m-3 mt-5">
                    <div className="col-md-12">
                      <label>End Repeat</label>
                      <Select
                        defaultValue={{ label: "Never", value: "never" }}
                        value={endRepeatValue}
                        onChange={(e) => {
                          setEndRepeatValue(e);
                          if (e.value == "never") {
                            getNext7Years(startDate);
                            setEndRepeatDate("");
                            setEndRepeat(false);
                          } else {
                            setEndRepeat(true);
                          }
                        }}
                        options={[
                          { label: "Never", value: "never" },
                          { label: "Select Date", value: "date" },
                        ]}
                      />
                    </div>
                  </div>
                )}
                {endRepeat ? (
                  <div className="row d-flex justify-content-around m-3 mt-5">
                    <div className="col-md-12">
                      <label>End Repeat Date</label>
                      <div>
                        <DatePicker
                          format={"DD/MM/YYYY"}
                          disabledDate={disabledDate}
                          style={{ width: "100%" }}
                          onChange={(dd, fD) => setEndRepeatDate(fD)}
                          defaultValue={
                            endRepeatDate
                              ? dayjs(endRepeatDate,"DD/MM/YYYY")
                              : ""
                          }
                        />
                      </div>
                     
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="d-flex align-items-center col-md-4">
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        checked={isGeoFence}
                        onChange={(check) => {
                          setIsGeoFence(check.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="Geo Fence"
                    labelPlacement="start"
                  />
                </div>

                <div className="d-flex align-items-center col-md-4">
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        checked={draft}
                        onChange={(check) => {
                          setDraft(check.target.checked);
                        }}
                      />
                    }
                    label="Draft"
                    labelPlacement="start"
                  />
                </div>
              </div>
              {isGeoFence && (
                <div className="m-3 mt-5 ml-3">
                  <label className="ml-3">
                    Location <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex align-items-center col-md-12">
                    <GooglePlacesAutocomplete
                      apiKey={api_key}
                      selectProps={{
                        renderSuggestions: () => {
                          return (
                            <div>
                              <label>Current location</label>
                            </div>
                          );
                        },
                        address,
                        onChange: handleSelect,
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            color: "blue",
                            width: 300,
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                        },
                      }}
                    />
                  </div>

                  {locError && <span className="text-danger">{locError}</span>}
                </div>
              )}
              <div className="row d-flex justify-content-around m-3 mt-5">
                <Button
                  onClick={() => {
                    if (rota?.is_draft == false) {
                      moveAllShiftsToDraftOnly()
                        .then((success) => {
                          if (success) {
                            CheckerEvent(true);
                          } else {
                          }
                        })
                        .catch((error) => {});
                    } else {
                      CheckerEvent(true);
                    }
                  }}
                  type="primary"
                  success
                >
                  Save
                </Button>
                {/* {PremissionChecker(user, "rota_shift_template.create") ? ( */}
                  <Button onClick={() => saveTemplate()} type="dashed" success>
                    Save as Template
                  </Button>
                {/* ) : (
                  ""
                )} */}

                <Button onClick={closeDrawer} type="link" danger>
                  Cancel
                </Button>
              </div>

              <Divider />
              <div className="text-center pt-4">
                <b>
                  <h3>Templates</h3>
                </b>
                {/* {PremissionChecker(user, "rota_shift_template.list") ? ( */}
                  <div>
                    {templates?.map((shift) => (
                      <div
                        onClick={() => onTemplateShiftClick(shift)}
                        className="mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Card
                          className={
                            selectedTemplate == shift.id
                              ? "templateCard myselectedTemp"
                              : "templateCard"
                          }
                          size="small"
                          title={<h6>{shift.title}</h6>}
                          extra={
                            <>
                              <div className="d-flex">
                                <div
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "6px",
                                    marginRight: "10px",
                                    borderRadius: "100%",
                                    backgroundColor: shift.color,
                                  }}
                                ></div>
                                {/* {PremissionChecker(
                                  user,
                                  "rota_shift_template.delete"
                                ) ? ( */}
                                  <a
                                    onClick={() => {
                                      setTemplateId(shift.id);
                                      setdeleteModal(true);
                                    }}
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/General/Trash.svg"
                                        )}
                                      />
                                    </span>
                                  </a>
                                {/* ) : (
                                  ""
                                )} */}
                              </div>
                            </>
                          }
                          style={{ width: 300 }}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <span>
                                <b>All Day</b>
                              </span>
                              <span className="mx-2">
                                {shift.full_day ? (
                                  <CheckOutlined
                                    style={{
                                      position: "relative",
                                      color: "green",
                                      bottom: "3px",
                                    }}
                                  />
                                ) : (
                                  <CloseOutlined
                                    style={{
                                      position: "relative",
                                      color: "red",
                                      bottom: "3px",
                                    }}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <span>
                                <b>Geo Fence</b>
                              </span>
                              <span className="mx-2">
                                {shift.geo_fence ? (
                                  <CheckOutlined
                                    style={{
                                      position: "relative",
                                      color: "green",
                                      bottom: "3px",
                                    }}
                                  />
                                ) : (
                                  <CloseOutlined
                                    style={{
                                      position: "relative",
                                      color: "red",
                                      bottom: "3px",
                                    }}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <span>
                                <b>Start Date</b>
                              </span>
                              <span className="mx-2">{shift.start_date}</span>
                            </div>
                            <div className="col-md-6">
                              <span>
                                <b>End Date</b>
                              </span>
                              <span className="mx-2">{shift.end_date}</span>
                            </div>
                            <div className="col-md-6">
                              <span>
                                <b>Start Time</b>
                              </span>
                              <span className="mx-2">
                                {removeSeconds(shift.start_time)}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <span>
                                <b>End Time</b>
                              </span>
                              <span className="mx-2">
                                {removeSeconds(shift.end_time)}
                              </span>
                            </div>
                            <div className="col-md-12">
                              <hr />
                              <h6>Users</h6>
                              <div
                                style={{
                                  height: "4px",
                                  background: "black",
                                  width: "20px",
                                }}
                              ></div>
                            </div>
                            {shift.users.map((user) => (
                              <div className="col-md-6 mt-4">
                                <div className="d-flex">
                                  <Avatar
                                    style={{
                                      backgroundColor: `#${user.color}`,
                                      width: 30,
                                      height: 30,
                                    }}
                                    alt={user?.username?.toUpperCase()}
                                    src="#"
                                  />
                                  <span className="mx-2 mt-1">
                                    {user.username}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Card>
                      </div>
                    ))}
                  </div>
                {/* // ) : (
                //   <h4>Do Not Have Permission</h4>
                // )} */}
              </div>
            </MuiPickersUtilsProvider>
          </>
        )}
      </Drawer>

      {/* edit drawer majburi wala drawer */}

      <EditDrawer2
        mainLoading={movingDraftLoading}
        moveAllShiftsToDraftOnly={moveAllShiftsToDraftOnly}
        isDraft={rota?.is_draft}
        allUsers={usersList}
        defUser={defaultUser}
        defColor={defaultColor}
        drawerVisible={editDrawerModal}
        future={updateForFuture}
        id={resultId}
        hideDrawer={() => {
          setDefaultColor(null);
          setEditDrawerModal(false);
          setUpdateForFuture(0)
          setResultId(undefined)
        }}
        object={selectedObject}
        reload={getRotaCalendar}
        rotaId={rotaId}
        rotaPlannerDraft={rotaPlannerDraft}
      />
      <EditDrawer2
        mainLoading={movingDraftLoading}
        moveAllShiftsToDraftOnly={moveAllShiftsToDraftOnly}
        isDraft={rota?.is_draft}
        allUsers={usersList}
        future={updateForFuture}
        id={resultId}
        defUser={defaultUser}
        defColor={defaultColor}
        drawerVisible={editDrawerModal2}
        hideDrawer={() => {
          setDefaultColor(null);
          setEditDrawerModal2(false);
        }}
        object={selectedObject}
        reload={getRotaCalendar}
        rotaId={rotaId}
        rotaPlannerDraft={rotaPlannerDraft}
      />
      <UserProfileDrawer
        drawerVisible={profileDrawer}
        user={profileUser}
        hideDrawer={() => setProfileDrawer(false)}
      />

      <ConfirmModal
        title={
          "This User is on Leave Today Do you want to create shift anyway?"
        }
        btnText={"Create Anyway"}
        modelTitle={"Shift Confirmation"}
        modalVisible={confirmModal}
        hideModal={() => setConfirmModal(false)}
        onClickAnyWay={saveEvent}
      />
      <ConfirmForRecurring
        title={
          "Do you want to repeat this shift"
        }
        btnText={"Yes"}
        modelTitle={"Edit Shift"}
        modalVisible={checkingModal}
        hideModal={() => setCheckingModal(false)}
        onClickAnyWay={()=>{
          setCheckingModal(false)
          setEditDrawerModal(true)}}
        onClickAnyWay2={()=>{
      setrepeatoptions(repeatArray)
          setCheckingModal(false)
          setEditDrawerModal2(true)}}
      />
      <ConfirmModal
        title={`Are you sure you want to ${
          hasUnpublish ? "unpublish times" : "publish times"
        }?`}
        btnText={hasUnpublish ? "Unpublish Times" : "Publish Times"}
        loading={singlePublishLoading}
        modelTitle={hasUnpublish ? "Unpublish Times" : "Publish Times"}
        modalVisible={confirmPublishModal}
        hideModal={() => setConfirmPublishModal(false)}
        onClickAnyWay={publishDay}
      />
      <ConfirmModal
        title={`Are you sure you want to send notification?`}
        btnText={"Send"}
        loading={singlePublishLoading}
        modelTitle={"Send Notification"}
        modalVisible={notificationModal}
        hideModal={() => setNotificationModal(false)}
        onClickAnyWay={publishDay}
      />
      <ConfirmModal
        title={`Are you sure you want to delete All Shifts for the day?`}
        btnText={"Delete"}
        loading={singleDeleteLoading}
        mainLoading={movingDraftLoading}
        modelTitle={"Delete"}
        modalVisible={confirmdeleteModal}
        hideModal={() => setConfirmDeleteModal(false)}
        onClickAnyWay={() => {
          if (rota?.is_draft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  deleteDay(true);
                } else {
                }
              })
              .catch((error) => {});
          } else {
            deleteDay(true);
          }
        }}
      />
      <ConfirmModal
        title={`Are you sure you want to move shifts to draft?`}
        btnText={"Yes"}
        loading={moveDraftModalLoading}
        modelTitle={"Move Shifts To Draft"}
        modalVisible={moveDraftModal}
        hideModal={() => setMoveDraftModal(false)}
        onClickAnyWay={moveAllShiftsToDraft}
      />
      <ConfirmModal
        title={`Are you sure you want to save changes?`}
        btnText={"Yes"}
        hasPublishDraft={checkerIsDraf}
        handleCheck={(value)=>{
          setIsPublishDraftShifts(value)
        }}
        loading={moveDraftToLiveModalLoading}
        modelTitle={"Save Changes?"}
        modalVisible={moveDraftToLiveModal}
        hideModal={() => setMoveDraftToLiveModal(false)}
        onClickAnyWay={moveToLive}
      />
      <ConfirmModal
        title={`Are you sure you want to Cancel ALL Changes?`}
        btnText={"Yes"}
        loading={revertModalLoading}
        modelTitle={"Cancel Changes"}
        modalVisible={revertModal}
        hideModal={() => setRevertModal(false)}
        onClickAnyWay={revertRota}
      />

      <Modal
        className="pikkerModal"
        title="Select Start and End Date"
        open={pikerModal}
        onCancel={handleCancel}
        onOk={handleOk}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { backgroundColor: "#151515" } }}
      ></Modal>

      <DeleteModal
        HideModal={() => setdeleteModal(false)}
        visible={deleteModal}
        // id={SelectedId}
        method="DELETE"
        reload={() => getTemplates()}
        url={`${baseUrl}/mgmt/rota-shift-templates/${templateId}`}
      />
      <DeleteDraftModal
        HideModal={() => setdeleteDraftModal(false)}
        visible={deleteDraftModal}
        // id={SelectedId}
        mainLoading={movingDraftLoading}
        isDraft={rota?.is_draft}
        moveAllShiftsToDraftOnly={moveAllShiftsToDraftOnly}
        isIMove={Oflinemode}
        method="DELETE"
        reload={() => getRotaCalendar()}
        url1={`${baseUrl}/mgmt/rota/${rotaId}/delete-all-draft-shifts`}
        url2={`${baseUrl}/mgmt/rota/${rotaId}/delete-all-draft-draft-shifts`}
      />
      <DeleteShift
        title="Delete All Future Shifts"
        msg="Are you sure you want to delete all Shifts?"
        HideModal={() => setDeleteShiftModal(false)}
        visible={deleteShiftModal}
        shifts={deleteShifts}
        start_date={newStartDate}
        end_date={newEndDate}
        reload={() => getRotaCalendar()}
        url={
          rotaPlannerDraft
            ? `${baseUrl}/mgmt/custom-destroy-draft-rota/${rotaId}/shifts`
            : `${baseUrl}/mgmt/custom-destroy-rota-shifts`
        }
        openOverlap={() => {}}
      />
      <DeleteShift
        HideModal={() => setPublishShiftModal(false)}
        visible={publishShiftModal}
        shifts={publishShiftArray}
        msg={"Are you sure you want to publish all Draft shifts?"}
        title="Publish All Draft Shifts"
        start_date={changer(newStartDate)}
        end_date={newEndDate}
        button="Publish"
        users={usersList}
        reload={() => getRotaCalendar()}
        openOverlap={() => setOverlapDraft(true)}
        url={
          rotaPlannerDraft
            ? `${baseUrl}/mgmt/custom-publish-draft-rota/${rotaId}/shifts`
            : `${baseUrl}/mgmt/custom-publish-rota/${rotaId}/shifts`
        }
      />
      <CustomModal open={custom} close={() => setCustom(false)} />
      <UpdateRecureModal
      entireButton={false}
        singleUpdate={handleMove}
        updateOpen={reCureEditModal}
        updateClose={() =>{
          setEventList((prev) => {
            const index = prev.findIndex(
              (item) => item.sposedId === updateData.sposedId
            );
            const updatedArray = [...prev];
            updateData["start"] = `${formetTime(updateData.date)}T${
              updateData.start_time ? updateData.start_time : "00:00:00"
            }`;
            updateData["end"] = `${formetTime(updateData.date)}T${
              updateData.end_time ? updateData.end_time : "00:00:00"
            }`;
            updateData["resource"] = updateData.user_id;
            updatedArray[index] = {
              ...updateData,
            };
            return updatedArray;
          });
          setReCureEditModal(false)}}
      />
      <DeleteRecureModal
        mainLoading={movingDraftLoading}
        muLoading={addLoading}
        futureDelete={() => {
          if (rota?.is_draft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  const  result=  findingLiveShiftInDraft(success.shifts)
                  removeFutureShift(true,result.id,result.user_id);
                } else {
                }
              })
              .catch((error) => {});
          } else {
            removeFutureShift(true);
          }
        }}
        singleDelete={() => {
          if (rota?.is_draft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  const  result=  findingLiveShiftInDraft(success.shifts)
                  removeEvent(true,result.id);
                } else {
                }
              })
              .catch((error) => {});
          } else {
            removeEvent(true);
          }
        }}
        open={reCureDeleteModal}
        close={() => setReCureDeleteModal(false)}
      />

      <OverlapModal
        muLoading={overLapModalLoading}
        singleDelete={() => deleteOverlapShifts(0)}
        open={overLapModal}
        close={() => setOverLapModal(false)}
      />
      <OverlapModal
        muLoading={overLapModalLoading}
        singleDelete={() => deleteOverlapShifts(1)}
        open={overlapDraft}
        close={() => setOverlapDraft(false)}
      />

      <DeleteShift2
        mainLoading={movingDraftLoading}
        muLoading={addLoading}
        handleDelete={() => {
          if (rota?.is_draft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
               const  result=  findingLiveShiftInDraft(success.shifts)
                  removeEvent(true,result.id);
                } else {
                }
              })
              .catch((error) => {});
          } else {
            removeEvent(true);
          }
        }}
        open={deleteConfirmation}
        close={() => setDeleteConfirmation(false)}
      />

      <SettingModal
        open={settingmodal}
        rotaId={rotaId}
        onHide={() => {
          setSettingmodal(false);
        }}
        reload={() => myReloader()}
        // onOk={() => formik.handleSubmit()}
        // reload={getRotaGroups}
      />

<UpdateRecureModal
entireButton={true}
        singleUpdate={(future) => {
          if (rota?.is_draft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  const result = findingLiveShiftInDraft(success.shifts);
                  // saveEvent(future, result.id);
                  setResultId(result?.id)
                  setUpdateForFuture(future)
                  setEditDrawerModal(true)
                  setReCureEditModal2(false)
                } else {
                }
              })
              .catch((error) => {});
          } else {
            setUpdateForFuture(future)
            setEditDrawerModal(true)
            setReCureEditModal2(false)
            // saveEvent(future);
          }
        }}
        updateOpen={reCureEditModal2}
        updateClose={() => setReCureEditModal2(false)}
      />
           {assignModal? <AssignRotaUsers
                    permission={PremissionChecker(user, "rota.assign_user")}
                    rotaId={rotaId}
                    visible={assignModal}
                    setModalVisibility={(bol) => setAssignModal(bol)}
                    refresh={() => getRotaCalendar()}

                />:''}
    </div>
  );
}

export default RotaCalendarMobi;
