import React, { useEffect, useRef } from "react";
import {
  TimePicker,
  Switch,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Spin,
  Space,
  Button,
  Select as AntSelect,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Switch as MuiSwitch } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../services/PremissionChecker";
import Select from "react-select";
import DeleteRecureModal from "../DeleteRecureModal/DeleteRecureModal";
import DeleteShift from "../DeleteRecureModal/DeleteShift";
import UpdateRecureModal from "../UpdateRecureModal/UpdateRecureModal";
import { DateRangePicker as RsuitePicker } from "rsuite";
import { addDays, isSameDay } from 'date-fns';
import { DatePicker as RsuitePickerDate } from "rsuite";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { beforeToday } = RsuitePicker;

const EditDrawer2 = ({
  defColor,
  object,
  hideDrawer,
  drawerVisible,
  reload,
  rotaId,
  defUser,
  allUsers,
  rotaPlannerDraft,
  isDraft,
  mainLoading = false,
  future,
  id,
  moveAllShiftsToDraftOnly,
}) => {
  const timeArray = [
    { label: "12:00 am", value: "00:00:00" },
    { label: "0:30 am", value: "00:30:00" },
    { label: "1:00 am", value: "01:00:00" },
    { label: "1:30 am", value: "01:30:00" },
    { label: "2:00 am", value: "02:00:00" },
    { label: "2:30 am", value: "02:30:00" },
    { label: "3:00 am", value: "03:00:00" },
    { label: "3:30 am", value: "03:30:00" },
    { label: "4:00 am", value: "04:00:00" },
    { label: "4:30 am", value: "04:30:00" },
    { label: "5:00 am", value: "05:00:00" },
    { label: "5:30 am", value: "05:30:00" },
    { label: "6:00 am", value: "06:00:00" },
    { label: "6:30 am", value: "06:30:00" },
    { label: "7:00 am", value: "07:00:00" },
    { label: "7:30 am", value: "07:30:00" },
    { label: "8:00 am", value: "08:00:00" },
    { label: "8:30 am", value: "08:30:00" },
    { label: "9:00 am", value: "09:00:00" },
    { label: "9:30 am", value: "09:30:00" },
    { label: "10:00 am", value: "10:00:00" },
    { label: "10:30 am", value: "10:30:00" },
    { label: "11:00 am", value: "11:00:00" },
    { label: "11:30 am", value: "11:30:00" },
    { label: "12:00 pm", value: "12:00:00" },
    { label: "12:30 pm", value: "12:30:00" },
    { label: "1:00 pm", value: "13:00:00" },
    { label: "1:30 pm", value: "13:30:00" },
    { label: "2:00 pm", value: "14:00:00" },
    { label: "2:30 pm", value: "14:30:00" },
    { label: "3:00 pm", value: "15:00:00" },
    { label: "3:30 pm", value: "15:30:00" },
    { label: "4:00 pm", value: "16:00:00" },
    { label: "4:30 pm", value: "16:30:00" },
    { label: "5:00 pm", value: "17:00:00" },
    { label: "5:30 pm", value: "17:30:00" },
    { label: "6:00 pm", value: "18:00:00" },
    { label: "6:30 pm", value: "18:30:00" },
    { label: "7:00 pm", value: "19:00:00" },
    { label: "7:30 pm", value: "19:30:00" },
    { label: "8:00 pm", value: "20:00:00" },
    { label: "8:30 pm", value: "20:30:00" },
    { label: "9:00 pm", value: "21:00:00" },
    { label: "9:30 pm", value: "21:30:00" },
    { label: "10:00 pm", value: "22:00:00" },
    { label: "10:30 pm", value: "22:30:00" },
    { label: "11:00 pm", value: "23:00:00" },
    { label: "11:30 pm", value: "23:30:00" },
  ];

  const repeatArray = [
    { label: "Never", value: "never" },
    { label: "Every Day", value: "everyday" },
    { label: "Every Week", value: "weekly" },
    { label: "Every 2 Weeks", value: "every_two_week" },
    { label: "Every 3 Weeks", value: "every_three_week" },
    { label: "Every Month", value: "monthly" },
    { label: "Every Other Month", value: "every_other_month" },
    { label: "Every Year", value: "yearly" },
    { label: "Every Week Gap", value: "weekly_gape" },
    { label: "Every 2 Weeks Gap", value: "every_two_week_gape" },
    { label: "Every 3 Weeks Gap", value: "every_three_week_gape" },
    { label: "Every Month Gap", value: "monthly_gape" },
    // { label: "Every Other Month Gap", value: "every_other_month_gape" },
    { label: "Every Year Gap", value: "yearly_gape" },
    // { label: "Custom", value: "custom" },
  ];

  const user = useSelector((state) => state.user.user);
  const isInitialMount = useRef(true);

  const googleInputRef = useRef();
  const authtoken = localStorage.getItem("userToken");
  var currentDate1 = new Date();

  currentDate1.setHours(9);
  currentDate1.setMinutes(0);
  currentDate1.setSeconds(0);

  var currentDate2 = new Date();

  currentDate2.setHours(18);
  currentDate2.setMinutes(0);
  currentDate2.setSeconds(0);

  const [latitude, setLatitude] = useState(object.latitude);
  const [longitude, setLongitude] = useState(object.longitude);
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState({
    label: object.address,
    value: { description: object.address },
  });
  const [color, setColor] = useState(object.color);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  //   const [title, setTitle] = useState(object?.rota_setting !=null ? object?.rota_setting?.title : object?.title);
  const [viewTitle, setviewTitle] = useState(object.title);
  const [shiftDate, setShiftdate] = useState(object.date);
  const [tempshiftDate, setTempshiftDate] = useState("");
  // const [startTime, setstartTime] = useState(object.start ? object.start : new Date(currentDate1))
  const [startTime, setstartTime] = useState();
  // const [endTime, setendTime] = useState(object.end ? object.end : new Date(currentDate2))
  const [endTime, setendTime] = useState();
  const [isfullDay, setIsFullday] = useState(
    object?.rota_setting != null
      ? object?.rota_setting?.full_day == "1"
        ? true
        : false
      : object?.full_day == "1"
      ? true
      : false
  );
  const [hideShow, setHideShow] = useState(object.hide == "1" ? true : false);
  const [title, setTitle] = useState("");

  const [isGeoFence, setIsGeoFence] = useState(object.isGeoFense);
  const [draft, setDraft] = useState(object.draft == "1" ? true : false);
  const [addLoading, setAddLoading] = useState(false);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [rotaUsers, setrotaUsers] = useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date(object.date));
  const [myDefUser, setMydefUser] = React.useState();
  const [defaultColor, setDefaultColor] = React.useState(defColor);
  const [reCureDeleteModal, setReCureDeleteModal] = useState(false);
  const [reCureEditModal, setReCureEditModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [endRepeatDate, setEndRepeatDate] = useState("");
  const [next7ear, setNext7Year] = useState("");
  const [endRepeat, setEndRepeat] = useState(false);
  const [repeat, setRepeat] = useState("never");
  const [selectedOption, setSelectedOption] = useState("");
  const [titlesArry, setTitlesArray] = useState([]);
  const [valueOfTitle, setValueOftitme] = useState({});
  const [showTextBox, setShowTextBox] = useState(false);
  const [rotaSettingId, setRotaSettingId] = useState(null);
  const [repeatoptions, setrepeatoptions] = useState([...repeatArray]);
  const [startRange, setStartRange] = useState(new Date());
  const [endRange, setEndRange] = useState(new Date());
  const [onChangeWorks, setOnChangeWorks] = useState(false);
  const [endRepeatValue, setEndRepeatValue] = useState({
    label: "Never",
    value: "never",
  });

  const [repeatValue, setRepeatValue] = useState({
    label: "Never",
    value: "never",
  });
  function formatDateEndRepeat2(dateString) {
    if (!dateString) {
      return;
    }
    var parts = dateString.split(dateString.includes("-") ? "-" : "/");
    var day = parts[2];
    var month = parts[1];
    var year = parts[0];

    day = day.padStart(2, "0");
    month = month.padStart(2, "0");

    var formattedDate = day + "/" + month + "/" + year;
    return formattedDate;
  }
  useEffect(() => {
    // This code will only run once on the initial mount of the component
    if (isInitialMount.current) {
      setAddress({
        label: "New York City, NY, USA",
        value: { description: "New York City, NY, USA" },
      });
      isInitialMount.current = false;
    }
  }, []);

  useEffect(() => {
    setOnChangeWorks(false);
    const rescor = allUsers?.find((item) => item.id == object.user_id);

    const filterStartTime = timeArray.find((time) => {
      if (object) {
        if (object.rota_setting) {
          return time.value == object.rota_setting.start_time;
        } else {
          return time.value == object.start_time;
        }
      }
    });
    const filterEndTime = timeArray.find((time) => {
      if (object) {
        if (object.rota_setting) {
          return time.value == object.rota_setting.end_time;
        } else {
          return time.value == object.end_time;
        }
      }
    });

    // const filterEndTime = timeArray.find((time) => time.value == object.rota_setting !=null ? object.rota_setting?.end_time : object.end_time)

    setShowTextBox(true);
    const getTitle = titlesArry.find((tt) => tt.value == object.title);
    if (getTitle == undefined) {
      handleTiming({ label: "Other", value: "Other" });
      setTitle(object.title);
    } else {
      handleTiming(getTitle);
    }

    const myrepeat = repeatArray.find(
      (item) => item.value == object.repeat_type
    );
    setEndRepeat(object.repeat_end_date ? true : false);
    setEndRepeatDate(
      object.repeat_end_date ? formatDateEndRepeat2(object.repeat_end_date) : ""
    );
    setEndRepeatValue(
      myrepeat
        ? { label: "Select Date", value: "date" }
        : { label: "Never", value: "never" }
    );
    setRepeatValue(
      myrepeat
        ? myrepeat
        : {
            label: "Never",
            value: "never",
          }
    );
    setrepeatoptions(repeatArray);
    // setRepeat(myrepeat ? myrepeat.value : "never");
    setRepeat(myrepeat ? myrepeat.value : "never");
    //  setStartRange(new Date(object.start_date_range))
    //  setEndRange(new Date(object.repeat_end_date))
    setEndRange(object.end_date_range);
    setMydefUser(rescor);
    setSelectedDate(new Date(object.date));
    console.log(object.start_date_range, "jfkfhsdkfjsd");
    setStartDate(new Date(object.start_date_range));
    setEndDate(new Date(object.end_date_range));
    setrepeatoptions(disableOptionsByGap(object.start_date_range, object.end_date_range));
    // setStartDate(new Date(object.start));
    // setEndDate(new Date(object.end));
    setstartTime(filterStartTime);
    setendTime(
      filterEndTime == undefined
        ? { label: "12:00 am", value: "00:00:00" }
        : filterEndTime
    );
    // setIsFullday(object.isFullDay)
    setTitle(
      object?.rota_setting != null ? object?.rota_setting?.title : object?.title
    );
    setviewTitle(object.title);
    setIsGeoFence(object.isGeoFense);
    if (defColor) {
      setDefaultColor(defColor);
    } else {
      setDefaultColor({});
    }
    setAddress(object.address);
    setDraft(object.draft == "1" ? true : false);
    setIsFullday(
      object?.rota_setting != null
        ? object?.rota_setting?.full_day == "1"
          ? true
          : false
        : object?.full_day == "1"
        ? true
        : false
    );
    setHideShow(object.hide == "1" ? true : false);
    setColor(object.color);
    setLatitude(object.latitude);
    setLongitude(object.longitude);
    if (object.latitude && object.longitude) {
      getDefaultLocationName(object.latitude, object.longitude);
    }
    if (drawerVisible) {
      getAllUsers();
      getAllTitle();
    }

    getNext7Years(new Date(object.start));

    if(future==0){
      setEndRepeat(false);
      setRepeat("never");
      setRepeatValue({ label: "Never", value: "never" });
      setEndRepeatValue({ label: "Never", value: "never" });
      setEndRepeatDate("");
    }
  }, [
    object.start_date_range,
    object.repeat_end_date,
    object.date,
    object.start,
    object.end,
    object.title,
    object.isGeoFense,
    object.address,
    object.color,
    object.latitude,
    object.longitude,
    defColor,
    drawerVisible,
  ]);
  function removeSeconds(timeStr) {
    const response = timeArray.find((item) => item.value == timeStr);
    if (response == undefined) {
      return timeStr;
    } else {
      return response.label.toUpperCase();
    }
    //  return timeStr.substring(0, 5);
  }

  function disableOptionsByGap(startDate, endDate) {
    const dayDifference = Math.floor(
      (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
    );

    const updatedOptions = repeatArray.map((option) => {
      switch (option.value) {
        case "everyday":
          return { ...option, isDisabled: dayDifference >= 1 };
        case "weekly":
          return { ...option, isDisabled: dayDifference >= 7 };
        case "every_two_week":
          return { ...option, isDisabled: dayDifference >= 14 };
        case "every_three_week":
          return { ...option, isDisabled: dayDifference >= 21 };
        case "monthly":
          return { ...option, isDisabled: dayDifference >= 30 };
        case "every_other_month":
          return { ...option, isDisabled: dayDifference >= 60 };
        case "yearly":
          return { ...option, isDisabled: dayDifference >= 365 };
        default:
          return option;
      }
    });

    return updatedOptions;
  }
  ///for scroll in view of selected time

  const [selectedElement, setSelectedElement] = useState(null);
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "MyDropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        setSelectedElement(selectedEl);
      }
    }, 15);
  };

  useEffect(() => {
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [selectedElement]);

  const getNext7Years = (startDate) => {
    var currentDate = startDate ? new Date(startDate) : new Date();
    var year = currentDate.getFullYear() + 5;
    var month = currentDate.getMonth() + 1;
    var day = currentDate.getDate();
    var todayDateString =
      year +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      (day < 10 ? "0" : "") +
      day;
    setNext7Year(todayDateString);
  };
  const handleTiming = (selected) => {
    setValueOftitme(selected);
    setSelectedOption(selected.value);
    if (selected.value == "Other") {
      setShowTextBox(true);
      setRotaSettingId(null);

      setEndRepeat(false);
      setRepeat("never");
      setRepeatValue({ label: "Never", value: "never" });
      setEndRepeatValue({ label: "Never", value: "never" });
      setEndRepeatDate("");
    } else {
      setIsFullday(selected.full_day);
      setShowTextBox(false);
      setRotaSettingId(selected.id);
      setstartTime({
        value: selected.start_time,
        label: removeSeconds(selected.start_time),
      });
      if (selected.end_time) {
        setendTime({
          label: removeSeconds(selected.end_time),
          value: selected.end_time,
        });
      } else {
        setendTime({
          label: "6:00 pm",
          value: "18:00:00",
        });
      }
      setEndRepeatValue({ label: "Never", value: "never" });
      setEndRepeatDate("");
      const filterRepeats = repeatoptions.find(
        (item) => item.value == selected.repeat
      );
      if (!filterRepeats.isDisabled) {
        setRepeatValue(filterRepeats);
        setRepeat(selected.repeat);
      }
      if (selected.repeat == "never") {
        setEndRepeat(false);
      }
    }
  };

  const getAllTitle = () => {
    // setloading(true);
    fetch(`${baseUrl}/mgmt/rotas/${rotaId}/rota-settings`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        let arr = [{ label: "Other", value: "Other" }];
        // setloading(false);
        data.payload.map((item) => {
          arr.push({
            ...item,
            label: item.title,
            value: item.title,
          });
        });
        setTitlesArray(arr);
      })

      .catch((err) => {
        // setloading(false);
        console.log(err);
      });
  };
  // On changes events
  const getAllUsers = () => {
    fetch(`${baseUrl}/mgmt/rotas/${rotaId}/assigned-users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        let tempArray = [];
        data.payload.forEach((item, index) => {
          tempArray.push({
            label: `${item.first_name} ${item.last_name}`,
            value: item.id.toString(),
          });
        });
        console.log(object.user_ids,"hello bunny bunny")
        const lastdata = tempArray.filter((item) =>
          object.user_ids.includes(item.value)
        );
        setrotaUsers(tempArray);
        setselectedUsers(lastdata);
      })
      .catch((err) => {});
  };
  const disabledDate = (current) => {
    return current && current < (startDate && startDate > dayjs().startOf("day") ? startDate : dayjs().startOf("day"));
  };
  
  
  function formatDateEndRepeat(dateString) {
    if (!dateString) {
      return;
    }
    var parts = dateString.split(dateString.includes("-") ? "-" : "/");
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];

    day = day.padStart(2, "0");
    month = month.padStart(2, "0");

    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  // useEffect(async () => {
  //     const results = await geocodeByAddress(value.label);
  //     const latLng = await getLatLng(results[0]);
  //     setAddress2(value);
  // }, [])

  const onChangeDate = (date, dateString) => {
    setShiftdate(dateString);
  };
  const findingLiveShiftInDraft = (shifts) => {
    const result = shifts.find((item) => {
      if (
        item.date == object.date &&
        item.end_time == object.end_time &&
        item.start_time == object.start_time &&
        item.user_id == object.user_id
      ) {
        return item;
      }
    });
    return result;
  };
  const onChangeStartTime = (time) => {
    let stime = `${new Date(time).getHours()}:${new Date(time).getMinutes()}`;
    setstartTime(time);
  };

  const onChangeEndTime = (time) => {
    let etime = `${new Date(time).getHours()}:${new Date(time).getMinutes()}`;
    setendTime(time);
  };

  const saveEvent = () => {
    if (isGeoFence == 1) {
      if (latitude == null || latitude == null) {
        return toast.error("Please Select Location");
      }
    }
    if(!repeat){
      return toast.error("Please Select Repeat");
    }
    console.log(repeat,"here is the repeat")
    setAddLoading(true);
    // let stime = `${new Date(startTime).getHours()}:${new Date(startTime).getMinutes()}:${new Date(startTime).getSeconds()}`

    // let etime = `${new Date(endTime).getHours()}:${new Date(endTime).getMinutes()}:${new Date(endTime).getSeconds()}`

    let formatStartDate = moment(startDate).format("YYYY-MM-DD");
    let formatEndDate = moment(endDate).format("YYYY-MM-DD");
    let formatedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");
    // let startTime2 = stime.replace(/0(?=:|$)/g, "00")
    // let endTime2 = etime.replace(/0(?=:|$)/g, "00")

    // let lastStartTime = startTime2.split(":").map((part, index) => index === 0 && part.length === 1 ? "0" + part : part).join(":");
    // let lastEndTime = endTime2.split(":").map((part, index) => index === 0 && part.length === 1 ? "0" + part : part).join(":");
    let arr = [];
    selectedUsers.map((item) => arr.push(item.value || item));
    // const data = {
    //   user_ids: arr,
    //   start_date:future==0 ? formatedSelectedDate : formatStartDate,
    //   end_date:future==0 ? formatedSelectedDate : future==2 ? formattedEndDateRange :   onChangeWorks ? formatEndDate : endRange,
    //   date:future==0 ? formatedSelectedDate : formatStartDate,
    //   title: selectedOption == "Other" ? title : selectedOption,
    //   full_day: 0,
    //   start_time: startTime.value,
    //   end_time: !isfullDay && endTime.value,
    //   geo_fence: isGeoFence ? 1 : 0,
    //   color: color,
    //   latitude: isGeoFence ? latitude : null,
    //   longitude: isGeoFence ? longitude : null,
    //   address: isGeoFence ? address : null,
    //   draft: draft ? 1 : 0,
    //   hide_time: hideShow == true ? 1 : 0,
    //   future_shifts_edit: future > 0 ? 1 : 0,
    //   offline: object.offline,
    //   repeat: repeat,
    //   repeat_end_date:
    //     repeat == "never"
    //       ? ""
    //       : endRepeat
    //       ? formatDateEndRepeat(endRepeatDate)
    //       : next7ear,
    //   rota_setting_id: rotaSettingId,
    // };
    const formData = new FormData();
console.log(arr,"what happened")
    arr.map((userId,index) => {
      formData.append(`user_ids[${index}]`, userId);
  });
    formData.append(
      "start_date",
      future == 0 || future == 1 ? formatedSelectedDate : formatStartDate
    );
    formData.append(
      "end_date",
      future == 0 || future == 1 ? formatedSelectedDate : formatEndDate
    );
    formData.append(
      "date",
      future == 0  || future == 1 ? formatedSelectedDate : formatStartDate
    );
    formData.append(
      "title",
      selectedOption == "Other" ? title : selectedOption
    );
    formData.append("full_day", 0);
    formData.append("start_time", startTime.value);
    // formData.append("new_start_date", formatStartDate);
    if (!isfullDay) formData.append("end_time", endTime.value);
    formData.append("geo_fence", isGeoFence ? 1 : 0);
    formData.append("color", color);
    formData.append("latitude", isGeoFence ? latitude : "");
    formData.append("longitude", isGeoFence ? longitude : "");
    formData.append("address", isGeoFence ? address : null);
    formData.append("draft", draft ? 1 : 0);
    formData.append("hide_time", hideShow == true ? 1 : 0);
    formData.append("future_shifts_edit", future);
    formData.append("offline", 1);
    formData.append("repeat", repeat);
    formData.append("start_date_range", formatStartDate);
    formData.append("end_date_range", formatEndDate);
    formData.append("repeat_end_date", repeat == "never"
      ? ""
      : endRepeat
      ? formatDateEndRepeat(endRepeatDate)
      : next7ear);

    if (rotaSettingId) {
      formData.append("rota_setting_id", rotaSettingId);
    }

    // const data2 = {
    //   user_ids: arr,
    //   start_date:future==0 ? formatedSelectedDate : formatStartDate,
    //   end_date:future==0 ? formatedSelectedDate : future==2 ? formatEndDate :   onChangeWorks ? formatEndDate : endRange,
    //   date:future==0 ? formatedSelectedDate : formatStartDate,
    //   title: selectedOption == "Other" ? title : selectedOption,
    //   full_day: 1,
    //   color: color,
    //   geo_fence: isGeoFence ? 1 : 0,
    //   latitude: isGeoFence ? latitude : null,
    //   longitude: isGeoFence ? longitude : null,
    //   address: isGeoFence ? address : null,
    //   draft: draft ? 1 : 0,
    //   hide_time: hideShow == true ? 1 : 0,
    //   future_shifts_edit: future > 0 ? 1 : 0,
    //   // start_time:"00:00:00",
    //   start_time: startTime.value,
    //   offline: object.offline,
    //   repeat: repeat,
    //   repeat_end_date:
    //     repeat == "never"
    //       ? ""
    //       : endRepeat
    //       ? formatDateEndRepeat(endRepeatDate)
    //       : next7ear,
    //   rota_setting_id: rotaSettingId,
    // };

    const formData2 = new FormData();

    arr.map((userId,index) => {
      formData2.append(`user_ids[${index}]`, userId);
  });
    formData2.append(
      "start_date",
      future == 0 || future == 1 ? formatedSelectedDate : formatStartDate
    );
    formData2.append(
      "end_date",
      future == 0 || future == 1 ? formatedSelectedDate : formatEndDate
    );
    formData2.append(
      "date",
      future == 0  || future == 1 ? formatedSelectedDate : formatStartDate
    );
    formData2.append(
      "title",
      selectedOption == "Other" ? title : selectedOption
    );
    formData2.append("full_day", 1);
    // formData2.append("new_start_date", formatStartDate);
    formData2.append("color", color);
    formData2.append("geo_fence", isGeoFence ? 1 : 0);
    formData2.append("latitude", isGeoFence ? latitude : "");
    formData2.append("longitude", isGeoFence ? longitude : "");
    formData2.append("address", isGeoFence ? address : null);
    formData2.append("draft", draft ? 1 : 0);
    formData2.append("hide_time", hideShow == true ? 1 : 0);
    formData2.append("future_shifts_edit", future);
    formData2.append("start_time", startTime.value); // Uncommented, using `startTime.value`
    formData2.append("offline", 1);
    formData2.append("start_date_range", formatStartDate);
    formData2.append("end_date_range", formatEndDate);
    formData2.append("repeat", repeat);
    formData2.append(
      "repeat_end_date",
      repeat == "never"
        ? ""
        : endRepeat
        ? formatDateEndRepeat(endRepeatDate)
        : next7ear
    );
    if (rotaSettingId) {
      formData2.append("rota_setting_id", rotaSettingId);
    }

    let draftUrl = `${baseUrl}/mgmt/draft-rota-shifts/${
      id != undefined ? id : object.id
    }?_method=PUT`;

    fetch(draftUrl, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: isfullDay ? formData2 : formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setDefaultColor("");
          setReCureEditModal(false);
          setAddLoading(false);
          setOnChangeWorks(false);
          hideDrawer();
          reload();
          toast.success(data.metadata.message);
        } else if (!data.metadata.success) {
          setAddLoading(false);
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        }
      })
      .catch((err) => {
        setAddLoading(false);
      });
  };
  const handlePickerChange = (e) => {
    setOnChangeWorks(true);
    if (e == null) {
      setStartDate(null);
      setEndDate(null);
      setrepeatoptions(repeatArray);
    } else {
      console.log(e[1], "here we go ");
      setEndDate(e[1]);
      setStartDate(e[0]);
      getNext7Years(e[0]);
      // setEndRepeatDate(dayjs(e[1]).format("DD/MM/YYYY"))
      setrepeatoptions(disableOptionsByGap(e[0], e[1]));
      if (repeatValue?.value != "never") {
        setRepeatValue(null);
        setRepeat("");
      }
    }
  };
  const formetTime = (date) => {
    let dd = new Date(date);

    const fd = new Intl.DateTimeFormat("en-us", {
      timeStyle: "medium",
      hour12: false,
    });
    return fd.format(dd);
  };

  const removeEvent = () => {
    setAddLoading(true);
    // const url = `mgmt/rota-shifts/${object.id}`;
    const draftUrl = `mgmt/draft-rota-shifts/${object.id}`;

    fetch(`${baseUrl}/${draftUrl}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAddLoading(false);
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setReCureDeleteModal(false);
          setDeleteConfirmation(false);
          hideDrawer();
          reload();
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
          });
        }
      });
  };
  const removeFutureShift = () => {
    setAddLoading(true);
    // let url = `mgmt/delete-future-shifts/${object.id}`;
    // const user_ids=[object.user_id]

    let draftUrl = `mgmt/delete-future-draft-shifts/${object.id}`;
   const formData=new FormData()
    formData.append('user_ids[0]',object.user_id)
    fetch(`${baseUrl}/${draftUrl}`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      // body:JSON.stringify({user_ids:[id !=undefined ? [userId] :liveRotaSelectedShift.user_id]}),
      body:formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setAddLoading(false);
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setReCureDeleteModal(false);
          hideDrawer();
          reload();
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
          });
        }
      })
      .catch(() => {
        setAddLoading(false);
      });
  };

  const handleClose = () => {
    setReCureDeleteModal(false);
  };

  const handleDateChange = (date) => {
    setShiftdate(date);
    setSelectedDate(date);
    setTempshiftDate(date);
  };

  const handleRepeat = (e) => {
    setRepeatValue(e);
    setRepeat(e.value);
    if (e.value == "never") {
      setEndRepeat(false);
    }
  };
  const handleSelect = (result) => {
    geocodeByPlaceId(result.value.place_id)
      .then((results) => {
        const { lat, lng } = results[0].geometry.location;
        setAddress(results[0].formatted_address);
        setLatitude(lat());
        setLongitude(lng());
      })
      .catch((error) => {});
  };
  const colorOptions = [
    // { value: "#FF0000", label: "Red" },
    // { value: "#00FF00", label: "Green" },
    // { value: "#0000FF", label: "Blue" },
    // { value: "#FF69B4", label: "HotPink" },
    // { value: "#FFA07A", label: "LightSalmon" },
    // { value: "#BDB76B", label: "DarkKhaki" },
    // { value: "#663399", label: "RebeccaPurple" },
    // { value: "#32CD32", label: "LimeGreen" },
    // { value: "#6B8E23", label: "OliveDrab" },
    // { value: "#48D1CC", label: "MediumTurquoise" },
    // { value: "#5F9EA0", label: "CadetBlue" },
    { value: "#ADD8E6", label: "LightBlue" },
    { value: "#E0FFFF", label: "LightCyan" },
    { value: "#E6E6FA", label: "Lavender" },
    { value: "#D8BFD8", label: "Thistle" },
    { value: "#F0E68C", label: "Khaki" },
    { value: "#F5DEB3", label: "Wheat" },
    { value: "#FFFACD", label: "LemonChiffon" },
    { value: "#FFEFD5", label: "PapayaWhip" },
    { value: "#FFE4E1", label: "MistyRose" },
    { value: "#F0FFF0", label: "Honeydew" },
  ];
  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          padding: 10,
          backgroundColor: data.value,
          width: "20px",
          margin: 10,
          height: "20px",
          marginRight: "10px",
          borderRadius: 10,
        }}
      ></div>
      <div>{label}</div>
    </div>
  );

  // Custom value component to display color and label
  const CustomValue = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: data.value,
          width: "20px",
          height: "20px",
          padding: 10,
          margin: 10,
          marginRight: "10px",
          borderRadius: 10,
        }}
      ></div>
      {label}
    </div>
  );

  // Function to format the option label
  const formatOptionLabel = ({ label, value }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          padding: 10,
          backgroundColor: value,
          margin: 10,
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      ></div>
      {label}
    </div>
  );

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedUsers,
    options: rotaUsers,
    onChange: (newValue) => {
      setselectedUsers(newValue);
    },
    placeholder: "Select Users...",
    maxTagCount: "responsive",
  };
  //   const selectProps = {
  //     mode: "multiple",
  //     style: {
  //       width: "100%",
  //     },
  //     value:`${myDefUser?.first_name} ${myDefUser?.last_name}`,
  //     options: [],
  //     onChange: (newValue) => {
  //       setselectedUsers(newValue);
  //     },
  //     placeholder: "Select Users...",
  //     maxTagCount: "responsive",
  //   };
  const getDefaultLocationName = async (defaultLatitude, defaultLongitude) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${defaultLatitude},${defaultLongitude}&key=AIzaSyAwElRh57Tzj0OJJDr1VzLGXF1Xm6pcnUg`
        // `https://maps.googleapis.com/maps/api/geocode/json?latlng=${defaultLatitude},${defaultLongitude}AIzaSyAwElRh57Tzj0OJJDr1VzLGXF1Xm6pcnUg`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const locationName = data.results[0].formatted_address;
        setAddress(locationName);
      }
    } catch (error) {}
  };
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Drawer
        // title={currentDate}
        width={420}
        className="addDrawer"
        onClose={() => {
          setDefaultColor(null);
          setDraft(false);
          setColor("");
          setIsFullday(object.full_day == "1" ? true : false);
          hideDrawer();
        }}
        open={drawerVisible}
        bodyStyle={{
          paddingBottom: 50,
        }}
        extra={
          <Space>
            <h3 className="text-center">{`Edit:${
              viewTitle?.length > 32
                ? viewTitle?.substr(0, 32) + "..."
                : viewTitle
            }`}</h3>
          </Space>
        }
      >
        {addLoading ? (
          <div>
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : object?.completed == "1" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "380px",
            }}
          >
            <h4 className="text-primary">
              Shif is completed you cannot edit it
            </h4>
          </div>
        ) : (
          <>
            {object.draft == 0 && (
              <div className="d-flex align-items-center col-md-12">
                <span>Show Time</span>
                <Switch
                  checked={hideShow}
                  defaultChecked={object.hide}
                  className="ml-3"
                  onChange={(checked) => setHideShow(checked)}
                />
              </div>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="row d-flex justify-content-around m-3">
                <div className="d-flex align-items-center col-md-8">
                  {startDate != "Invalid Date" && future == 2 && (
                    <RsuitePicker
                      disabledDate={beforeToday()}
                      value={[startDate, endDate]}
                      onChange={handlePickerChange}
                      defaultValue={[startDate, endDate]}
                      format="dd/MM/yyyy"
                    />
                  )}

                  {selectedDate != "Invalid Date" &&
                    (future == 0 || future == 1) && (
                      <RsuitePickerDate
                        style={{ width: "100%" }}
                        value={selectedDate}
                        disabledDate={beforeToday()}
                        onChange={(e) => setSelectedDate(e)}
                        format="dd/MM/yyyy"
                      />
                    )}
                </div>
                <div className="d-flex align-items-center col-md-4">
                  <span>No End</span>
                  <Switch
                    checked={isfullDay}
                    defaultChecked={object.isfullDay}
                    className="ml-3"
                    onChange={(checked) => setIsFullday(checked)}
                  />

                  {/* <input
                                            onChange={(e) => setIsFullday(e.target.checked)}
                                            checked={isfullDay}
                                            defaultChecked={object.isfullDay}
                                            type='checkbox' /> */}
                </div>
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                {/* {
                                !isfullDay ? */}
                {/* <> */}

                <div className="col-md-6">
                  {/* <KeyboardTimePicker
                                            // disabled={isfullDay}
                                            margin="normal"
                                            id="start-time-picker"
                                            label="Start Time"
                                            value={startTime ? startTime : new Date(currentDate1)}
                                            onChange={(date) => onChangeStartTime(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        /> */}
                  <div>
                    <lable>Start Time</lable>
                  </div>
                  <div>
                    <Select
                      onMenuOpen={onMenuOpen}
                      // defaultValue="lucy"
                      style={{ width: 120 }}
                      onChange={(e) => onChangeStartTime(e)}
                      options={timeArray}
                      value={startTime}
                      className="MyDropdown"
                      classNamePrefix="MyDropdown"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <lable>End Time</lable>
                  </div>
                  <div>
                    <Select
                      onMenuOpen={onMenuOpen}
                      isDisabled={isfullDay}
                      style={{ width: 120 }}
                      onChange={(e) => onChangeEndTime(e)}
                      options={timeArray}
                      value={endTime}
                      className="MyDropdown"
                      classNamePrefix="MyDropdown"
                    />
                  </div>
                </div>
                {/* </> */}
                {/* 
                                    : null


                            } */}
              </div>

              {/* <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-12">
                  <label>
                    Title <span className="text-danger">*</span>
                  </label>
                  <textarea
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    rows={4}
                    className="form-control"
                    placeholder="Shift title"
                  />
                </div>
              </div> */}
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-12">
                  <label>
                    Title
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={handleTiming}
                    options={titlesArry}
                    value={valueOfTitle}
                  />
                </div>
                {showTextBox && (
                  <div className="col-md-12">
                    <label className="mt-2">
                      Enter Title <span className="text-danger">*</span>
                    </label>
                    <textarea
                      //  value={selectedOption}
                      onChange={(e) => setTitle(e.target.value)}
                      rows={4}
                      value={title}
                      className="form-control"
                      placeholder="Shift title"
                    />
                  </div>
                )}
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-12">
                  <label>
                    Users <span className="text-danger"></span>
                  </label>
                  {/* <Select
                    options={rotaUsers}
                    onChange={(users) => setselectedUsers(users)}
                    allowClear
                    isMulti

                    mode="multiple"
                    defaultValue={selectedUsers}
                  /> */}
                  <AntSelect
                    onChange={(users) => setselectedUsers(users)}
                    {...selectProps}
                  />
                </div>
              </div>

              <div>
                <div className="row d-flex justify-content-around m-3 mt-5">
                  <div className="col-md-12">
                    <label>Shift Color</label>
                    <Select
                      defaultValue={defColor == undefined ? "" : defColor}
                      value={defaultColor}
                      onChange={(e) => {
                        setDefaultColor(e);
                        setColor(e.value);
                      }}
                      options={colorOptions}
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomValue,
                      }}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {/* <AntSelect
                      options={colorOptions}
                      optionLabelProp="label"
                      className="custom-dropdown"
                      // dropdownClassName="custom-dropdown"
                    >
                      {colorOptions.map((option) => (
                        <div>
                          <div>circle</div>
                          <div>{option.label}</div>
                        </div>
                        // <CustomOption
                        //   key={option.value}
                        //   value={option.value}>
                        //   {option.label}
                        // </CustomOption>
                      ))}
                    </AntSelect> */}
                  </div>
                </div>
                <div className="row d-flex justify-content-around m-3 mt-5">
                  <div className="col-md-12">
                    <label>Repeat</label>
                    <Select
                      // defaultValue={{ label: "Never", value: "never" }}
                      onChange={handleRepeat}
                      options={repeatoptions}
                      value={repeatValue}
                    />
                  </div>
                </div>
                {repeat == "never" ? (
                  ""
                ) : (
                  <div className="row d-flex justify-content-around m-3 mt-5">
                    <div className="col-md-12">
                      <label>End Repeat</label>
                      <Select
                        defaultValue={{ label: "Never", value: "never" }}
                        value={endRepeatValue}
                        onChange={(e) => {
                          setEndRepeatValue(e);
                          if (e.value == "never") {
                            getNext7Years(startDate);
                            setEndRepeatDate("");
                            setEndRepeat(false);
                          } else {
                            setEndRepeat(true);
                          }
                        }}
                        options={[
                          { label: "Never", value: "never" },
                          { label: "Select Date", value: "date" },
                        ]}
                      />
                    </div>
                  </div>
                )}
                {endRepeat ? (
                  <div className="row d-flex justify-content-around m-3 mt-5">
                    <div className="col-md-12">
                      <label>End Repeat Date</label>
                      <div>
                        <DatePicker
                          format={"DD/MM/YYYY"}
                          disabledDate={disabledDate}
                          style={{ width: "100%" }}
                          onChange={(dd, fD) => {
                            
                  
                            // console.log(moment(fD, "DD/MM/YYYY").toDate(),"here wedfsjdhsakdsad")
                            
                            // setEndDate(moment(fD, "DD/MM/YYYY").toDate())
                            setEndRepeatDate(fD)}}
                          defaultValue={
                            endRepeatDate
                              ? dayjs(endRepeatDate, "DD/MM/YYYY")
                              : ""
                          }
                          value={endRepeatDate ? dayjs(endRepeatDate, "DD/MM/YYYY") : null}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="d-flex align-items-center col-md-4">
                  <span>Geo Fence</span>
                  {/* <AntdSwitch checked={isfullDay} className='ml-3' onChange={(checked) => setIsFullday(checked)} /> */}
                  {/* <FormControlLabel
                                    value={'start'}
                                    control={<MuiSwitch
                                        value={isGeoFence}
                                        onChange={check => {
                                            setIsGeoFence(check.target.checked)
                                        }}
                                        color="primary" />}
                                    label="Geo Fence"
                                    labelPlacement="start"
                                /> */}
                  <Switch
                    checked={isGeoFence}
                    className="ml-3"
                    onChange={(checked) => setIsGeoFence(checked)}
                  />
                </div>

                {/* <div className='d-flex align-items-center col-md-4'>
                                       
                                        <span>Shift Color</span>
                                        <input value={color} onChange={(e) => setColor(e.target.value)} type={'color'} />
                                    </div> */}
                <div className="d-flex align-items-center col-md-4">
                  <span>Draft</span>
                  {/* <AntdSwitch checked={isfullDay} className='ml-3' onChange={(checked) => setIsFullday(checked)} /> */}
                  {/* <FormControlLabel
                                    value={'start'}
                                    control={<MuiSwitch
                                        value={isGeoFence}
                                        onChange={check => {
                                            setIsGeoFence(check.target.checked)
                                        }}
                                        color="primary" />}
                                    label="Geo Fence"
                                    labelPlacement="start"
                                /> */}
                  <Switch
                    checked={draft}
                    className="ml-3"
                    onChange={(checked) => setDraft(checked)}
                  />
                </div>
              </div>
              {isGeoFence && address != undefined && (
                <div className="row d-flex justify-content-around m-3 mt-5">
                  <div className="d-flex align-items-center col-md-12">
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyAwElRh57Tzj0OJJDr1VzLGXF1Xm6pcnUg"
                      ref={googleInputRef}
                      selectProps={{
                        renderSuggestions: () => {
                          return (
                            <div>
                              <label>Current location</label>
                            </div>
                          );
                        },

                        defaultInputValue: address,
                        address,
                        onChange: handleSelect,
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            color: "blue",
                            width: 300,
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                        },
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row d-flex justify-content-around m-3 mt-5">
                {PremissionChecker(user, "rota_shift.update") ? (
                  <Button
                    // onClick={() => {
                    //   if (object.isRe) {
                    //     setReCureEditModal(true);
                    //   } else {
                    //     if (isDraft == false) {
                    //       moveAllShiftsToDraftOnly()
                    //         .then((success) => {
                    //           if (success) {
                    //             const result = findingLiveShiftInDraft(
                    //               success.shifts
                    //             );
                    //             saveEvent(0, result.id);
                    //           } else {
                    //           }
                    //         })
                    //         .catch((error) => {});
                    //     } else {
                    //       saveEvent(0);
                    //     }
                    //   }
                    // }}
                    onClick={saveEvent}
                    type="primary"
                    success
                  >
                    Update
                  </Button>
                ) : null}
                {PremissionChecker(user, "rota_shift.delete") ? (
                  <Button
                    onClick={() => {
                      if (object.isRe) {
                        setReCureDeleteModal(true);
                      } else {
                        setDeleteConfirmation(true);
                      }
                    }}
                    type="primary"
                    danger
                  >
                    Delete
                  </Button>
                ) : null}

                <Button
                  onClick={() => {
                    setDefaultColor(null);
                    setDraft(false);
                    setColor("");
                    setIsFullday(object.full_day == "1" ? true : false);
                    hideDrawer();
                  }}
                  type="dashed"
                  success
                >
                  Cancel
                </Button>
              </div>
            </MuiPickersUtilsProvider>
          </>
        )}
      </Drawer>
      <DeleteRecureModal
        muLoading={addLoading}
        mainLoading={mainLoading}
        futureDelete={() => {
          if (isDraft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  removeFutureShift();
                } else {
                }
              })
              .catch((error) => {});
          } else {
            removeFutureShift();
          }
        }}
        singleDelete={() => {
          if (isDraft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  removeEvent();
                } else {
                }
              })
              .catch((error) => {});
          } else {
            removeEvent();
          }
        }}
        open={reCureDeleteModal}
        close={handleClose}
      />

      <DeleteShift
        muLoading={addLoading}
        mainLoading={mainLoading}
        handleDelete={() => {
          if (isDraft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  removeEvent();
                } else {
                }
              })
              .catch((error) => {});
          } else {
            removeEvent();
          }
        }}
        open={deleteConfirmation}
        close={() => setDeleteConfirmation(false)}
        openOverlap={() => {}}
      />
      <UpdateRecureModal
        entireButton={true}
        addLoading={addLoading}
        mainLoading={mainLoading}
        singleUpdate={(future) => {
          if (isDraft == false) {
            moveAllShiftsToDraftOnly()
              .then((success) => {
                if (success) {
                  const result = findingLiveShiftInDraft(success.shifts);
                  saveEvent(future, result.id);
                } else {
                }
              })
              .catch((error) => {});
          } else {
            saveEvent(future);
          }
        }}
        updateOpen={reCureEditModal}
        updateClose={() => setReCureEditModal(false)}
      />
    </>
  );
};

export default EditDrawer2;
