import React from 'react';
import { EditOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { Card, Skeleton, Tooltip } from 'antd';

import { Dropdown } from "react-bootstrap";
import AssignUsers from '../AssignUsers/AssignUsers';
import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../../services/config';
import { PremissionChecker } from '../../../services/PremissionChecker';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { FaAngleDown } from "react-icons/fa";
import {UserSwitchOutlined} from '@ant-design/icons'
import AssignRotaUsers from '../AssignUsers/AssignRotaUsers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    },
}));


const { Meta } = Card;


const GroupCard = ({
    loading,
    item,
    onEditClick,
    onDeleteClick,
    refresh
}) => {

    const classes = useStyles();
    const history = useHistory()
    const [assignModal, setAssignModal] = useState(false)
    const user = useSelector(state => state.user.user)

    console.log('rota group', item)

    const groupDropDown = () => {
  
        return (
            <>
               {/* <Dropdown className="dropdown-inline" drop="down" alignRight>
                <Dropdown.Toggle
                    className="btn btn-sm font-weight-bolder dropdown-toggle px-5"
                    variant="transparent"
                    id="dropdown-toggle-top">
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi navi-hover">
                     
                        <li className="navi-item">
                            <div
                                onClick={() => setAssignModal(true)}
                                className="navi-link">
                         
                                <span className="navi-text">Edit assignments</span>
                            </div>
                        </li>

                    </ul>
                </Dropdown.Menu>


            </Dropdown> */}
             <UserSwitchOutlined onClick={() => setAssignModal(true)}  style={{ color: "orange" }}/>
              {/* <FaAngleDown
            onClick={() => setAssignModal(true)}
            /> */}
            {assignModal? <AssignRotaUsers
                    permission={PremissionChecker(user, "rota.assign_user")}
                    rotaId={item.id}
                    visible={assignModal}
                    setModalVisibility={(bol) => setAssignModal(bol)}
                    refresh={refresh}

                />:''}
            </>
         
        )
    }




    const getColor = () => {
        let color = ''

        color = Math.floor(Math.random() * 16777215).toString(16)
        return color
    }




    const gotToCalendar = (url,item) => {
        if(PremissionChecker(user,'rota.calendar')){
            history.push(url, { customData: item })

        }else{
           toast.error('Do not have permission to see rota calendar')
        }
    }

    return (
        <Card
            hoverable
            style={{
                width: 270,
                margin: 10
            }}
            cover={
                <img
                    onClick={() => gotToCalendar(`/rota-calendar/${item.id}`,item)}
                    alt="example"
                    src="https://app.connecteam.com/images/categories/shift-scheduler/calendar-icon@2x.svg"
                />

            }
            actions={[
                PremissionChecker(user,"rota.assign_user")?  groupDropDown():null,
                
                
                    PremissionChecker(user,"rota.update")?   <EditOutlined className='text-primary' onClick={() => onEditClick(item)} key="edit" />:null,
                    
                        PremissionChecker(user,"rota.delete")? <DeleteOutlined className='text-danger' onClick={() => onDeleteClick(item)} key="delete" />:null,
            ]}
            loading={loading}
        >
            <Skeleton loading={loading} avatar active>
                <div
                   
                    onClick={() => gotToCalendar(`/rota-calendar/${item.id}`,item)}
                >
                    <Meta

                        // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                        title={<Tooltip title={item.name}>{item.name.length>27 ? item.name.slice(0,27)+'..':item.name}</Tooltip>}
                        description="Job Scheduling"
                    />
                </div>

                <div onClick={() => gotToCalendar(`/rota-calendar/${item.id}`,item)} className='d-flex align-items-center justify-content-between'>
                    <div>
                        <h6>Assigned:</h6>
                    </div>
                    <div>
                        <AvatarGroup max={4}>
                            {
                                item.users.map((usr) => (
                                    <Avatar style={{ backgroundColor: `#${getColor()}` }} alt={usr?.username?.toUpperCase()} src={usr?.image !=null ? usr?.image?.url :   "#"} />
                                ))
                            }
                            {/* <Avatar className={classes.orange} alt="Travis Howard" src="/static/images/avatar/2.jpg" /> */}
                            {/* <Avatar className={classes.purple} alt="Agnes Walker" src="/static/images/avatar/4.jpg" /> */}

                        </AvatarGroup>
                    </div>

                </div>

            </Skeleton>

        </Card>
    )
}
export default GroupCard;

