import {
  Card,
  CardContent,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./allholidays.css";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import { Modal, Spinner, Table } from "react-bootstrap";
import { payload } from "./data.json";
// import { Tooltip } from '@material-ui/core';
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import { GiPalmTree } from "react-icons/gi";
import { BsFillCircleFill, BsCircleHalf } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Avatar } from "@material-ui/core";
import { hahaleaves } from "./haha";
import { DatePicker as AntdDatePicker, Empty } from "antd";
import UsePagination from "../../components/UsePagination/UsePagination";
import set from "date-fns/fp/set/index.js";
import collect from "collect.js";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Drawer from "@material-ui/core/Drawer";
import { Badge } from "@material-ui/core";
import { MdPendingActions } from "react-icons/md";
import { useHistory } from "react-router-dom";
import AllPendingLeaves from "../AllPendingLeaves/AllPendingLeaves";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../services/PremissionChecker";
import pendingLogo from "./vvvvv.png";
import { SendNotifaction } from "../../../services/SendNotifaction";

const AllHolidays = ({ groupsId, trigger }) => {
  const authtoken = localStorage.getItem("userToken");

  const [drawerModal, setdrawerModal] = useState(false);

  const history = useHistory();
  const [value, setValue] = useState();
  const [getDays, setGetDays] = useState([]);
  const [tempList, settempList] = useState([]);
  const [currenetSelectedDate, setcurrenetSelectedDate] = useState("");
  const [links, setLinks] = useState([]);
  const [holidaysList, setHolidaysList] = useState([]);
  const [dateIndexList, setdateIndexList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const [leaveDetails, setleaveDetails] = useState({});
  const [actionsLoading, setactionsLoading] = useState(false);

  const [searchLeave, setsearchLeave] = useState("");
  const [searchRole, setsearchRole] = useState("");
  const [counting, setCounting] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [roles, setroles] = useState([]);
  const [oldLink, setOldLink] = useState("");
  const [oldDate, setOldDate] = useState("");
  const [selectedObj, setSelectedObj] = useState(null);
  const [confirmModel, setconfirmModel] = useState(false);
  const [modelApprove, setModalApprove] = useState(false);
  const [notiUser, setNotiUser] = useState();
  useEffect(() => {
    getAllBrands();
    getAllRoles();
    getAllBrands2();
  }, []);

  const getAllBrands2 = () => {
    fetch(`${baseUrl}/mgmt/leave-applications`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        console.log("brands List", data.payload);
        let filterd = data.payload.filter((item) =>
          item.leave_applications.some(
            (application) => application.status === "PENDING"
          )
        );
        setCounting(filterd.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBrands = () => {
    fetch(`${baseUrl}/mgmt/users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setUsersList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("oldDate", oldDate);
  console.log("oldlink", oldLink);

  const getAllRoles = () => {
    fetch(`${baseUrl}/mgmt/roles`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setroles(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [currentUserLeaves, setCurrentUserLeaves] = useState([]);

  useEffect(() => {
    // if(oldDate){

    // }
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

    console.log("useefff?????", enddate);

    let url2 = `${baseUrl}/mgmt/leave-calendar`;
    let url = `${baseUrl}/mgmt/leave-group/${groupsId}/leave-calendar`;
    if (groupsId == "") {
    } else if (groupsId == null) {
      setOldLink(url2);
      getHolidaysCalendar(enddate, url2);
    } else {
      setOldLink(url);
      getHolidaysCalendar(enddate, url);
    }
    setValue(new Date());
    setOldDate(enddate);

    getLeavesTypes();
  }, [groupsId, trigger]);

  const getLeavesTypes = () => {
    fetch(`${baseUrl}/mgmt/leave-types`, {
      method: "GET",
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLeaveTypes(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHolidaysCalendar = (apidate, url) => {
    console.log("ye call huya?", apidate);
    console.log("ye call huya?");

    let year = new Date(apidate).getFullYear();
    let month = new Date(apidate).getMonth();

    const date = new Date(year, month, 1);

    let d = new Date();

    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

    const customDateArray = [];
    const customDateArray2 = [];

    while (date.getMonth() === month) {
      customDateArray.push({
        date: new Date(date).getDate(),
        day: moment(date).format("dd"),
        leave: 0,
        fulldate: moment(date).format("YYYY-MM-DD"),
        leave: true,
      });
      date.setDate(date.getDate() + 1);
      customDateArray2.push(moment(date).format("YYYY-MM-DD"));
    }

    console.log("arrayyyy ===>", customDateArray);
    setGetDays(customDateArray);
    setdateIndexList(customDateArray2);

    console.log("customDateArray", customDateArray);
    setGetDays(customDateArray);
    console.log(apidate, "this is api");
    setcurrenetSelectedDate(apidate);
    setLoading(true);
    const first = `${url}/${apidate}`;
    const onPaginate = url;

    fetch(url.includes("page") ? onPaginate : first, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          const transformedArr = data.payload.data.map((user) => {
            user.leaves = user.leaves.filter(
              (item) => item.leave_application_status.status != "REJECTED"
            );
            return user;
          });
          let newData = transformedArr;
          console.log(newData, "new Data");
          data.payload.data.map((userone, userIndex) => {
            userone["thisLeaves"] = userone?.leaves?.reduce(function(
              map,
              obj,
              i
            ) {
              map[i] = obj.day;
              return Object.values(map);
            },
            {});
            // Check if "leaves" array is empty
            if (!userone.leaves || userone.leaves.length === 0) {
              // Add 30 empty objects to "thisLeaves"
              userone["thisLeaves"] = Array(30).fill({});
            }
            newData[userIndex] = userone;
          });

          setLinks(data.payload.links);
          setHolidaysList(newData);
          console.log(newData, "this is new hol");
          setLoading(false);
          console.log("my list", newData);
        }
        //  else if (!data.metadata.success) {
        //   Object.values(data.metadata.message).map((msg) => {
        //     toast.error(msg);
        //   });
        //   setLoading(false);
        // } else {
        //   toast.error("Something went wrong! 😔");
        //   setLoading(false);
        // }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // const getStatus

  useEffect(() => {
    getDaysFunction();
  }, []);

  const getDaysFunction = () => {
    let year = new Date().getFullYear();
    let month = new Date().getMonth();

    const date = new Date(year, month, 1);

    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

    const customDateArray = [];
    const customDateArray2 = [];

    while (date.getMonth() === month) {
      customDateArray.push({
        date: new Date(date).getDate(),
        day: moment(date).format("dd"),
        leave: 0,
        fulldate: moment(date).format("YYYY-MM-DD"),
        leave: true,
      });
      date.setDate(date.getDate() + 1);
      customDateArray2.push(moment(date).format("YYYY-MM-DD"));
    }
    console.log("arrayyyy ===>", customDateArray);
    setGetDays(customDateArray);
    setdateIndexList(customDateArray2);
  };

  console.log("getDays", getDays);

  //get particular leaves according to user

  const getLeaves = (arr) => {
    let list = [];

    arr.map((item) => {
      list.push({
        status: item.status,
        start_date: item.start_date,
        end_date: item.end_date,
        date: getDatefrom(item.start_date),
        leaveType: item?.type?.name,
        duration: getDuration(item.start_date, item.end_date),
      });
    });
    return list;
  };

  //get durations from dates
  const getDuration = (d1, d2) => {
    let duration = "";
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    duration = diffDays;

    return duration;
  };

  // get date from object when user request leave

  const getDatefrom = (date) => {
    let fdate = "";
    let d = new Date("2023-05-16");
    fdate = d.getDate();
    return fdate;
  };

  const getColor = (item) => {
    let bgColor = "";
    if (item.type.name == "Sickness") {
      bgColor = "red";
    } else if (item.type.name == "Annual leave") {
      bgColor = "purple";
    }

    return bgColor;
  };

  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };

  function checkandBalance(arr, name) {
    const { length } = arr;
    const id = length + 1;
    const found = arr.some((el) => el.day === name);

    // if (!found) arr.push({ id, day: name });
    return found;
  }

  let newData = payload;

  payload.map((userone, userIndex) => {
    userone["thisLeaves"] = userone?.leaves?.reduce(function(map, obj, i) {
      map[i] = obj.day;
      return Object.values(map);
    }, {});
    newData[userIndex] = userone;
  });

  const getFunc = (arr) => {
    var res = arr.reduce(function(map, obj, i) {
      map[i] = obj.day;
      return map;
    }, {});
    return Object.values(res);
  };

  console.log("get days?", getDays);

  // getFunc(payload[0].leaves)

  const getTypeForLeave = (arr, dd) => {
    return arr.find((item) => item.day == dd);
  };

  const onPaginate = (link) => {
    const dateRegex = /\/(\d{4}-\d{2}-\d{2})\//;

    const match = link.url.match(dateRegex);
    const extractedDate = match ? match[1] : null;
    setOldLink(link);
    // getDaysFunction();
    var date = link.url
      .split("/")
      .pop()
      .slice(0, 10);

    setOldLink(link.url);
    setOldDate(date);
    console.log("onpaginate", date);
    getHolidaysCalendar(date, link.url);
  };

  const reloader = () => {
    getHolidaysCalendar(oldDate, oldLink);
  };

  // const dataSearch = brands.filter((user) => {
  // });

  // let filteredArray = holidaysList.filter(function(outerObject) {
  //   outerObject.leaves = outerObject.leaves.filter(function(innerObject) {
  //     return innerObject?.type?.name == searchText;
  //   });
  //   return outerObject.leaves.length > 0;
  // });

  // console.log("filterrd ", filteredArray);

  // let filteredArray = holidaysList.filter(function (outerObject) {
  //   outerObject.items = outerObject.leaves.filter(function (innerObject) {
  //     return innerObject?.type?.name === searchText;
  //   });
  //   return holidaysList;
  // });

  // console.log('filteredArray',filteredArray);

  const onDayClick = (item) => {
    const holidaysCollection = collect(holidaysList);
    const sortedHolidays = holidaysCollection.sortBy(
      (user, key) => !user.thisLeaves.includes(item.fulldate)
    );
    setHolidaysList(sortedHolidays);
    setSelectedId(item.fulldate);
  };

  const dataSearchLast = holidaysList.map((item) => {
    item.leaves.filter((leave) => {
      if (searchText != "") {
        if (leave?.type?.name == searchText) {
          console.log("found", item);
          return item;
        }
      } else {
        // console.log("No text found", item);
        return item;
      }
    });
  });

  // const filteredEmployees = holidaysList.filter(employee => {
  //   return employee.leaves.some(leave => leave.type.name === "Annual leave");
  // });

  function filterEmployees() {
    if (!searchText) {
      return holidaysList;
    } else {
      let array = [];
      holidaysList.map((employee, emplIndex) => {
        console.log("employee", employee);

        employee.leaves.find((leave, index) => {
          if (leave?.type?.name == searchText) {
            array.push({
              ...employee,
              ...holidaysList[emplIndex].leaves[index],
            });
          }
        });
      });

      console.log("datatatatatttattatta", array);
      return array;
    }
  }

  const onChangeType = (leaveType, roleId) => {
    setLoading(true);
    fetch(
      `${baseUrl}/mgmt/leave-calendar/${currenetSelectedDate}?leave_type_id=${leaveType}&role_id=${roleId}&leave_group_id=${groupsId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          //@do not remove please
          let newData = data.payload.data;
          data.payload.data.map((userone, userIndex) => {
            userone["thisLeaves"] = userone?.leaves?.reduce(function(
              map,
              obj,
              i
            ) {
              map[i] = obj.day;
              return Object.values(map);
            },
            {});
            // Check if "leaves" array is empty
            if (!userone.leaves || userone.leaves.length === 0) {
              // Add 30 empty objects to "thisLeaves"
              userone["thisLeaves"] = Array(30).fill({});
            }
            newData[userIndex] = userone;
          });

          // data.payload.map((user) => {
          //   customDateArray.map((date, i) => {
          //     console.log(date.fulldate)
          //     if(user.leaves_days.includes(date.fulldate)){
          //       console.log('yes')
          //     }else{
          //       console.log('no')
          //     }
          //   })

          // })

          setLinks(data.payload.links);
          setHolidaysList(newData);
          setLoading(false);
          console.log("my list", newData);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
          });
          setLoading(false);
        } else {
          toast.error("Something went wrong! 😔");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getLeaveDetails = (user) => {
    setSelectedUser(user);
    console.log(user);
    if (usersList.length > 0) {
      // const found = usersList.find(item => item.first_name == user.first_name && item.last_name == user.last_name)
      fetch(`${baseUrl}/mgmt/users/${user.id}/leave-applications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            setCurrentUserLeaves(data.payload);
            setdrawerModal(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const clearFilter = () => {
    // let url = `${baseUrl}/mgmt/leave-calendar`;
    let d = new Date();
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;
    setValue(new Date());
    let url2 = `${baseUrl}/mgmt/leave-calendar`;
    let url = `${baseUrl}/mgmt/leave-group/${groupsId}/leave-calendar`;
    if (groupsId == "") {
    } else if (groupsId == null) {
      setOldLink(url2);
      getHolidaysCalendar(enddate, url2);
    } else {
      setOldLink(url);
      getHolidaysCalendar(enddate, url);
    }
    // setOldLink(url);
    setOldDate(enddate);
    // getHolidaysCalendar(enddate, url);
  };

  const getDurationDays = (start_date, end_date) => {
    return (
      Math.round(
        moment
          .duration(
            moment(end_date, "YYYY-MM-DD").diff(
              moment(start_date, "YYYY-MM-DD")
            )
          )
          .asDays()
      ) || 1
    );
  };

  const getNumberofdays = (st, ed) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const startParts = st.split("/");
    const endParts = ed.split("/");

    const start = new Date(
      `20${startParts[2]}`,
      startParts[1] - 1,
      startParts[0]
    );
    const end = new Date(`20${endParts[2]}`, endParts[1] - 1, endParts[0]);

    // Calculate the difference in days
    const diffDays = Math.round(Math.abs((end - start) / oneDay)) + 1;

    return diffDays;
  };
  const user = useSelector((state) => state.user.user);

  const openLeaveApprove = (user, date) => {
    const found = user?.leaves.find((leav) => leav.day == date.fulldate);

    console.log("select?", found);
    console.log("user select", user);
    setNotiUser(user);
    setSelectedObj(found);
    setconfirmModel(true);
  };
  function convertToSimpleDate(timestamp) {
    var date = new Date(timestamp);

    var simpleDate = date.toISOString().split("T")[0];

    return simpleDate;
  }

  console.log(selectedObj, "hasdhasdjkhsadkjsa");

  const onStatusChange = (status) => {
    setactionsLoading(true);
    fetch(
      `${baseUrl}/mgmt/leave-applications/${selectedObj.leave_application.id}?_method=PUT`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify({ status: status }),
      }
    )
      .then((resp) => {
        resp.json().then((res) => {
          setactionsLoading(false);

          if (res.metadata) {
            if (res.metadata.success) {
              setModalApprove(!modelApprove);
              toast.success(res.metadata.message);
              reloader();
              const extraData = {
                type: "accpet_leave",
                userId: notiUser.id,
              };
              SendNotifaction(
                "Leave Status Updated",
                `Your ${selectedObj.type.name} updated to Approved. Your ${
                  selectedObj.type.name
                } starts from ${convertToSimpleDate(
                  selectedObj.leave_application.start_date
                )} and ended on ${convertToSimpleDate(
                  selectedObj.leave_application.end_date
                )}`,
                notiUser.id,
                "",
                extraData
              );
              setconfirmModel(false);
              // getLeaveDetails()
              // updateArray(status)
              // getAllBrands()
              // SendNotifaction('Leave Status Updated', `Your Leave updated to ${'Approved'}`, item.id)
            } else if (!res.metadata.success) {
              if (Array.isArray(res.metadata.message)) {
                Object.values(res.metadata.message).map((msg) => {
                  toast.error(msg);
                });
              } else {
                toast.error(res.metadata.message);
              }
            }
          }
        });
      })
      .catch((err) => {
        setactionsLoading(false);
        console.log(err);
      });
  };

  function isDurationGreaterThanOneDay(startDate, endDate) {
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    const durationInMilliseconds = endDateTime - startDateTime;
    const durationInDays = durationInMilliseconds / (1000 * 60 * 60 * 24);
    if (durationInDays >= 1) {
      return true;
    } else {
      return false;
    }
  }
  function extractDate(dateTimeString) {
    var dateTime = new Date(dateTimeString);

    var year = dateTime.getFullYear();
    var month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
    var day = ("0" + dateTime.getDate()).slice(-2);
    var date = year + "-" + month + "-" + day;
    return date;
  }
  function isCurrentDate(dateString) {
    const today = new Date();
    const inputDate = new Date(dateString);
    if (
      today.getFullYear() === inputDate.getFullYear() &&
      today.getMonth() === inputDate.getMonth() &&
      today.getDate() === inputDate.getDate()
    ) {
      return true;
    } else {
      return false;
    }
  }
  const getMonthName = (monthIndex) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  };
  const getMonthName2 = (monthName) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames.findIndex(
      (name) => name.toLowerCase() === monthName.toLowerCase()
    );
  };


   
        function calculateWorkingDays(startDateStr, endDateStr, workPattern,leavePattern) {
       
            const startDate = moment(startDateStr);
            const endDate = moment(endDateStr);
            const workingDaysArray = [];
        
            let currentDate = startDate.clone();
            while (currentDate.isSameOrBefore(endDate, 'day')) {
              console.log("is this working??")
                const dayName = currentDate.format('dddd').toLowerCase();
                const workingDayValue = workPattern[dayName] && leavePattern[dayName] ? 1 : 0;
                workingDaysArray.push({ day: currentDate.format('YYYY-MM-DD'), duration: "3", working_day: workingDayValue });
                currentDate.add(1, 'day');
            }
           console.log(workingDaysArray,"what happening")
            return workingDaysArray;
        }
  
        const getRequestedTimeOFf=(start,end,work_pattern,leave_pattern)=>{
          if(!work_pattern || !leave_pattern){
            return
          }
          const leaveDuration = calculateWorkingDays(start, end, work_pattern,leave_pattern);
          console.log(leaveDuration,"this is the resultant")
        const filteredData=  leaveDuration.filter((lev)=>lev.working_day=="1")
        console.log(filteredData,"ffabsha")
        return filteredData.length
        }

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          {PremissionChecker(user, "leave_calendar.show") ? (
            <Paper
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                padding: "15px",
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="row">
                      <div className="col-md-6">
                        <DatePicker
                          views={["year"]}
                          label="Year"
                          // minDate={new Date("2012-03-01")}
                          // maxDate={new Date()}
                          value={value}
                          onChange={(dateee) => {
                            setValue(dateee);
                            console.log("date===>", dateee);
                            let year = new Date(dateee).getFullYear();
                            let month = new Date(dateee).getMonth();
                            const date = new Date(year, month, 1);
                            // get Data from api

                            let d = new Date(dateee);
                            var curr_moth = ("0" + (d.getMonth() + 1)).slice(
                              -2
                            );
                            var curr_date = ("0" + d.getDate()).slice(-2);
                            let ddd = `${new Date(
                              d
                            ).getFullYear()}-${curr_moth}-${curr_date}`;

                            // let url = `${baseUrl}/mgmt/leave-calendar`;
                            let url2 = `${baseUrl}/mgmt/leave-calendar`;
                            let url = `${baseUrl}/mgmt/leave-group/${groupsId}/leave-calendar`;
                            if (groupsId == "") {
                            } else if (groupsId == null) {
                              setOldLink(url2);
                              getHolidaysCalendar(ddd, url2);
                            } else {
                              setOldLink(url);
                              getHolidaysCalendar(ddd, url);
                            }

                            // setOldLink(url);
                            setOldDate(ddd);
                            // getHolidaysCalendar(ddd, url);

                            const dates = [];

                            while (date.getMonth() === month) {
                              dates.push({
                                date: new Date(date).getDate(),
                                day: moment(date).format("dd"),
                                leave: 0,
                                fulldate: moment(date).format("YYYY-MM-DD"),
                              });
                              date.setDate(date.getDate() + 1);
                            }

                            console.log("arrayyyy ===>", dates);
                            setGetDays(dates);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                          )}
                        />
                      </div>
                      <div className="col-md-6">
                        {/* <DatePicker
                          views={["month"]}
                          label="Month"
                          // minDate={new Date("2012-03-01")}
                          // maxDate={new Date()}
                          value={value}
                          onChange={(dateee) => {
                            setValue(dateee);
                            console.log("date===>", dateee);
                            let year = new Date(dateee).getFullYear();
                            let month = new Date(dateee).getMonth();

                            const date = new Date(year, month, 1);
                            // get Data from api

                            let d = new Date(dateee);
                            var curr_moth = ("0" + (d.getMonth() + 1)).slice(
                              -2
                            );
                            var curr_date = ("0" + d.getDate()).slice(-2);
                            let ddd = `${new Date(
                              d
                            ).getFullYear()}-${curr_moth}-${curr_date}`;

                            // let url = `${baseUrl}/mgmt/leave-calendar`;

                            let url2 = `${baseUrl}/mgmt/leave-calendar`;
                            let url = `${baseUrl}/mgmt/leave-group/${groupsId}/leave-calendar`;
                            if (groupsId == "") {
                            } else if (groupsId == null) {
                              setOldLink(url2);
                              getHolidaysCalendar(ddd, url2);
                            } else {
                              setOldLink(url);
                              getHolidaysCalendar(ddd, url);
                            }
                            // setOldLink(url);
                            setOldDate(ddd);
                            // getHolidaysCalendar(ddd, url);

                            const dates = [];

                            while (date.getMonth() === month) {
                              dates.push({
                                date: new Date(date).getDate(),
                                day: moment(date).format("dd"),
                                leave: 0,
                                fulldate: moment(date).format("YYYY-MM-DD"),
                              });
                              date.setDate(date.getDate() + 1);
                            }

                            console.log("arrayyyy ===>", dates);
                            setGetDays(dates);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                          )}
                        /> */}
                        <div>
                          <label
                            style={{
                              fontSize: "10px",
                              color: "gray",
                              position: "absolute",
                            }}
                          >
                            Month
                          </label>
                        </div>
                        {value && (
                          <Select
                            value={getMonthName(value.getMonth())}
                            onChange={(event) => {
                              const selectedMonth = event.target.value;
                              let monthIndex=getMonthName2(selectedMonth)
                              console.log(monthIndex,"getting month index??")
                              const makingDate = new Date(value.getFullYear(), monthIndex, 1);
                              let dateee =makingDate;
                              setValue(dateee);
                              console.log("date===>", dateee);
                              let year = new Date(dateee).getFullYear();
                              let month = new Date(dateee).getMonth();

                              const date = new Date(year, month, 1);
                              // get Data from api

                              let d = new Date(dateee);
                              var curr_moth = ("0" + (d.getMonth() + 1)).slice(
                                -2
                              );
                              var curr_date = ("0" + d.getDate()).slice(-2);
                              let ddd = `${new Date(
                                d
                              ).getFullYear()}-${curr_moth}-${curr_date}`;

                              // let url = `${baseUrl}/mgmt/leave-calendar`;

                              let url2 = `${baseUrl}/mgmt/leave-calendar`;
                              let url = `${baseUrl}/mgmt/leave-group/${groupsId}/leave-calendar`;
                              if (groupsId == "") {
                              } else if (groupsId == null) {
                                setOldLink(url2);
                                getHolidaysCalendar(ddd, url2);
                              } else {
                                setOldLink(url);
                                getHolidaysCalendar(ddd, url);
                              }
                              // setOldLink(url);
                              setOldDate(ddd);
                              // getHolidaysCalendar(ddd, url);

                              const dates = [];

                              while (date.getMonth() === month) {
                                dates.push({
                                  date: new Date(date).getDate(),
                                  day: moment(date).format("dd"),
                                  leave: 0,
                                  fulldate: moment(date).format("YYYY-MM-DD"),
                                });
                                date.setDate(date.getDate() + 1);
                              }

                              console.log("arrayyyy ===>", dates);
                              setGetDays(dates);
                            }}
                            // onChange={handleMonthChange}
                            displayEmpty
                            style={{ width: 200, marginTop: "16px" }}
                          >
                            <MenuItem value="" disabled>
                              Select Month
                            </MenuItem>
                            {[
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July",
                              "August",
                              "September",
                              "October",
                              "November",
                              "December",
                            ].map((month, index) => (
                              <MenuItem key={index} value={month}>
                                {month}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-md-3">
                  <small className="form-text text-muted">
                    <b>Filter</b> by holiday Type
                  </small>
                  <select
                    className="form-control"
                    placeholder="Filter by Role"
                    name="type"
                    onChange={(e) => {
                      onChangeType(e.target.value, searchRole);
                      setsearchLeave(e.target.value);
                    }}
                    value={searchLeave}
                  >
                    <option value="">All</option>
                    {leaveTypes &&
                      leaveTypes.map((statu) => (
                        <option key={statu.id} value={statu.id}>
                          {statu.name}
                        </option>
                      ))}
                  </select>
                </div>
                {user.role[0]?.name == "admin" && (
                  <div className="col-md-3">
                    <small className="form-text text-muted">
                      <b>Filter</b> by Roles
                    </small>
                    <select
                      className="form-control"
                      placeholder="Filter by Role"
                      name="type"
                      onChange={(e) => {
                        onChangeType(searchLeave, e.target.value);
                        setsearchRole(e.target.value);
                      }}
                      value={searchRole}
                    >
                      <option value="">All</option>
                      {roles &&
                        roles.map((rol) => (
                          <option key={rol.id} value={rol.id}>
                            {rol.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {/* 
              <div className="col-md-3">
                <div className="pending__card" onClick={() => history.push('/allpendingleaves')}>
                  <div className="left-side">
                    <span>Pending Leaves</span>
                  </div>
                  <div className="right-side">
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      style={{
                        marginRight: 10
                      }}
                      badgeContent={counting}
                      color="secondary">
                  
                    </Badge>

                  </div>
                </div>
              </div> */}

                <div className="col-md-5 d-flex justify-content-end">
                  <div className="m-3">
                    <b>Annual leave:</b>
                    <GiPalmTree className="ml-2" size={25} color="green" />
                  </div>
                  <div className="m-3">
                    <b>Sickness:</b>
                    <BsFillCircleFill
                      className="ml-2"
                      size={25}
                      color="#FF6771"
                    />
                  </div>
                  <div className="m-3">
                    <b>Lateness:</b>
                    <BsCircleHalf className="ml-2" size={25} color="#FF6771" />
                  </div>
                  <div className="m-3">
                    <b>Pending:</b>
                    <img
                      // className="ml-2"
                      height={20}
                      width={20}
                      src={pendingLogo}
                    />
                  </div>
                </div>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => clearFilter()}
                  className="col-md-2 mt-3"
                >
                  <strong className="form-text text-muted">Clear filter</strong>
                </div>
              </div>

              <div className="row mt-3">
                <div
                  className="col-md-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h3 style={{ color: "#C6C5C9" }}>Users</h3>
                </div>
                <div className="col-md-10 d-flex">
                  {getDays.map((dayo, i) => (
                    <div
                      onClick={() => onDayClick(dayo, i)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: isCurrentDate(dayo.fulldate)
                          ? "black"
                          : "",
                        color: isCurrentDate(dayo.fulldate) ? "white" : "",
                      }}
                      className={`box ${
                        dayo.fulldate == selectedId ? "boxOuter" : ""
                      }`}
                    >
                      {/* <span className="">{dayo.day}</span> */}
                      <p style={{ marginBottom: 0 }}>{dayo.date}</p>
                      <b>{dayo.day}</b>
                      {/* <span className="tableDays text-black">{dayo.date}</span> */}
                    </div>
                  ))}
                </div>
              </div>

              {loading ? (
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <Spinner animation="border" />
                </div>
              ) : (
                holidaysList.map((user, i) => {
                  return (
                    <div className="row">
                      {/* {
                    user.leaves.length > 0 ? */}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => getLeaveDetails(user)}
                        className="col-md-2 d-flex align-items-center user-box"
                      >
                        <h3>{user.first_name}</h3>
                      </div>
                      {/* : null

                  } */}
                      <div className="col-md-10 d-flex">
                        {getDays.map((date, dateIndex) => {
                          if (user.thisLeaves.length > 0) {
                            if (user.thisLeaves.includes(date.fulldate)) {
                              return (
                                <OverlayTrigger
                                  key={date.id}
                                  placement={"top"}
                                  overlay={
                                    <Tooltip id={`tooltip-${date.fulldate}`}>
                                      <strong>
                                        {date.fulldate}{" "}
                                        {
                                          getTypeForLeave(
                                            user.leaves,
                                            date.fulldate
                                          ).type.name
                                        }
                                        {getTypeForLeave(
                                          user.leaves,
                                          date.fulldate
                                        )?.leave_application_status?.status ==
                                        "PENDING" ? (
                                          <b className="fw-bold">(Pending)</b>
                                        ) : null}
                                      </strong>
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    onClick={() => {
                                      if (
                                        getTypeForLeave(
                                          user.leaves,
                                          date.fulldate
                                        )?.leave_application_status?.status ==
                                        "PENDING"
                                      ) {
                                        openLeaveApprove(user, date);
                                      } else {
                                        getLeaveDetails(user);
                                      }
                                    }}
                                    className="box cursor-pointer"
                                  >
                                    {getTypeForLeave(user.leaves, date.fulldate)
                                      ?.leave_application_status?.status ==
                                    "PENDING" ? (
                                      // <MdPendingActions
                                      //   size={25}
                                      //   color="#FFAC1C"
                                      // />
                                      <img
                                        height={20}
                                        width={20}
                                        src={pendingLogo}
                                      />
                                    ) : (
                                      <img
                                        height={20}
                                        width={20}
                                        src={
                                          getTypeForLeave(
                                            user.leaves,
                                            date.fulldate
                                          ).type.icon_url
                                        }
                                      />
                                    )}
                                  </div>
                                </OverlayTrigger>
                              );
                            } else {
                              return <div className="box"></div>;
                            }
                          }
                        })}
                      </div>
                    </div>
                  );
                })
              )}
              {/* NOw we will check if item is already exits in array then show box otherwise show date */}

              <UsePagination links={links} onPaginate={onPaginate} />
            </Paper>
          ) : (
            <Paper
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                padding: "15px",
              }}
              className="notPermission"
            >
              <Empty description="Do not have permission" />
            </Paper>
          )}
        </div>
        <div className="col-md-3">
          <AllPendingLeaves reloader={reloader} modelApprove={modelApprove} />
        </div>
      </div>
      <Drawer
        anchor={"right"}
        open={drawerModal}
        onClose={() => setdrawerModal(false)}
      >
        <div style={{ width: "400px" }}>
          <button
            onClick={() =>
              history.push(`/view-holiday/${selectedUser.id}`, selectedUser)
            }
            className="btn btn-primary"
          >
            See More
          </button>
          <div
            className="row"
            style={{ justifyContent: "flex-end", margin: 10 }}
          >
            {/* NOw we will check if item is already exits in array then show box otherwise show date */}
            <div
              onClick={() => setdrawerModal(false)}
              className="offcanvas-close mt-n1 pr-5"
            >
              <a
                href="#"
                className="btn btn-xs btn-icon btn-light btn-hover-primary"
                id="kt_quick_panel_close"
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            </div>
          </div>

          <div className="mt-5 text-center">
            <span className="font-weight-bolder">Leave Details</span>
          </div>
          {currentUserLeaves &&
            currentUserLeaves.map((leav) => (
              <>
                <div
                  className="mt-2"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Card className="allholiCard" variant="outlined">
                    <CardContent
                      style={{
                        paddingTop: "0px",
                        paddingLeft: "15px",
                        paddingBottom: "5px",
                        paddingRight: "0px",
                      }}
                      className="text-center"
                    >
                      <div className="">
                        <div className="row">
                          <div className="col-md-8 text-left pt-2">
                            <p>
                              {/* Duration: */}
                              <b>
                                {getNumberofdays(
                                  leav.start_date,
                                  leav.end_date
                                )}{" "}
                                {getNumberofdays(
                                  leav.start_date,
                                  leav.end_date
                                ) == 1
                                  ? "day"
                                  : "days"}
                              </b>
                              {/* &nbsp; {leav.start_date} to {leav.end_date} */}
                              <p>
                                {leav.start_date} to {leav.end_date}
                              </p>
                            </p>
                            <p style={{ marginBottom: "0" }}>
                              {leav?.type?.name}
                            </p>
                          </div>

                          <div className="col-md-4 text-center">
                            <p
                              className={
                                leav?.status == "PENDING"
                                  ? "bg-warning"
                                  : leav?.status == "REJECTED"
                                  ? "bg-danger"
                                  : "bg-primary"
                              }
                              style={{ marginBottom: "0", color: "white" }}
                            >
                              {leav?.status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                {/* <div className="row p-4">
                  <div className="col-md-6">
                    <label><strong>Name: {' '}</strong></label>

                  </div>
                  <div className="col-md-6">
                    <span>{leav?.type?.name}</span>
                  </div>
                </div> */}
                {/* <div className="row p-4">
                  <div className="col-md-6">
                    <label><strong>Status: {' '}</strong></label>

                  </div>
                  <div className="col-md-6">
                    <span>{leav?.status}</span>
                  </div>
                </div> */}
                {/* <hr /> */}
              </>
            ))}
        </div>
      </Drawer>
      <Modal show={confirmModel} centered animation>
        {console.log(selectedObj, "fghjfgsdjfh")}
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to approve this leave?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 text-dark">
              <b>Leave Type</b>
            </div>
            <div className="col-md-6">{selectedObj?.type?.name}</div>

            <div className="col-md-6">
              <b>Date</b>
            </div>
            <div className="col-md-6">{selectedObj?.day}</div>
            {isDurationGreaterThanOneDay(
              extractDate(selectedObj?.leave_application?.start_date),
              extractDate(selectedObj?.leave_application?.end_date)
            ) && (
              <>
                <div className="col-md-6">
                  <b>Starting Date</b>
                </div>
                <div className="col-md-6">
                  {extractDate(selectedObj?.leave_application?.start_date)}
                </div>
                <div className="col-md-6">
                  <b>End Date</b>
                </div>
                <div className="col-md-6">
                  {extractDate(selectedObj?.leave_application?.end_date)}
                </div>
                  <div className="col-md-6">
                              <b>Days requested</b>
                            </div>
                            <div className="col-md-6">
                              {selectedObj && getRequestedTimeOFf(selectedObj?.leave_application?.start_date,selectedObj?.leave_application?.end_date,notiUser?.work_pattern,notiUser?.leave_pattern)}
                            </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setconfirmModel(false)}
            className="btn btn-light"
          >
            Cancel
          </button>
          {PremissionChecker(user, "leave_application.action") && (
            <>
              <button
                disabled={actionsLoading}
                onClick={() => onStatusChange(2)}
                className="btn btn-primary"
              >
                Approve
              </button>
              <button
                disabled={actionsLoading}
                onClick={() => onStatusChange(3)}
                className="btn btn-danger"
              >
                Reject
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllHolidays;
